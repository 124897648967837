import { toast } from "react-toastify";

const toastProperties = {
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
}

export function infoToast(message, toastId = "info_toast") {
  return toast.info(message, {
    ...toastProperties,
    toastId
  });
}

export function successToast(message, toastId = "success_toast") {
  return toast.success(message, {
    ...toastProperties,
    toastId
  });
}

export function warningToast(message, toastId = "warning_toast") {
  return toast.warn(message, {
    ...toastProperties,
    toastId
  });
}

export function errorToast(message, toastId = "error_toast") {
  return toast.error(message, {
    ...toastProperties,
    toastId
  });
}
