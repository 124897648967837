import axios from "axios";
import { GET_YOGA_VINYASA } from "../types";
import { API_GET_YOGA_VINYASA, API_UPDATE_YOGA_VINYASA_COUNT } from '../../config/api/endpoint'
import { getHeadersForAPICall } from '../../utils/helper'

export const getYogaVinyasa = () => {
  let headers = getHeadersForAPICall()
  return dispatch => {
    return axios
      .get(API_GET_YOGA_VINYASA, { headers })
      .then(response => {
        dispatch({
          type: GET_YOGA_VINYASA,
          payload: response
        });
        return response
      })
      .catch(err => {
        return err
      });
  };
};


export const updateYogaVinyasaCount = (data) => {
  let headers = getHeadersForAPICall()
  return dispatch => {
    return axios
      .put(API_UPDATE_YOGA_VINYASA_COUNT, data, { headers })
      .then(response => {
        return response
      })
      .catch(err => {
        return err
      });
  };
};
