import styled from 'styled-components'

export const DashboardTilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #f2f3f4;
  font-family: Cormorant;
  color: #404a59;
  padding: 0 10.5% 5%;
  text-align: center;

  @media screen and (max-width: 767px) {
    padding: 4%;
  }
`
export const Tile = styled.div`
  flex-basis: 40%;
  background: ${props => props.bgCol ? props.bgCol : "#d7ddd8"};
  padding: 1em;
  font-size: 1.5em;
  margin: 2% 1%;
  box-sizing: border-box;
  cursor: pointer;

  &:nth-child(3), &:nth-child(4) {
    margin: 0 1% 2%;
  }

  @media screen and (max-width: 767px) {
    padding: 4%;
    flex-basis: 100%;
    // margin-bottom: 4%;
    margin: 0 1% 4%;
    font-size: 1.2em;

    &:nth-child(3), &:nth-child(4) {
      margin: 0 1% 4%;
    }
  }

  @media screen and (max-width: 500px) {
    font-size: 1em;
  }
`