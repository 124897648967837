import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DOWNLOAD_PROMO } from '../../../../images'
import Checkbox from '../../../common/checkbox'
import { ContactContainer, ContactFormSection, FormField, CheckboxOptions, DownloadImageSection } from './style'
import { getHeadersForAPICall} from '../../../../utils/helper'
import axios from "axios";

function DownloadForm(props) {
  const [hearAboutUs, updateHearAboutUsList] = useState([]);
  const [fName, setFname] = useState("");
  const [email, setEmail] = useState("");
  const [lName, setLname] = useState("");
  const [msg, setMsg] = useState("");
  const content = props.content

  const handleChange = (event) => {
    if (event && event.target) {
      let elementName = event.target.name
      if (event.target.checked && !hearAboutUs.includes(elementName)) {
        updateHearAboutUsList([
          ...hearAboutUs,
          elementName
        ])
      } else if (hearAboutUs.includes(elementName)) {
        let tmp = [...hearAboutUs]
        tmp.splice(hearAboutUs.indexOf(elementName), 1)
        updateHearAboutUsList(tmp)
      }
    }
  }

  const onSubmit = (event) => {
    event.preventDefault()
    let isValid = true
    setMsg("Free Download");

    if (!fName.trim() || !lName.trim() || !email.trim()) {
      toast.error("Please fill in all fields (*)", { toastId: "common" })
      isValid = false
    } else {
      if (fName && fName.trim().length < 2) {
        toast.error("Please enter minimum 2 characters for First name", { toastId: "fname" })
        isValid = false
      }
      if (lName && lName.trim().length < 2) {
        toast.error("Please enter minimum 2 characters for Last name", { toastId: "lname" })
        isValid = false
      }
      if (email) {
        let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regex.test(email.trim())) {
          toast.error("Please enter correct Email address", { toastId: "email" })
          isValid = false
        }
      }
      if (msg && msg.trim().length < 10) {
        toast.error("Please enter minimum 10 characters for message", { toastId: "msg" })
        isValid = false
      }
    }

    if (isValid) {
      const formData = { firstName: fName, lastName: lName, email: email, message: "Free Download", howDidYouHearOfUs: hearAboutUs }
      const headers = getHeadersForAPICall()
      axios.post('/users/contactUs', formData, {headers}).then(res => {
        toast.success("Thank You, keep an eye on your inbox.", { toastId: "success" })
        updateHearAboutUsList([]); 
        setEmail(""); setFname(""); setLname(""); setEmail(""); setMsg("")
      }).catch(error => {
        toast.error("Something went wrong, Please try again later.", { toastId: "error" })
      })
    }
  }
  return (
    <>
      <ContactContainer>
        <ContactFormSection>
          <div className="dynamic_content form_header">{ReactHtmlParser(content["download_form_heading"])}</div>
          <div className="dynamic_content form_sub_header">{ReactHtmlParser(content["download_form_description"])}</div>
          <form className="form">
            <FormField>
              <label htmlFor="name">Name*</label><br />
              <div className="name_container">
                <div className="form_first_name_section">
                  <input type="text" id="fName" name="fName" onChange={e => setFname(e.target.value)} value={fName} />
                  <div className="form_first_name">First Name</div>
                </div>
                <div className="form_last_name_section">
                  <input type="text" id="lName" name="lName" onChange={e => setLname(e.target.value)} value={lName} />
                  <div className="form_last_name">Last Name</div>
                </div>
              </div>
            </FormField>
            <FormField>
              <label htmlFor="email">Email*</label><br />
              <input type="text" id="email" name="email" onChange={e => setEmail(e.target.value)} value={email} /><br />
            </FormField>
            <FormField>
              {/* <label htmlFor="message">Message*</label><br /> */}
              <input type="hidden" id="msg" name="msg" onChange={e => setMsg("Free Download")} value={msg} /><br />
            </FormField>
            <FormField>
              <label>Check All That Interest You Below.</label><br />
              <div className="checkbox_option">
                <CheckboxOptions>
                  <label>
                    <Checkbox checked={hearAboutUs.includes("1 on 1 with Esha")} id="checkbox_insta" onChange={handleChange} name={"1 on 1 with Esha"} />
                    <span className={"checkbox_label"}>1 on 1 w/Esha</span>
                  </label>
                </CheckboxOptions>
                <CheckboxOptions>
                  <label>
                    <Checkbox checked={hearAboutUs.includes("Groups")} id="checkbox_club" onChange={handleChange} name={"Groups"} />
                    <span className={"checkbox_label"}>Online Groups</span>
                  </label>
                </CheckboxOptions>
                <CheckboxOptions>
                  <label>
                    <Checkbox checked={hearAboutUs.includes("Support Crowdfund")} id="checkbox_ff" onChange={handleChange} name={"Support Crowdfund"} />
                    <span className={"checkbox_label"}>Support Crowdfund</span>
                  </label>
                </CheckboxOptions>
                <CheckboxOptions>
                  <label>
                    <Checkbox checked={hearAboutUs.includes("Mobile App")} id="checkbox_fb" onChange={handleChange} name={"Mobile App"} />
                    <span className={"checkbox_label"}>Mobile App</span>
                  </label>
                </CheckboxOptions>
                <CheckboxOptions>
                  <label>
                    <Checkbox checked={hearAboutUs.includes("Grief Doula Training")} id="checkbox_podcast" onChange={handleChange} name={"Grief Doula Training"} />
                    <span className={"checkbox_label"}>Grief Doula Training</span>
                  </label>
                </CheckboxOptions>
                <CheckboxOptions>
                  <label>
                    <Checkbox checked={hearAboutUs.includes("Ambassador Affiliate")} id="checkbox_internet" onChange={handleChange} name={"Ambassador Affiliate"} />
                    <span className={"checkbox_label"}>Ambassodor/Affiliate</span>
                  </label>
                </CheckboxOptions>
              </div>
            </FormField>
            <FormField submit>
              <input type="submit" className="submit_btn" id="submit" value="Submit" onClick={onSubmit} />
            </FormField>
          </form>
        </ContactFormSection>
        <DownloadImageSection>
          {/* <div className="dynamic_content form_header">{ReactHtmlParser(content["contact_map_heading"])}</div>
          <div className="dynamic_content form_sub_header">{ReactHtmlParser(content["contact_map_description"])}</div> */}
          <img src={DOWNLOAD_PROMO} width="100%" alt="Download Promo" />
        </DownloadImageSection>
      </ContactContainer>
    </>
  );
}

export default DownloadForm;