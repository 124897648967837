import styled from 'styled-components'

export const TilesViewContainer = styled.div`
	display: inline-block;
	width: 25.3%;
	margin: 1.5em 4%;
	text-align: center;
	vertical-align: top;
  color: #404a59;

	& > img {
		width: 72%;
		margin: ${props => props.margin};
	}

	.tile_heading{
		font-size: 1.2em;
		font-style: italic;
		font-weight: bold;
		margin-top: 0.3em;
		color: #212020;
	}

	.tile_description{
		margin: 1em auto;
		width: 65%;
		font-size: 0.7em;
		font-family: 'Muli';
	}

	.imp_note {
		font-size: 0.7em;
		font-weight: bold;
		color: black;
		width: 75%;
		margin: 0 auto;
	}

	@media screen and (max-width: 767px) {
		width: auto;
		text-align: center;
		vertical-align: top;
		margin-top: 3em;
		color: #404a59;

		& > img {
			width: 100%;
			margin: 0;
		}

		.tile_heading{
			font-size: 1.7em;
			color: #af986f;
			font-style: italic;
			margin-top: 0.5em;
			margin-left: 0;
		}

		.tile_description{
			margin: 0.5em 0;
			width: 100%;
			font-size: 1em;
			font-family: 'Muli';
		}
		.imp_note {
			font-size: 0.8em;
			width: 100%;
		}

	}
`
