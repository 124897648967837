import React, { Component } from 'react';
import { connect } from 'react-redux'
import Header from '../../components/common/header'
import YogaVinyasaPage from '../../components/pages/yoga_vinyasa'
import Footer from '../../components/common/footer'
import { getYogaVinyasa, updateYogaVinyasaCount } from '../../redux/actions/yoga_vinyasa_actions'
import Loader from '../../components/common/loader'
import { redirectWithOptionalMessage, isObjectEmpty } from '../../utils/helper'

class YogaVinyasa extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isYogaVinyasaAvailable: false,
    }
  }

  componentDidMount() {
    if (isObjectEmpty(this.props.userInfo)) {
      redirectWithOptionalMessage("/dashboard", this.props.history)
      return
    }
    this.props.getYogaVinyasa().then(resp => {
      if (resp && resp.status === 200) {
        this.setState({ isYogaVinyasaAvailable: true })
      } else {
        redirectWithOptionalMessage("/dashboard", this.props.history, resp.response.data.error.message)
      }
    })
  }

  render() {
    return (
      <>
        {!this.state.isYogaVinyasaAvailable ? <Loader /> :
          <>
            <Header />
            <YogaVinyasaPage yoga_vinyasas={this.props.yoga_vinyasas} updateYogaVinyasaCount={this.props.updateYogaVinyasaCount} />
            <Footer />
          </>
        }
      </>
    )
  }
}



function mapStateToProps(state) {
  return {
    yoga_vinyasas: state.yoga_vinyasa_content.yoga_vinyasas,
    userInfo: state.user_content.userInfo
  };
}

const mapDispatchToProps = {
  getYogaVinyasa,
  updateYogaVinyasaCount
}

export default connect(mapStateToProps, mapDispatchToProps)(YogaVinyasa)
