import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getLocalStorageItem } from './utils/helper'
const ProtectedRoute = ({ component: Component, ...rest }) => (

  <Route {...rest} render={(props) => (
    getLocalStorageItem("auth0_access_token")
      ? <Component {...props} />
      : <Redirect to='/home' />
  )} />
)
export default ProtectedRoute;

