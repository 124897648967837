import styled from 'styled-components'

export const HeaderLogo = styled.div`
	text-align: center;
	
	& > img {
		width: 20.5%;
	}

	@media screen and (max-width: 767px) {
		& > img {
			width: 55%;
		}
	}
`

export const NoConnectionPageContainer = styled.div`
  background: #f2f3f4;
  min-height: ${props => (props.minHeight ? props.minHeight : "40em")};
  font-family: Muli;
  color: #af986f;
  padding: 1.5em 10.5% 2em;
  width: 100%;
  text-align: center;
  box-sizing: border-box;

  .text {
    font-size: 1.7em;
    margin-top: 3em;
    text-align: center;
  }

	@media screen and (min-width: 1400px) {
    .text {
      font-size: 3em;
    }
  }

	@media screen and (max-width: 767px) {
    padding: 2em 4% 5%;

    .text {
      font-size: 1.5em;
      margin-top: 2em;
    }
  }
`