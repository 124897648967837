import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { TestimonalsContainer, TestimonalsContent } from './styles'

function Testimonals(props) {
  const content = props.content
  return (
    <>
      <TestimonalsContainer>
        <div className="testimonals_heading">Testimonals</div>
        <TestimonalsContent>
          <div className="dynamic_content content">{ReactHtmlParser(content["home_testimonals_1_content"])}</div>
          <div className="dynamic_content author">{ReactHtmlParser(content["home_testimonals_1_author"])}</div>
        </TestimonalsContent>
        <TestimonalsContent>
          <div className="dynamic_content content">{ReactHtmlParser(content["home_testimonals_2_content"])}</div>
          <div className="dynamic_content author">{ReactHtmlParser(content["home_testimonals_2_author"])}</div>
        </TestimonalsContent>
        <TestimonalsContent>
          <div className="dynamic_content content">{ReactHtmlParser(content["home_testimonals_3_content"])}</div>
          <div className="dynamic_content author">{ReactHtmlParser(content["home_testimonals_3_author"])}</div>
        </TestimonalsContent>
      </TestimonalsContainer>
    </>
  );
}

export default Testimonals;
