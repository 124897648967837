import styled from 'styled-components'

export const YogaNidraContainer = styled.div`
	background: #f2f3f4;
	font-family: Cormorant;
	color: #404a59;
	padding: 4em 10.5% 2em;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;

  .title {
    font-size: 1.6em;
    justify-content: flex-start;
    font-weight: bold;
  }

  .media_container {
    margin: 2% 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #404a59;
    color: #f2f3f4;
    padding: 15px 30px;
  }

  .media_music_icon {
    width: 40px;
    height: 40px;
  }

  .media_detail {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .media_name {
    font-size: 1em;
    font-family: Muli;
    margin-right: 15px;
    flex: 1;
  }

  audio {
    flex: 2;
    outline: none;
  }

	@media screen and (max-width: 767px) {
		padding: 4%;

    .media_music_icon {
      width: 20px;
      height: 20px;
    }

    .media_container {
      flex-direction: column;
      padding: 4%;
    }

    .media_detail {
      padding: 0 0 2%;
    }

    audio {
      width: 100%;
    }
	}
`