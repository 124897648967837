import { MainContainer } from './styles';

function DefaultContentPage(props) {
  return (
    <MainContainer>
      <div className="card_container">
        <div>{props.children}</div>
      </div>
    </MainContainer>
  );
}

export default DefaultContentPage;