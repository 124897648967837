import styled from 'styled-components'

export const JournalContainer = styled.div`
	background: #f2f3f4;
	font-family: Cormorant;
  font-size: 1em;
	color: #404a59;
	padding: 4em 10.5% 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;

  .user_profile_container {
    text-align: center;
  }

  .user_profile {
    width: 10em;
    height: 10em;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background: black;

    &>img {
      width: 100%;
    }
  }

  .user_info {
    padding: 3% 0 0;
    text-align: center;
    margin: 0 auto;
  }

  .title {
    font-size: 1.2em;
    font-weight: bold;
  }

  .user_msg {
    font-family: Muli;
    font-size: 0.8em;
    text-align: center;
    padding-bottom: 3%;
    margin: 0 auto;
  }

  .btn_grp {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding: 1em 0;
  }

  .default_btn {
    font-size: 0.7em;
    text-align: center;
    flex-basis: 35%;
    border: 1px solid #af986f;
    color: #af986f;
    cursor: pointer;
    border-radius: unset;
    padding: 0.5em 1em;
    min-width: 8em;
    max-width: 8em;
    margin: 1em;
  }

  .active_follow {
    background: #af986f;
    color: #f2f3f4;
  }

  .active_block {
    background: #af986f;
    color: #f2f3f4;
  }

  @media screen and (min-width: 1500px) {
    .title {
      font-size: 2em;
    }

    .username {
      font-size: 1.5em;
    }
  
    .user_msg {
      font-size: 1em;
      padding: 1em 0 0;
    }

    .btdefault_btnn {
      font-size: 1em;
    }
  }
 
	@media screen and (max-width: 767px) {
		padding: 4% 4% 0;
	}
`