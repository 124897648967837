import React, { useState } from 'react';
import { EditProfileHomeContainer } from './styles';
import UserProfile from '../user_profile';
import BlockedUserProfile from '../blocked_user_profile';
import AdvanceSettings from '../advance_setting';
import Loader from '../../../common/loader'

function EditAccountsection(props) {
  const [loading, setLoading] = useState(false)
  const [ userSettingOption, setUserSettingOption ] = useState("profile");
  const user = props.userInfo;

  
  const UserSettingOption = (props) => {
    const option = props.option;
    if(option === "advance_setting") {
      return <AdvanceSettings setLoading={setLoading} user={user}/>
    } else if(option === "block_list") {
      return <BlockedUserProfile />
    } else {
      return <UserProfile userInfo={user} setLoading ={setLoading}/>
    }
  }

  return (
    <>
    {loading ? <Loader /> : null}
      <EditProfileHomeContainer>
        <div className="header">
          <div className="title">Account</div>
          <div className="sub_title">Hi, {user["basic"].name}</div>
        </div>
        <div className="edit_container">
         <div className="settings_options">
           <div className={ userSettingOption === "profile" ? "active option" : "option"} onClick={() => setUserSettingOption("profile")}>Edit User</div>
           {/* <div className={ userSettingOption === "block_list" ? "active option" : "option"} onClick={() => setUserSettingOption("block_list")}>Blocked User</div> */}
           <div className={ userSettingOption === "advance_setting" ? "active option" : "option"} onClick={() => setUserSettingOption("advance_setting")}>Advance</div>
         </div>
         <div className="selected_setting_container">
           <UserSettingOption option={userSettingOption} />
         </div>
        </div>
      </EditProfileHomeContainer>
    </>
  );
}

export default EditAccountsection;
