import React, { useState } from 'react';
import { connect } from 'react-redux'
import { PROFILE_USERNAME_ICON, PROFILE_NAME_ICON, PROFILE_ADD_IMG_ICON, PROFILE_EDIT_IMG_ICON, PROFILE_DEFAULT_AVATAR } from '../../../../images';
import { EditProfileModalContainer, ModalContainer, FormField } from './styles';
import { convertBase64, getCompletePathOfAssest } from '../../../../utils/helper'
import { UpdateUserInformation } from '../../../../redux/actions/user_actions'
import Loader from '../../loader'
import { successToast, errorToast } from '../../../../utils/toast'

function EditProfileModal(props) {
  const user = props.userInfo
  const [userName, setUserName] = useState(user["userName"] ? user["userName"] : "");
  const [nickName, setNickName] = useState(user["basic"] ? user["basic"].nickName : "");
  const [profileMessage, setprofileMessage] = useState(user["profileMessage"] ? user["profileMessage"] : "");
  const [newProfileImageBase64, setNewProfileImageBase64] = useState("")
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const currentProfilePicPath = getCompletePathOfAssest(user["profileImageURL"])
  const [loading, setLoading] = useState(false)

  const isValid = (event) => {
    event.preventDefault();
    let isValidInfo = true;

    if(!userName.trim() || !nickName.trim() || !profileMessage.trim()) {
      errorToast("Please fill in all details", { toastId: "common" })
      isValidInfo = false;
    } else {
      if(userName && userName.trim().length < 2) {
        errorToast("Please enter minimum 2 characters for username", { toastId: "userName" });
        isValidInfo = false;
      }
      if(nickName && nickName.trim().length < 2) {
        errorToast("Please enter minimum 2 characters for name", { toastId: "name" });
        isValidInfo = false;
      }
      if(profileMessage && profileMessage.trim().length < 5) {
        errorToast("Please enter minimum 5 characters for message", { toastId: "profileMessage" });
        isValidInfo = false;
      }
    }

    if(isValidInfo) {
      const profileInfo = { nickName, userName, profileMessage, profileImage: newProfileImageBase64 }
      setLoading(true)
      props.UpdateUserInformation(profileInfo).then(resp => {
        setLoading(false)
        if(resp && resp.status === 200) {
          props.closePopup();
          successToast("Profile updated succesfully", { toastId: "profile_updated_success" });
        } else {
          const msg = resp?.response?.data?.error?.message || `Error uploading profile image. Image size exceeds.`
          errorToast(msg, { toastId: "profile_updated_error" });
        }
      })
    }
  }


  const handleImgUpload = async (event) => {
    const file = event.target.files[0]
    if(file) {
      let reader = new FileReader();
      let { current } = uploadedImage;
      current.file = file;
      reader.onload = e => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      const base64 = await convertBase64(file)
      setNewProfileImageBase64(base64);
    }
  }

  return (
    <>
    {loading ? <Loader /> : null}
    <EditProfileModalContainer>
      <ModalContainer>
        <div className="title">PROFILE</div>
        <input type="file" multiple={false} accept="image/*" onChange={handleImgUpload} ref={imageUploader} style={{ display: "none" }} />
        <div className="profile_logo" onClick={() => imageUploader.current.click()}>
          <div className="user_img">
            <img ref={uploadedImage} src={currentProfilePicPath ? currentProfilePicPath : PROFILE_DEFAULT_AVATAR} alt="Default Avatar" />
          </div>
          <div className="add_edit_icon">
            {!currentProfilePicPath ? <img src={PROFILE_ADD_IMG_ICON} alt="plus" /> : <img src={PROFILE_EDIT_IMG_ICON} alt="edit" />}
          </div>
        </div>
        <form className="form">
          <FormField>
            <label htmlFor="username">Username</label><br />
            <div className="input_field">
              <input type="text" value={userName} id="userName" name="userName" onChange={e => setUserName(e.target.value)} placeholder="Enter username" />
              <img src={PROFILE_USERNAME_ICON} alt="User Name" />
            </div>
          </FormField>
          <FormField>
            <label htmlFor="name">Name</label><br />
            <div className="input_field">
              <input type="text" value={nickName} id="nickName" name="nickName" onChange={e => setNickName(e.target.value)} placeholder="Enter name" />
              <img src={PROFILE_NAME_ICON} alt="Name" />
            </div>
          </FormField>
          <FormField>
            <label htmlFor="message">Profile message</label><br />
            <textarea id="msg" value={profileMessage} name="msg" onChange={e => setprofileMessage(e.target.value)} placeholder="Enter profile message" />
          </FormField>
          <FormField>
            <input type="submit" className="submit_btn" id="submit" value="SUBMIT" onClick={isValid} />
          </FormField>
        </form>
      </ModalContainer>
    </EditProfileModalContainer>
    </>
  )
}

const mapDispatchToProps = {
  UpdateUserInformation
}

export default connect(null, mapDispatchToProps)(EditProfileModal)