import styled from 'styled-components'

export const YogaVinyasaModalContainer = styled.div`
  background: #0000003d;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalContainer = styled.div`
  background: transparent;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 40%;
  max-width: 45em;

  .cross_video_player {
    position: absolute;
    right: 2%;
    top: 2%;
    z-index: 1;
    cursor: pointer;
  }

  .video_player {
    width: 100%;
    max-width: 95em;
    height: 430px;
    max-height: 50em;
  }

  @media screen and (min-width: 768px) and (max-width: 1200px){
    width: 65%;
  }

  @media screen and (max-width: 767px) {
    width: 95%;

    .video_player {
      height: 340px;
    }
  }
`