import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { MariposaSanctuaryContainer, MariposaSummaryContainer } from './styles';
import { MARIPOSA_SUMMARY_IMG } from '../../../../images';

function MariposaSummary(props) {
  const content = props.content
  return (
    <>
      <MariposaSanctuaryContainer>
        <img src={MARIPOSA_SUMMARY_IMG} alt="Mariposa" />
        <MariposaSummaryContainer>
          <div className="dynamic_content title">{ReactHtmlParser(content["mariposa_heading"])}</div>
          <div className="dynamic_content sub_title">{ReactHtmlParser(content["mariposa_text_1"])}</div>
          <div className="dynamic_content summary">{ReactHtmlParser(content["mariposa_description"])}</div>
        </MariposaSummaryContainer>
      </MariposaSanctuaryContainer>
    </>
  )
}

export default MariposaSummary;