export const GET_STATIC_WEB_CONTENT = "GET_STATIC_WEB_CONTENT"

export const GET_USER_INFO = "GET_USER_INFO"
export const UPDATE_USER_INFO = "UPDATE_USER_INFO"

export const GET_MEDITATIONS = "GET_MEDITATIONS"

export const GET_YOGA_NIDRAS = "GET_YOGA_NIDRAS"

export const GET_YOGA_VINYASA = "GET_YOGA_VINYASA"

export const GET_QUESTION_OF_THE_DAY = "GET_QUESTION_OF_THE_DAY"

export const GET_ALL_JOURNALS = "GET_ALL_JOURNALS"
export const GET_FOLLOWED_PEOPLE_JOURNALS = "GET_FOLLOWED_PEOPLE_JOURNALS"
export const GET_MY_JOUNRALS = "GET_MY_JOUNRALS"

export const GET_GUIDED_REFLECTION = "GET_GUIDED_REFLECTION"