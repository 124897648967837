import { GET_YOGA_VINYASA } from "../types";

let initialState = {
  yoga_vinyasas: {},
};

export default function yoga_vinyasa_reducer (state = initialState, action) {
  switch (action.type) {
    case GET_YOGA_VINYASA:
      const resp = action.payload.data.data
      const videos = []
      const audios = []
      resp.forEach(content=>{
        if(content.mediaType === "audio") {
          audios.push(content)
        } else {
          videos.push(content)
        }
      })
      return {
        ...state,
        yoga_vinyasas: {audioContent: [...audios], videoContent: [...videos]}
      };
    default:
      return state;
  }
}