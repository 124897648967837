import axios from "axios";
import { GET_MEDITATIONS } from "../types";
import { API_GET_MEDITATIONS, API_UPDATE_MEDITATION_COUNT} from '../../config/api/endpoint'
import { getHeadersForAPICall } from '../../utils/helper'

export const getMeditations = () => {
  let headers = getHeadersForAPICall()
  return dispatch => {
    return axios
      .get(API_GET_MEDITATIONS, { headers })
      .then(response => {
        dispatch({
          type: GET_MEDITATIONS,
          payload: response
        });
        return response
      })
      .catch(err => {
        return err
      });
  };
};


export const updateMeditationCount = (data) => {
  let headers = getHeadersForAPICall()
  return dispatch => {
    return axios
      .put(API_UPDATE_MEDITATION_COUNT, data, { headers })
      .then(response => {
        return response
      })
      .catch(err => {
        return err
      });
  };
};
