import React from 'react';
import { ABOUT_ESHA_NEW } from '../../../../images'
import { AboutEshaHeaderContainer } from './style'
import ReactHtmlParser from 'react-html-parser';

function AboutEshaHead(props) {
  const content = props.content
  return (
    <AboutEshaHeaderContainer>
      <div className="about_esha_description_container">
        <div className="esha_img_container">
          <img src={ABOUT_ESHA_NEW} alt="About_esha" />
        </div>
        <div className="esha_desc_container">
          <div className="dynamic_content title">{ReactHtmlParser(content["about_esha_head_1"])}</div>
          <div className="dynamic_content subtitle">{ReactHtmlParser(content["about_esha_head_2"])}</div>
        </div>
      </div>
      <div className="desc_container">
        <div className="desc_title">About Esha</div>
        <div className="dynamic_content about_desc">{ReactHtmlParser(content["about_esha_head_desc"])}</div>
      </div>
    </AboutEshaHeaderContainer>
  );
}

export default AboutEshaHead;