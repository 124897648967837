import { GET_STATIC_WEB_CONTENT } from "../types";

let initialState = {
  staticWebContent: {},
};

export default function static_content_reducer (state = initialState, action) {
  switch (action.type) {
    case GET_STATIC_WEB_CONTENT:
      return {
        ...state,
        staticWebContent: action.payload.data.data
      };
    default:
      return state;
  }
}