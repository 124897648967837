import React from 'react';
import { ProgramViewContainer } from './styles';

function ProgramView(props) {
  return (
    <>
      <ProgramViewContainer margin={(props.align && props.align % 2 === 0) ? "2% 0 2% 2%" : "2% 2% 2% 0"}>
        <div className="dynamic_content program_title">{props.programTitle}</div>
        <div className="dynamic_content program_info">{props.programInfo}</div>
      </ProgramViewContainer>
    </>
  )
}

export default ProgramView;
