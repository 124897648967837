import styled, { css } from 'styled-components'

export const ContactContainer = styled.div`
  background: #f2f3f4;
  padding: 5.5em 10.5% 5%;

  @media screen and (max-width: 767px) {
    padding: 4%;
  }
`

export const ContactFormSection = styled.div`
  display: inline-block;
  width: 45%;
  vertical-align: top;

  .form_header {
    font-family: Cormorant;
    font-size: 2.2em;
    font-weight: bold;
    color: #404a59;
  }

  .form_sub_header {
    padding-top: 2%;
    font-family: Cormorant;
    font-style: italic;
    font-size: 1.3em;
    color: #af986f;
  }

  .form {
    padding: 2% 1%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    display: block;

    .form_header, .form_sub_header {
      text-align: center;
    }
  }
`
export const FormField = styled.div`
  margin: 1em 0;
  // text-align: ${props => props.textAlign ? props.textAlign : ""};

  ${props => props.submit && css`
		text-align: right;
	`};

  label {
    font-family: Cormorant;
    font-style: italic;
    font-size: 1.4em;
    color: #404a59;
  }

  .name_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  input[type=text] {
    border: 1px solid #af986f;
    background: #f2f3f4;
    padding: 0.7em;
    width: 100%;
    margin-top: 10px;
    outline: none;
    box-sizing: border-box;
  }

  .form_first_name_section, .form_last_name_section {
    flex-basis: 45%;
  }

  .form_first_name, .form_last_name {
    font-size: 0.8em;
    color: #404a59;
    margin-top: 8px;
  }

  .checkbox_option {
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .submit_btn {
    background: #af986f;
    padding: 0.3em 0;
    text-align: center;
    width: 25%;
    max-width: 10em;
    border: none;
    color: white;
    font-family: Cormorant;
    font-size: 1em;
    font-style: italic;
    cursor: pointer;
    outline: none;
  }

  @media screen and (max-width: 767px) {
    ${props => props.submit && css`
      text-align: center;
      margin: 2em 0;
    `};

    name_container {
      flex-direction: column;
      flex-wrap: wrap;
    }

    input[type=text] {
      width: 95%;
    }
  
    .form_first_name_section, .form_last_name_section {
      flex-basis: 100%;
    }
  }
`

export const CheckboxOptions = styled.div`
  flex-basis: 33%;

  input[type=checkbox] {
    border: 1px solid #af986f;
    background: #f2f3f4;
    padding: 0.5em;
    zoom: 1.5;
  }

  label {
    cursor: pointer;
  }

  .checkbox_label {
    font-family: Muli;
    font-size: 0.6em;
    font-style: normal;
    padding-left: 7px;
    vertical-align: text-top;
  }

  @media screen and (max-width: 1145px) {
    flex-basis: 50%;
  }
`

export const DownloadImageSection = styled.div`
  display: inline-block;
  width: 45%;
  padding-left: 10%;
  vertical-align: top;


  .form_header {
    font-family: Cormorant;
    font-size: 2.2em;
    font-weight: bold;
    color: #404a59;
  }

  .form_sub_header {
    font-family: Cormorant;
    font-style: italic;
    font-size: 1.3em;
    color: #af986f;
    margin: 2% 0 10%;
  }

  &>img {
    width: 100%;
  }

  @media screen and (min-width: 2560px) {
    &>img {
      width: 60%;
    }

    .form_sub_header {
      margin: 2% 0 5%;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    display: block;
    padding: unset;

    &>img {
      width: 100%;
    }

    .form_header, .form_sub_header {
      text-align: center;
    }
  }
`
