import styled from 'styled-components';

export const SharedJournalContainer = styled.div`
  background: #f2f3f4;
  font-family: Cormorant;
  font-size: 1em;
  color: #404a59;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;

  .footer_show_more_less {
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    margin: 1em 0;
  }

  .heading {
    font-size: 1.9em;
    font-weight: bold;
    margin-top: 2em;
  }

  .journey_container {
    width: 45%;
    max-width: 50em;
    text-align: center;
  }

  .user_activity_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    box-sizing: border-box;
  }

  .activity {
    flex-basis: 48%;
    background: #AF986F;
    margin: 1em;
    padding: 1% 2%;
    min-height: 10.5em;
  }

  .activity_count {
    font-size: 3em;
  }

  .toggle_btn {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 1em auto 1.5em;
    padding: 5px;
    background: #e5e5e3;
    width: 35%;
    max-width: 20em;
  }

  .btn {
    flex-basis: 50%;
    cursor: pointer;
    font-size: 0.8em;
    font-family: Muli;
    color: #404a59;
    padding: 5px;
    text-align: center;
    outline: none;
    border: none;
  }

  .active_btn {
    background: #af986f;
    flex-basis: 50%;
    cursor: pointer;
    font-size: 0.8em;
    font-family: Muli;
    color: #f2f3f4;
    padding: 5px;
    text-align: center;
    outline: none;
    border: none;
  }

	@media screen and (min-width: 1500px) {
    padding: 1em 0;
  }

	@media screen and (max-width: 767px) {
    padding: 0 4%;

    .journey_container {
      width: 100%;
      margin-left: unset;
    }

    .activity {
      margin: 1em 0 0;
    }

    .heading {
      margin-top: 1em;
    }

    .toggle_btn {
      width: 50%;
    }

    .active_btn, .btn{
      padding: 5px;
    }
  }

  @media screen and (max-width: 400px) {
    .toggle_btn {
      width: 65%;
    }

    .active_btn, .btn{
      padding: 5px;
    }
  }
`

export const SharedJournalListContainer = styled.div`
	background: #e5ebf2;
	font-family: Cormorant;
  font-size: 1em;
	color: #404a59;
	padding: 2em;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  box-sizing: border-box;
  margin: 1em 1em 2em;

  .journal_edit_icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .journal_question {
    text-align: center;
  }

  .title {
    font-size: 1.5em;
    font-weight: bold;
  }

  .journal_title {
    font-weight: bold;
    font-size: 1.1em;
    margin-top: 10px;
    text-transform: capitalize;
  }
  
  .journal_date {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  
  .journal {
    margin: 5% 0;
    width: 85%;
    text-align: center;
    word-break: break-all;
  }

  .show_more {
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
  }

  .like_count {
    font-size: 1.3em;
    display: inline;
    margin-left: 0.7em;
  }

  .fa-heart {
    color: white;
  }

  .input_disabled {
    background: #e5ebf2;
    border: none;
    text-align: center;
  }
 
	@media screen and (min-width: 1400px) {
    .journal {
      margin: 2% 0;
    }
  }

	@media screen and (max-width: 767px) {
    padding: 4% 2%;
    margin: 1em 0;

    .title {
      font-size: 1.2em;
    }

    .journal {
      margin: 5% 0;
      font-size: 0.9em;
    }

    .show_more {
      font-size: 0.8em;
    }
  }
`