import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { connect } from 'react-redux'
import { getAllJournals, getFollowedPeopleJournals, likeJournal, unLikeJournal } from '../../../../redux/actions/journal_actions'
import { getCompletePathOfAssest, getTimeFromNowForJournal } from '../../../../utils/helper'
import { MainContainer, CommunityStoryContainer, Story } from './styles'
import Loader from '../../../common/loader'

function JournalGrp(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const jounralGrp = props.journalGrp
  const userInfo = props.userInfo

  if ( jounralGrp && jounralGrp.journals && jounralGrp.journals.length !== 0) {
    return (
      jounralGrp.journals.map((journal, index) => {
        if (!props.showAllJournals && index >= 1) {
          return null
        }
        const journalUserInfo = journal["userId"]
        let fromNow = getTimeFromNowForJournal(journal.createdAt);
        let journalLikedBy = []
        if(journal && journal["likedBy"] && journal["likedBy"].length) {
          journal["likedBy"].forEach(user => {
            journalLikedBy.push(user["userId"])
          })
        }
        const isUserAlreadyLikeJournal = journalLikedBy.includes(userInfo.id)

        const likeUnlikeJournal = (id) => {
          const data = { journalId: id }
          setLoading(true)
          if (!isUserAlreadyLikeJournal) {
            props.likeJournal(data).then(resp => {
              if (resp && resp.status === 200) {
                if(props.journalType === "public") {
                  props.getAllJournals().then(resp=>{
                    setLoading(false)
                  })
                } else if(props.journalType === "following"){
                  props.getFollowedPeopleJournals().then(resp=>{
                    setLoading(false)
                  })
                }
              }
            })
          } else {
            props.unLikeJournal(data).then(resp => {
              if (resp && resp.status === 200) {
                if(props.journalType === "public") {
                  props.getAllJournals().then(resp=>{
                    setLoading(false)
                  })
                } else if(props.journalType === "following"){
                  props.getFollowedPeopleJournals().then(resp=>{
                    setLoading(false)
                  })
                }
              }
            })
          }
        }
        let redirectURL = "/user/" + journalUserInfo["id"]
        if(journalUserInfo["id"] === userInfo["id"]) {
          redirectURL = "/journal"
        }
        return (
          <React.Fragment key={props.uniqueKeyForPost + "_" + index}>
            {loading ? <Loader /> : null}
            <Story>
              <div className="user_comment_card" onClick={() => history.push(redirectURL)}>
                <div className="user_img">
                  <div><img src={getCompletePathOfAssest(journalUserInfo["profileImageURL"])} alt="User Avatar" /></div>
                </div>
                <div className="user_name">
                  <div className="name_text">{journalUserInfo.userName}</div>
                  <div className="sub_text">{fromNow}</div>
                </div>
              </div>
              <div className="story_content">
                <div className="journal_title">{journal["title"]}</div>
                {journal["journal"]}
              </div>
            </Story>
            <div className="likes_container" onClick={() => likeUnlikeJournal(journal["_id"])}>
              <i className="fas fa-heart" style={isUserAlreadyLikeJournal ? { color: "red" } : { color: "white" }} />
              <div className="like_count">
                {journal.likes}
              </div>
            </div>
          </React.Fragment>
        )
      })
    )
  }
}

function CommunityStory(props) {
  const date = moment(props.journalGrp.date).format('MMMM DD, YYYY')
  const question = props.journalGrp["question"] ? props.journalGrp["question"].question : ""
  const uniqueKeyForPost = props.journalType + "_" + date
  return (
    <MainContainer>
      <CommunityStoryContainer bgCol={props.bgCol}>
        <div className="header">{date}</div>
        <div className="question">{question}</div>
        <JournalGrp
          journalGrp={props.journalGrp}
          userInfo={props.userInfo}
          getAllJournals={props.getAllJournals}
          getFollowedPeopleJournals={props.getFollowedPeopleJournals}
          likeJournal={props.likeJournal}
          unLikeJournal={props.unLikeJournal}
          showAllJournals={props.allJournalForDate === date}
          uniqueKeyForPost={uniqueKeyForPost}
          journalType={props.journalType}
        />
        {props.journalGrp.journals.length > 1 ?
          <div className="footer" onClick={() => props.setAllJournalForDate(props.allJournalForDate === date ? "" : date)}>
            {props.allJournalForDate === date ? "Show less" : "Show More"}
          </div> : null
        }
      </CommunityStoryContainer>
    </MainContainer>
  )
}

function mapStateToProps(state) {
  return {
    userInfo: state.user_content.userInfo,
  };
}

const mapDispatchToProps = {
  getFollowedPeopleJournals,
  getAllJournals,
  likeJournal,
  unLikeJournal,
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunityStory)

