import React, { useState } from 'react';
import { SharedJournalListContainer, SharedJournalContainer } from './styles';
import DefaultContentPage from '../../../common/no_content_page';
import moment from 'moment';
import TodaysJournalModal from '../../../common/modal/dashboard_todays_jornal_modal'
import {redirectWithOptionalMessage} from '../../../../utils/helper'
import {useHistory} from 'react-router-dom'

function GetMyJournalSection(props) {
  const myJournals = props.myJournals
  const [journalContent, setJournalContent] = useState({});
  const [openTodaysJournalModal, setTodaysJournalModal] = useState(false);
  const [selectedDate, setSelectedJournalDate] = useState(null);
  let questionOftheDayObj = {date: '', greeting: '', questionId: '', question: ''};
  const history = useHistory();

  if(journalContent && journalContent["questionId"]) {
    questionOftheDayObj = {date: journalContent["date"], greeting: '', questionId: journalContent["questionId"]._id, question: journalContent["questionId"].question}
  }
  
  const closeTodaysJournalModal = () => {
    setTodaysJournalModal(false);
    redirectWithOptionalMessage("/dashboard", history)
  }

  const getSpecificDateJournalEntry = (date) => {
    setSelectedJournalDate(date)
    props.getSpecificDateJournal(date).then(resp => {
      if(resp && resp.status === 200) {
        setJournalContent(resp.data.data[0]);
        setTodaysJournalModal(true);
      } else {
        console.error("Not found");
      }
    })
  }

  if(openTodaysJournalModal && selectedDate) {
    return <TodaysJournalModal closeModal={closeTodaysJournalModal} user={props.user} questionOfTheDay={questionOftheDayObj} origin={"edit_journal"} selectedJournalDate={selectedDate} /> 
  } else if (myJournals && myJournals.length !== 0) {
    return (
      myJournals.map((journal, index) => {
        const journalQuestion = (journal.questionId && journal.questionId.question) ? journal.questionId.question : "" 
        if(!props.showAllJournal && index >=1 ) {
          return null
        }

        if(journal["journal"].trim().length === 0) {
          return null
        }

        return (
          <SharedJournalListContainer key={index}>
            <i className="fas fa-edit journal_edit_icon" onClick={() => getSpecificDateJournalEntry(journal["date"])} />
            <div className="journal_date"> {moment(journal["date"]).format('MMMM DD, YYYY')} </div>
            <div className="journal_question"> {journalQuestion} </div>
            <div className="journal_title">{journal["title"]}</div>
            <div className="journal">{journal["journal"] ? journal["journal"] : ""}</div>
          </SharedJournalListContainer>
        )
      })
    )
  } else {
    return (
      <DefaultContentPage> No Journals are available in this Section</DefaultContentPage>
    )
  }
}

function MyJournalList(props) {
  const [showAllJournal, setShowAllJournal] = useState(false)
  const user = props.userInfo;
  const [myJournals, setMyJournals] = useState(props.myJournals);
  const [showJournalsFor, setJournalViewType] = useState("all");

  const filterJournalsOnRequest = (requestFor) => {
    if(requestFor === "shared") {
      let journalList = myJournals.filter(journal => journal.isPublic);
      setMyJournals(journalList);
    } else {
      setMyJournals(props.myJournals);
    }
    setJournalViewType(requestFor);
  }

  return (
    <>
      <SharedJournalContainer>
        <div className="journey_container">
          <div className="user_activity_container">
            <div className="activity" >
              <div className="activity_count">{user.entries_count || 0}</div>
              <div>SHARED ACTIVITY</div>
            </div>
            <div className="activity" >
              <div className="activity_count">{user.completed_meditation_count || 0}</div>
              <div>COMPLETED MEDITATION</div>
            </div>
          </div>
          <div className="heading">Public Journal Entries</div>
          <div className="toggle_btn">
            <div className={showJournalsFor === "shared" ? "active_btn" : "btn"} onClick={() => filterJournalsOnRequest("shared")}>SHARED</div>
            <div className={showJournalsFor === "all" ? "active_btn" : "btn"} onClick={() => filterJournalsOnRequest("all")}>ALL</div>
          </div>
          {
            <GetMyJournalSection 
              myJournals={myJournals}
              showAllJournal={showAllJournal}
              getSpecificDateJournal={props.getSpecificDateJournal}
              saveEditedJournal={props.saveEditedJournal}
              user={user}
            />
          }
        {props.myJournals.length > 1 ?
          <div className="footer_show_more_less" onClick={() => setShowAllJournal(!showAllJournal)}>
            {showAllJournal ? "Show less" : "Show More"}
          </div> : null
        }
        </div>
      </SharedJournalContainer>
    </>
  );
}

export default MyJournalList;