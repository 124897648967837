import styled from 'styled-components'

export const WaysToEngageContainer = styled.div`
	text-align: center;
	background: #d7ddd8;
	font-family: Cormorant;

	.ways_to_engage_heading{
		font-size: 2em;
		font-weight: bold;
		padding-top: 1.5em;
		color: #404a59;
	}

	.ways_to_engage_tile_section{
		padding: 2em 10%;
	}

	.ways_to_engage_bottom_text{
		color: #af986f;
		font-size: 1.2em;
		width: 68%;
		margin: 0 auto;
		font-family: Muli;
		margin-top: 1.5em;
	}

	@media screen and (max-width: 767px) {
		text-align: center;
		background: #d7ddd8;
		font-family: Cormorant;
		padding: 4%;
	
		.ways_to_engage_heading{
			padding-top: 0.3em;
			color: #404a59;
		}
	
		.engage_heading{
			color: #af986f;
			font-size: 2em;
			font-style: italic;
			margin-top: 0.3em;
		}
	
		.ways_to_engage_tile_section{
			margin-top: 0;
			padding: 0;
		}
	
		.ways_to_engage_bottom_text{
			color: #af986f;
			font-size: 1.2em;
			width: 100%;
			margin: 0 auto;
			font-family: Muli;
			margin-top: 1.5em;
		}
	}
`
