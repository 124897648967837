import {development_config} from './development'
import {production_config} from './production'

const config = {
  development: development_config,
  production: production_config
};

export function getConfig() {
  const appENV = process.env.REACT_APP_ENV || 'development'
  return config[appENV];
}