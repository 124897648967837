import styled, { css } from 'styled-components'

export const ComingSoonContainer = styled.div`
	background: #f2f3f4;
	font-family: Cormorant;
	color: #404a59;
	padding: 1.5em 10.5% 4em;

	.coming_header_container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		margin-top: 4.5em;
	}

	@media screen and (max-width: 767px) {
		padding: 1.5em 0 1em;
	}
`
export const HeaderLogo = styled.div`
	text-align: center;
	
	& > img {
		width: 16.5%;
	}

	@media screen and (max-width: 767px) {
		& > img {
			width: 45%;
		}
	}
`


export const ComingSoonSection = styled.div`
	& > img {
		width: 90%;
		max-width: 550px;
	}

	${props => props.image && css`
		text-align: right;
		padding-right: 5%;
		flex-basis: 45%;
	`};

	${props => props.text && css`
		flex-basis: 50%;
		font-size: 1.1em;
	`};

	.coming_soon_heading {
		margin-left: 6px;
		font-size: 2em;
	}

	.coming_soon_text_1 {
		color: #af986f;
		font-size: 1.8em;
		font-style: italic;
		margin-left: 15px;
		margin-top: 13px
	}

	.coming_soon_sub_text_1 {
		width: 80%;
		margin: 38px 13px 20px 13px;
		font-size: 16px;
		line-height: 20px;
		font-family: Muli;
	}

	.coming_soon_sub_text_2 {
		width: 80%;
		margin-left: 13px;
		font-size: 16px;
		line-height: 20px;
		font-family: Muli;
	}

	@media screen and (max-width: 767px) {
		flex-basis: 100%;

		${props => props.image && css`
			padding: unset;
		`};

		& > img {
			width: 100%;
			margin-left: 0;
			max-width: unset;
		}

		${props => props.text && css`
			margin-top: 0;
			padding: 4%;
			width: auto;
			text-align: center;
		`};

		.coming_soon_heading{
			margin: 0.5em 0 0.2em;
		}

		.coming_soon_text_1 {
			margin: 0;
		}

		.coming_soon_sub_text_1 {   
			width: 90%;
			margin: 2em auto;
		}

		.coming_soon_sub_text_2 {   
			width: 90%;
			margin: 2em auto;
		}
	}

`