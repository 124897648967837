import React, { useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { MeditationContainer } from './styles';
import { MUSIC_WHITE_ICON, COMING_SOON_TITLE_1, PLAY_VIDEO_ICON } from '../../../images';
import VideoModal from '../../common/modal/yoga_vinyasa_video_modal';
import DefaultContentPage from '../../common/no_content_page';

function GetSingleVideoSection(props) {
  const [openVideoModal, setVideoModal] = useState(false);
  const videoInfo = props.content;

  return (
    <>
      <div className="video_tile" onClick={() => setVideoModal(true)}>
        <div className="video_poster_container">
          <img src={COMING_SOON_TITLE_1} alt="poster 1" />
          <div className="overlay"><img src={PLAY_VIDEO_ICON} alt="Play Button" /></div>
        </div>
        <div className="video_info">{videoInfo.title}</div>
      </div>
      {
        openVideoModal ?
          <VideoModal
            closeModal={() => setVideoModal(false)}
            videoSrc={videoInfo.media}
            onEnd={(id) => props.updateMeditationCount(id)}
            id={videoInfo["_id"]}
          /> : null
      }
    </>
  )
}

function stopAnyOtherCurrentlyPlayingAudio(event) {
  for (const audio of document.querySelectorAll('audio')) {
    if (audio !== event.currentTarget) {
      audio.pause();
      audio.currentTime = 0
    }
  }
}

function GetSingleAudioSection(props) {
  const audioInfo = props.content
  return (
    <>
      <div className="media_container">
        <div className="media_detail">
          <img src={MUSIC_WHITE_ICON} className="media_music_icon" alt="Music" />
          <div className="media_name">{audioInfo.title}</div>
        </div>
        <ReactAudioPlayer
          src={audioInfo.media}
          className="audio_player"
          autoPlay={false}
          controls
          controlsList="nofullscreen nodownload noremoteplayback"
          preload="auto"
          onPlay={event => stopAnyOtherCurrentlyPlayingAudio(event)}
          onEnded={()=>props.updateMeditationCount({id: audioInfo["_id"]})}
        />
      </div>
    </>
  )
}

function MeditationPage(props) {
  const [mediaOption, setMediaOption] = useState("audio");
  return (
    <>
      <MeditationContainer>
        <div className="header">
          <div className="title">Meditation</div>
          <div className="btn_grp">
            <button className={mediaOption === "audio" ? "active_btn media_btn" : "media_btn"} onClick={() => setMediaOption("audio")}>Audio</button>
            <button className={mediaOption === "video" ? "active_btn media_btn" : "media_btn"} onClick={() => setMediaOption("video")}>Video</button>
          </div>
        </div>
        {
          mediaOption === "audio" ?
            (props.meditations.audioContent && props.meditations.audioContent.length) ?
              props.meditations.audioContent.map((audioInfo, index) => {
                return <GetSingleAudioSection content={audioInfo} key={index} updateMeditationCount={props.updateMeditationCount}/>
              })
              : <DefaultContentPage>No audio(s) found</DefaultContentPage>
          : null
        }
        {
          mediaOption === "video" ?
            (props.meditations.videoContent && props.meditations.videoContent.length) ?
              <div className="video_container">
                {
                  props.meditations.videoContent.map((videoInfo, index) => {
                    return <GetSingleVideoSection content={videoInfo} key={index} updateMeditationCount={props.updateMeditationCount}/>
                  })
                }
              </div>
              : <DefaultContentPage>No video(s) found</DefaultContentPage>
          : null
        }
      </MeditationContainer>
    </>
  );
}

export default MeditationPage;
