import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { ContactEshaJourneyContainer, ContactFollowEshaImagesContainer } from './style'

function ContactEshaJourney(props) {
  const content = props.content
  return (
    <div>
      <ContactEshaJourneyContainer>
        <div className="dynamic_content header">{ReactHtmlParser(content["contact_follow_esha_heading"])}</div>
        <div className="dynamic_content sub_header">{ReactHtmlParser(content["contact_follow_esha_description"])}</div>
        <ContactFollowEshaImagesContainer>
        <iframe title="Grief and ..." src="https://cdn.lightwidget.com/widgets/37ea7650a31d53f0a2cdad71979359e3.html" scrolling={"no"} allowtransparency={"true"} className="lightwidget-widget" style={{width:"100%", border:"0", overflow:"hidden"}}></iframe>
        </ContactFollowEshaImagesContainer>
      </ContactEshaJourneyContainer>
    </div>
  );
}

export default ContactEshaJourney;