import styled from 'styled-components'

export const BlockedAccountContainer = styled.div`
  font-family: Muli;
  font-size: 0.7em;
  color: #404a59;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  box-sizing: border-box;
  width: 100%;
  margin: 10px auto;

  .user_details_container {
    background: #fff;
    padding: 1.5em;
    margin: 1em 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
  }

  .user_profile {
    flex: 1;
    text-align: center;

    &>img {
      width: 6em;
      height: 6em;
      max-width: 8em;
      max-height: 8em;
      border-radius: 50%;
    }
  }
  
  .user_name {
    flex: 5;
    font-size: 1.2em;
    text-transform: capitalize;
    margin-left: 10px;
  }

  .unblock_button {
    flex: 1;
  }

  .button {
    background: #af986f;
    color: #f2f3f4;
    font-size: 1em;
    font-family: Muli;
    padding: 10px;
    outline: none;
    cursor: pointer;
    border: none;
    text-align: center;
    max-width: 12em;
  }
`