import React, { useHistory } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';

import { ErrorPageContainer } from './styles';

function ErrorPage() {
  const history = useHistory();

  return (
    <>
      <Header />
      <ErrorPageContainer>
        <div className="title">404</div>
        <div className="sub_text">The webpage you are looking for is not available.</div>
        <div className="redirect_text_container">Take me back to <span className="redirect_text" onClick={() => history.push("/home")}>Home</span></div>
      </ErrorPageContainer>
      <Footer />
    </>
  )
} 

export default ErrorPage;
