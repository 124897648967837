import React, { Component } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { CLOSE_POPUP,  COMING_SOON_TITLE_1, PLAY_VIDEO_ICON } from '../../../../images';
import { GuidedReflectionModalContainer, ModalContainer, ButtonOptionContainer } from './styles';
import { isObjectEmpty } from '../../../../utils/helper';
import VideoModal from '../yoga_vinyasa_video_modal';

class GuidedReflection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      historyOfQuestionId: [],
      activeId: "",
      activeQuestion: "",
      answersOfActiveQuestion: [],
      activeReflection: "",
      activeReflectionMedia: "",
      activeReflectionMediaType: "",
      reflectionOverride: false,
      endOfSequence: false,
      videoModalOpen: false
    }
  }

  componentDidMount() {
    const { guidedReflection } = this.props
    this.setState({
      activeId: guidedReflection[0]._id,
      activeQuestion: guidedReflection[0].question,
      answersOfActiveQuestion: this.getActualAnswersOfActiveId(),
      historyOfQuestionId: []
    })

    let burgerElement = document.getElementById("burger-menu")
    document.body.style.overflow = 'hidden';
    if (burgerElement) {
      burgerElement.style.display = "none"
    }
  }

  componentWillUnmount() {
    let burgerElement = document.getElementById("burger-menu")
    document.body.style.overflow = 'unset';
    if (burgerElement) {
      burgerElement.style.display = "block"
    }
  }

  getActualAnswersOfActiveId = (guidedReflectionId = null) => {
    const { guidedReflection } = this.props
    let actualAnswers = []

    if (guidedReflectionId) {
      actualAnswers = this.getParticularInfoOfIdOrFull(guidedReflectionId, "answers")
    } else {
      actualAnswers = guidedReflection[0].answers
    }

    const allAnswersId = Object.values(actualAnswers).map(ele => ele.id)
    return (guidedReflection && guidedReflection.filter(questionObj => allAnswersId.includes(questionObj["_id"]))
      .map(answerObj => {
        return {
          answer: answerObj["answer"],
          id: answerObj["_id"]
        }
      })) || []
    }

  getToTheLastQuestion = () => {
    const { historyOfQuestionId } = this.state
    let tmp = [...historyOfQuestionId]
    const lastQuestionId = tmp.pop()
    const activeQuestion = this.getParticularInfoOfIdOrFull(lastQuestionId, "question")
    const answersOfActiveQuestion = this.getActualAnswersOfActiveId(lastQuestionId)
    this.setState({
      activeId: lastQuestionId,
      answersOfActiveQuestion,
      activeQuestion,
      historyOfQuestionId: tmp,
      activeReflection: "",
      activeReflectionMedia: "",
      activeReflectionMediaType: "",
    })
  }

  getParticularInfoOfIdOrFull = (guidedReflectionId, infoType = null) => {
    const { guidedReflection } = this.props
    const selectedGuidedCollection = guidedReflection.filter(questionObj => questionObj["_id"] === guidedReflectionId)[0]
    if (!isObjectEmpty(selectedGuidedCollection)) {
      if (infoType === "question") {
        return selectedGuidedCollection["question"]
      } else if (infoType === "answers") {
        return selectedGuidedCollection["answers"]
      } else if (infoType === "reflection") {
        return selectedGuidedCollection["reflection"]
      } else if (infoType === "reflection_media") {
        return selectedGuidedCollection["reflection_media"]
      }
    }
    return selectedGuidedCollection
  }

  fetchAndUpdateNextQuestionAnswers = (newId) => {
    const {question, reflection, reflection_media, reflection_media_type} = this.getParticularInfoOfIdOrFull(newId)
    const answersOfActiveQuestion = this.getActualAnswersOfActiveId(newId)
    let tmp = [...this.state.historyOfQuestionId]
    tmp.push(this.state.activeId)
    this.setState({
      activeId: newId,
      answersOfActiveQuestion,
      activeQuestion: question,
      historyOfQuestionId: tmp,
      activeReflection: reflection,
      activeReflectionMedia: reflection_media,
      activeReflectionMediaType: reflection_media_type,
      reflectionOverride: false,
      endOfSequence: answersOfActiveQuestion.length ? false : true
    })
  }

  stopAnyOtherCurrentlyPlayingAudio = (event) => {
    for (const audio of document.querySelectorAll('audio')) {
      if (audio !== event.currentTarget) {
        audio.pause();
        audio.currentTime = 0
      }
    }
  }

  guidedReflectionMedia = (media, mediaType) => {
    if(mediaType === "audio") {
      return (
        <>
          <ReactAudioPlayer
            src={media}
            className="audio_player"
            autoPlay={false}
            controls
            controlsList="nofullscreen nodownload noremoteplayback"
            preload="auto"
            onPlay={event => this.stopAnyOtherCurrentlyPlayingAudio(event)}
            onEnded={()=> null}
          />
        </>
      )
    } else {
      return (
        <>
          <div className="video_tile" onClick={() => this.setState({videoModalOpen: true})}>
            <div className="video_poster_container">
              <img src={COMING_SOON_TITLE_1} alt="poster" />
              <div className="overlay"><img src={PLAY_VIDEO_ICON} alt="Play Button" /></div>
            </div>
          </div>
          {
            this.state.videoModalOpen ?
              <VideoModal
                closeModal={() => this.setState({videoModalOpen: false})}
                onEnd={(id) => null}
                id= {null}
                videoSrc={media}
              /> : null
          }
        </>
      )
    }
  }

  render() {
    const { activeQuestion, answersOfActiveQuestion, historyOfQuestionId, activeReflection, reflectionOverride, endOfSequence, activeReflectionMedia, activeReflectionMediaType } = this.state
    return (
      <GuidedReflectionModalContainer>
        <ModalContainer>
          <div className="title">Guided Reflection</div>
          <img src={CLOSE_POPUP} className="close_popup" onClick={this.props.closeModal} alt="Close" />
          <div className="sub_title">
            Allow this Guided reflection to be your daily practice to aid in this journey.
          </div>
          {!(activeReflection && !reflectionOverride) ?
            <div className="modal_body">{activeQuestion || ""}</div>
            : <div className="modal_body">REFLECTION</div>
          }
          {
            (activeReflection && !reflectionOverride) ? 
              <div className="reflection_text" >
                {activeReflection}
                {(activeReflectionMedia && activeReflectionMediaType) ? this.guidedReflectionMedia(activeReflectionMedia, activeReflectionMediaType) : null}
              </div> :
              <ButtonOptionContainer>
                {
                  answersOfActiveQuestion.map((answer, index) => {
                    return <div onClick={() => this.fetchAndUpdateNextQuestionAnswers(answer.id)} className="options" key={index}>{answer.answer}</div>
                  })
                }
              </ButtonOptionContainer>
          }
          {
            historyOfQuestionId.length ?
              <div className="btn_grp">
                <i className="fas fa-angle-left arrows" onClick={() => this.getToTheLastQuestion()} />
                {!endOfSequence && activeReflection && !reflectionOverride ? <i className="fas fa-angle-right arrows" onClick={() => this.setState({ reflectionOverride: true })} /> : null}

              </div>
              : null
          }
        </ModalContainer>
      </GuidedReflectionModalContainer>
    )
  }
}

export default GuidedReflection;