import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import { LOGO, HOME_ESHA_NEW } from '../../../../images'
import { ComingSoonContainer, HeaderLogo, ComingSoonSection } from './styles'

function ComingSoon(props) {
  const content = props.content
  return (
    <>
      <ComingSoonContainer>
        <HeaderLogo>
          <img src={LOGO} alt="Esha Estar logo" />
        </HeaderLogo>
        <div className="coming_header_container">
          <ComingSoonSection image>
            <img src={HOME_ESHA_NEW} alt="Coming soon" />
          </ComingSoonSection>
          <ComingSoonSection text>
            <div className="dynamic_content coming_soon_heading">{ReactHtmlParser(content["coming_soon_heading"])}</div>
            <div className="dynamic_content coming_soon_text_1">{ReactHtmlParser(content["coming_soon_text_1"])}</div>
            <div className="dynamic_content coming_soon_sub_text_1">{ReactHtmlParser(content["coming_soon_sub_text_1"])}</div>
            <div className="dynamic_content coming_soon_sub_text_2">{ReactHtmlParser(content["coming_soon_sub_text_2"])}</div>
          </ComingSoonSection>
        </div>
      </ComingSoonContainer>
    </>
  );
}

export default ComingSoon;
