export const API_GET_STATIC_CONTENT = "/google-sheet/data"

export const API_GET_USER_PROFILE = "/auth/profile"
export const API_GET_LOGGED_USER_PROFILE = "/users"
export const API_GET_USER_BY_USER_ID = "/users/profile/"
export const API_UPDATE_USER_PROFILE = "/users/profile"
export const API_GET_QUESTION_OF_THE_DAY = "/users/question/get"
export const API_USER_PROFILE_ACTION = "/users/follow?action="

export const API_GET_MEDITATIONS = "/meditations"
export const API_GET_YOGA_NIDRAS = "/yogaNidras"
export const API_GET_YOGA_VINYASA = "/yogaVinyasas"

export const API_GET_ALL_JOURNALS = "/journals/all"
export const API_GET_MY_JOURNALS = "/users"
export const API_GET_FOLLOWED_PEOPLE_JOURNALS = "/journals/get"
export const API_LIKE_JOURNAL = "/journals/like/1"
export const API_UNLIKE_JOURNAL = "/journals/like/0"
export const API_SAVE_JOURNAL = "/journals/?date="
export const API_GET_SPECIFIC_DATED_JOURNAL = "/journals/entries?date="
export const API_SHARE_UNSHARE_JOURNAL = "/journals/isPublic?action="
export const API_GET_GUIDED_REFLECTION = "/guidedJournals"
export const API_GET_LOGOUT_USER = "/users/logout"
export const API_PRIVATE_PUBLIC_USER = "/users/private?action="
export const API_UPDATE_MEDITATION_COUNT = "/meditations"
export const API_UPDATE_YOGA_VINYASA_COUNT = "/yogaVinyasas"
export const API_UPDATE_YOGA_NIDRA_COUNT = "/yogaNidras"
