import React, { Component } from 'react';
import { connect } from 'react-redux'
import ProgramView from '../../components/common/program_list_view';
import Header from '../../components/common/header';
import Footer from '../../components/common/footer';
import ProgramResource from '../../components/common/program_additional_resource';
import { ProgramsListContainer } from './styles';
import ReactHtmlParser from 'react-html-parser';
import { programInfo } from './program_info';

class ProgramScreen extends Component {
  programName = "";
  constructor(props) {
    super(props)
    this.state = {
      programContent: undefined,
    }
  }

  componentDidMount() {
    const { programType } = this.props
    if (programType === "general_grief" && Object.keys(programInfo["general_grief"]).length) {
      this.programName = "General Grief";
      this.setState({ programContent: programInfo["general_grief"] });
    } else if (programType === "i_am_dying" && Object.keys(programInfo["i_am_dying"]).length) {
      this.programName = "I am dying";
      this.setState({ programContent: programInfo["i_am_dying"] });
    } else if (programType === "lost_a_loved_one" && Object.keys(programInfo["lost_a_loved_one"]).length) {
      this.programName = "Just Lost a Loved One";
      this.setState({ programContent: programInfo["lost_a_loved_one"] });
    } else if (programType === "self_care" && Object.keys(programInfo["self_care"]).length) {
      this.programName = "Self Care";
      this.setState({ programContent: programInfo["self_care"] });
    } else if (programType === "spiritual" && Object.keys(programInfo["spiritual"]).length) {
      this.programName = "Spiritual Growth and Guidance";
      this.setState({ programContent: programInfo["spiritual"] });
    }
  }

  render() {
    const { programContent } = this.state
    const content = this.props.staticWebContent
    if (!programContent) {
      return null
    }
    let programCards = programContent["cards"];
    let recommendedBooks = programContent["workBooks"]
    return (
      <>
        <Header />
        <ProgramsListContainer>
          <div className="title_1">Programs For:</div>
          <div className="title_2">{this.programName}</div>
          <div className="program_container">
            {
              programCards.map((programObj, index) => {
                return <ProgramView key={index} align={index + 1} programTitle={ReactHtmlParser(content[programObj.titleId])} programInfo={ReactHtmlParser(content[programObj.descriptionId])} />
              })
            }
          </div>
          <div className="recommended_book_list">
            Workbooks:
              {
              (recommendedBooks && recommendedBooks.length) ? recommendedBooks.map((book, index) => {
                return (
                  <span className="dynamic_content book_link" key={index} onClick={() => window.location.replace(book.link)}>
                    {book.bookTitle}
                  </span>
                )
              }) : null
            }
          </div>
        </ProgramsListContainer>
        <ProgramResource />
        <Footer content={this.props.staticWebContent} />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    staticWebContent: state.static_content.staticWebContent,
  }
}

export default connect(mapStateToProps)(ProgramScreen);
