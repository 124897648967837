import styled from 'styled-components'

export const ProgramsListContainer = styled.div`
  background: #f2f3f4;
  font-family: Muli;
  color: #404a59;
  padding: 1em 10.5% 2em;

  .title_1 {
    font-style: italic;
    font-size: 1.5em;
    text-align: center;
    font-family: Cormorant;
  }

  .title_2 {
    font-family: Cormorant;
    font-style: italic;
    font-weight: bold;
    font-size: 2.3em;
    line-height: 1.3em;
    text-align: center;
  }

  .program_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    margin-top: 2.5em;
  }

  .recommended_book_list {
    text-align: center;
    font-size: 1.4em;
    font-style: italic;
    font-family: Cormorant;
    font-weight: bold;
    margin: 1em auto 0;
    width: 65%;
  }

  .book_link {
    cursor: pointer;
  }

	@media screen and (max-width: 767px) {
    padding: 4%;

    .program_container {
      display: block;
      flex-direction: unset;
      flex-wrap: unset;
    }

    .recommended_book_list {
      font-size: 1.3em;
      margin: 1em 0 0;
      width: 100%;
    }
	}
`