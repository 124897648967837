import styled from 'styled-components'

export const HomeProgramContainer = styled.div`
  background: #af986f;
  padding: 2em 8% 3em;
  color: #404a59;

  .title {
    font-family: Cormorant;
    font-size: 2em;
    font-weight: bold;
    text-align: center;
  }

  .sub_title {
    font-size: 1.5em;
    text-align: center;
    font-style: italic;
  }

  .program_cards_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin: 2em 0 0;
  }

  .program_card {
    flex: 1;
    position: relative;
    margin: 2%;

    &>img {
      width: 100%;
    }
  }

  .program_button {
    cursor: pointer;
    position: absolute;
    padding: 10px 0px;
    width: 100%;
    background: #f2f3f4;
    background: linear-gradient(to left, #f2f3f4 50%, #404a59 50%);
    background-size: 200% 100%;
    background-position:right bottom;
    transition:all 0.5s ease;
    color: #404a59;
    text-align: center;
    top: 40%;
    font-size: 1.4vw;    
  }

  .program_button:hover {
    color: #f2f3f4;
    background-position:left bottom;
  }

	@media screen and (max-width: 767px) {
    padding: 2em 4%;

    .program_cards_container {
      flex-direction: column;
      margin: 2em 0 0;
    }

    .program_card {
      margin: 4%;
    }

    .program_button {
      font-size: 1em;
    }
  }
`
