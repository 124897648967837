import styled from 'styled-components';

export const ProgramViewContainer = styled.div`
  margin: ${props => props.margin};
  flex-basis: 45%;

  .program_title {
    font-family: Cormorant;
    font-size: 1.8em;
    font-weight: bold;
    line-height: 1.2em;
  }

  .program_info {
    font-size: 0.9em;
    padding-top: 3%;
    width: 85%;
  }

	@media screen and (max-width: 767px) {
    margin: 2em 0;
    flex-basis: unset;

    .program_title, .program_info {
      text-align: center;
    }

    .program_info {
      width: 100%;
      font-size: 1em;
    }
	}
`