import React, {Component} from 'react';
import Header from '../../components/common/header'
import Footer from '../../components/common/footer'
import AboutEshaHead from '../../components/pages/about_esha/about_esha_head'
import AboutEshaCard from '../../components/pages/about_esha/about_esha_card'
import { connect } from 'react-redux'

class AboutEsha extends Component {
  render() {
    return (
      <div>
        <Header />
        <AboutEshaHead content={this.props.staticWebContent}/>
        <AboutEshaCard content={this.props.staticWebContent}/>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    staticWebContent: state.static_content.staticWebContent,
  }
}

export default connect(mapStateToProps)(AboutEsha)

