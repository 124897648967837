import React from 'react-router-dom';
import { NoConnectionPageContainer, HeaderLogo } from './styles';
import { LOGO } from '../../../images'

function NoConnectionPage(props) {

  return (
    <>
      <NoConnectionPageContainer minHeight={window.innerHeight + "px"}>
        <HeaderLogo>
          <img src={LOGO} alt="Esha Estar logo" />
        </HeaderLogo>
        <div className="text">{props.message}</div>
      </NoConnectionPageContainer>
    </>
  )
}

export default NoConnectionPage;
