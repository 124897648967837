import { useHistory } from "react-router-dom";

import { DashboardTilesContainer, Tile } from './styles'

function DashboardTiles() {
  const history = useHistory();

  return (
    <DashboardTilesContainer>
      <Tile bgCol={"#d7ddd8"} onClick={() => history.push("/community")}>Community Shares</Tile>
      <Tile bgCol={"#e5e5e3"} onClick={() => history.push("/yoga_nidra")}>Yoga Nidra</Tile>
      <Tile bgCol={"#e5ebf2"} onClick={() => history.push("/meditation")}>Meditations</Tile>
      <Tile bgCol={"#af986f"} onClick={() => history.push("/yoga_vinyasa")}>Yoga Vinyasa</Tile>
    </DashboardTilesContainer>
  )
}

export default DashboardTiles;