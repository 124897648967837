import styled from 'styled-components'

export const YogaVinyasaContainer = styled.div`
	background: #f2f3f4;
	font-family: Muli;
	color: #404a59;
	padding: 4em 10.5% 2em;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  font-size: 1em;
  box-sizing: border-box;

  .title {
    font-size: 1.6em;
	  font-family: Cormorant;
    justify-content: flex-start;
    font-weight: bold;
  }

  .video_poster_container {
    position: relative;

    &>img {
      width: 100%;
      max-width: 30em;
    }
  }

  .video_poster_container:hover .overlay {
    opacity: 1;
  }

  .video_container {
    margin: 2% 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
  }

  .overlay {
    position: absolute; 
    bottom: 0; 
    background: #404a59b3;
    background: #404a59b3;
    color: #f1f1f1; 
    width: 100%;
    height: 100%;
    transition: .5s ease;
    opacity:0;
    color: white;
    font-size: 20px;
    padding: 20px;
    text-align: center;
    
    &>img {
      width: 100%;
      max-width: 25em;
    }
  }

  .video_tile {
    cursor: pointer;
    margin: 2%;
    flex-basis: 20%;
  }

  .video_info {
    font-size: 1.2em;
    padding-top: 10px;
  }

	@media screen and (max-width: 767px) {
		padding: 4%;

    .title {      
      font-size: 2em;
      text-align: center;
    }

    .video_container {
      margin: 2% 0 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .video_tile {
      margin: 4% 0;
    }

    .video_info {
      font-size: 1em;
      text-align: center;
    }
	}
`