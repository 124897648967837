import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../components/common/header';
import Footer from '../../components/common/footer';
import UserJournalProfile from '../../components/pages/user_journal/user_profile';
import UserJournalList from '../../components/pages/user_journal/user_list';
import { getUserByUserId, } from '../../redux/actions/user_actions';
import Loader from '../../components/common/loader';
import { isObjectEmpty, redirectWithOptionalMessage } from '../../utils/helper';

class UserJournal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      specificUserInfo: {}
    }
  }

  componentDidMount() {
    if (isObjectEmpty(this.props.userInfo)) {
      redirectWithOptionalMessage("/dashboard", this.props.history)
      return
    }
    const userId = this.props.match.params.id
    if (userId) {
      this.props.getUserByUserId(userId).then(userResp => {
        if (userResp && userResp.status === 200) {
          this.setState({ specificUserInfo: userResp.data.data })
        } else {
          redirectWithOptionalMessage("/dashboard", this.props.history, userResp.response.data.error.message)
        }
      })
    } else {
      redirectWithOptionalMessage("/dashboard", this.props.history)
    }
  }

  render() {
    const { specificUserInfo } = this.state;
    const { userInfo } = this.props
    return (
      <>
        {
          isObjectEmpty(specificUserInfo) ? <Loader /> :
            <>
              <Header />
              <UserJournalProfile specificUserInfo={specificUserInfo} currentLoggedUser={userInfo} />
              <UserJournalList specificUserInfo={specificUserInfo} />
              <Footer />
            </>
        }
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.user_content.userInfo,
  };
}

const mapDispatchToProps = {
  getUserByUserId,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserJournal);
