import styled from 'styled-components'

export const ContactEshaJourneyContainer = styled.div`
  background: #af986f;
  padding: 2.5em 10.5% 5em;  

  .header {
    font-family: Cormorant;
    font-size: 2.2em;
    font-weight: bold;
    color: #404a59;
  }

  .sub_header {
    font-family: Cormorant;
    font-size: 1.5em;
    color: #404a59;
  }

  .follow_notification {
    position: absolute;
    right: 0;
    top: 13%;
    width: 21%;
    padding: 0.5em;
    background: yellow;
    font-size: 1.2em;
    font-family: Cormorant;
    color: #404a59;
  }

  @media screen and (max-width: 767px) {
    padding: 10% 4%;
    text-align: center;

    .header {
      font-size: 1.5em;
    }

    .sub_header {
      font-size: 1.2em;
    }
  }

`

export const ContactFollowEshaImagesContainer = styled.div`
  display: flex;
  flex-direction: row; 
  margin-top: 3em;

  &>div>img {
    width: 90%;
  }

  @media screen and (max-width: 768px) {
    &>div>img {
      width: 90%;
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 1.5em;
    
    &>div {
      padding: 1em;

      &>img {
        width: 100%;
      }
    }
  }
`