import React from 'react';
import { AccountContainer } from './styles';
import { connect } from 'react-redux';
import { makeUserPrivateOrPublic } from '../../../../redux/actions/user_actions';

function AdvanceSettings(props) {
  const isPrivateAccount = props.user["private"]
  
  const togglePrivatePublicHandler = () => {
    props.setLoading(true)
    props.makeUserPrivateOrPublic(!isPrivateAccount).then(resp => {
      props.setLoading(false)
    })
  }

  return (
    <AccountContainer>
      <div className="user_details_container">
        <div className="setting_option">Private Account</div>
        <div className="toggle_button">
          <label className="switch" >
            <input type="checkbox" checked={isPrivateAccount} onChange={togglePrivatePublicHandler}/>
            <span className="slider round"></span>
          </label>
        </div>
      </div>
    </AccountContainer>
  )
}
const mapDispatchToProps = {
  makeUserPrivateOrPublic
}

export default connect(null, mapDispatchToProps)(AdvanceSettings)