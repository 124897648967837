import styled, { css } from 'styled-components'

export const HomeCarouselContainer = styled.div`
  .control-dots {
    .dot {
      opacity: unset;
      width: 1vw;
      height: 1vw;
      box-shadow: unset;
      background: #fff;
      border: 2px solid #404a59;
    }

    .selected {
      background: #404a59;
      border: 2px solid #404a59;
    }
  }

	@media screen and (max-width: 767px) {
    .control-dots {
      .dot {
        width: 2vw;
        height: 2vw;
        border: 1px solid #404a59;
      }
  
      .selected {
        border: 2px solid #404a59;
      }
    }
  }
`

export const HomeCarouselImgContainer = styled.div`
	position: relative;

	& > img {
    width: 100%;
	}

	${props => props.right && css`
		&>div {
			position: absolute;
			right: 12%;
			top: 8%;
			font-family: Cormorant;
			color: white;

			b {
				font-size: 3.6vw;
			}
		}

		.sub_text {
			line-height: 1.2em;
			font-style: italic;
			font-size: 2.5vw;
		}
	`};

	${props => props.bottom && css`
		&>div {
			position: absolute;
			right: 6%;
			bottom: 9%;
			font-family: Cormorant;
			color: white;
			font-style: italic;

			b {
				font-size: 3.5vw;
			}
		}

		.focus_box {
			background: #af986f;
			padding: 3%;
			font-size: 1.8vw;
			text-align: center;
			line-height: 1.2em;
			margin-top: 1em;
			float: right;
			width: 45%;
		}

		.sub_text {
			line-height: 1.2em;
			font-style: italic;
			font-size: 2.5vw;
		}
	`};

	${props => props.left && css`
		&>div {
			position: absolute;
			left: 5%;
			top: 35%;
			font-size: 3.2vw;
			font-family: Cormorant;
			font-style: italic;
			color: white;
		}

		.sub_text {
			line-height: 1.2em;
			font-style: italic;
			font-size: 2.5vw;
		}
	`};

	${props => props.fullLeft && css`
		&>div {
			position: absolute;
			left: 5%;
			top: 25%;
			font-size: 3.2vw;
			font-family: Cormorant;
			font-style: italic;
			color: white;
			text-align: left;
		}

		div > sup{
			font-size: .3em;
			position: relative;
			top: -1.5em;
		}

		.sub_text {
			line-height: 1.2em;
			font-style: italic;
			font-size: 2.5vw;
		}

		.quote_text {
			padding-top: 15%;
			line-height: 1.2em;
			font-style: italic;
			font-size: 2.5vw;
		}
	`};
`
