import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import { Provider } from 'react-redux'
import store from './redux/store'
import axios from 'axios';
import {getSpecificConfig} from './utils/helper'
window.store = store

axios.defaults.baseURL = getSpecificConfig("REACT_APP_API_URL")

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider >
  </React.StrictMode>,
  document.getElementById('root')
);
