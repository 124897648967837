import { useAuth0 } from "@auth0/auth0-react";
import { setLocalStorageItem } from '../utils/helper'
import { toast } from 'react-toastify';

const LoginCallBack = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const callSecureApi = async () => {
    try {
      const token = await getAccessTokenSilently();
      toast.success("You are successfully logged in", { toastId: "login_success" })
      setLocalStorageItem("auth0_access_token", "Bearer " + token)
      props.history.push("/dashboard")
    } catch (error) {
      toast.error("Unable to Login", { toastId: "login_error" })
      props.history.push("/home")
    }
  };
  callSecureApi()
  return null
};

export default LoginCallBack;