import axios from "axios";
import { GET_USER_INFO, GET_QUESTION_OF_THE_DAY, UPDATE_USER_INFO } from "../types";
import { API_GET_USER_PROFILE, API_UPDATE_USER_PROFILE, API_GET_QUESTION_OF_THE_DAY, API_USER_PROFILE_ACTION, API_GET_USER_BY_USER_ID, API_GET_LOGGED_USER_PROFILE, API_GET_LOGOUT_USER, API_PRIVATE_PUBLIC_USER } from '../../config/api/endpoint'
import { getHeadersForAPICall, setLocalStorageItem } from '../../utils/helper'


export const getUserInformation = () => {
  let headers = getHeadersForAPICall("auth0_access_token")
  return dispatch => {
    return axios
      .put(API_GET_USER_PROFILE, null, { headers })
      .then(response => {
        const jwt_token = response.data.data.token
        setLocalStorageItem("jwt_token", jwt_token)
        dispatch({
          type: GET_USER_INFO,
          payload: response
        });
        return response
      })
      .catch(err => {
        return err
      });
  };
};

export const UpdateUserInformation = (updatedUserInfo) => {
  let headers = getHeadersForAPICall()
  return dispatch => {
    return axios
      .put(API_UPDATE_USER_PROFILE, updatedUserInfo, { headers })
      .then(response => {
        dispatch({
          type: UPDATE_USER_INFO,
          payload: response
        });
        return response
      })
      .catch(err => {
        return err
      });
  };
};

export const getQuestionOfTheDay = () => {
  let headers = getHeadersForAPICall()
  return dispatch => {
    return axios
      .get(API_GET_QUESTION_OF_THE_DAY, { headers })
      .then(response => {
        dispatch({
          type: GET_QUESTION_OF_THE_DAY,
          payload: response
        });
        return response
      })
      .catch(err => {
        return err
      });
  };
};

export const userProfileActions = (userId, action) => {
  let headers = getHeadersForAPICall()
  return dispatch => {
    return axios
      .put(API_USER_PROFILE_ACTION+action, userId, { headers })
      .then(response => {
        return response
      })
      .catch(err => {
        return err
      });
  };
};

export const getUserByUserId = (userId) => {
  let headers = getHeadersForAPICall()
  return dispatch => {
    return axios
      .get(API_GET_USER_BY_USER_ID+userId, { headers })
      .then(response => {
        return response
      })
      .catch(err => {
        return err
      });
  };
};

export const getLoggedUserProfile = () => {
  let headers = getHeadersForAPICall()
  return dispatch => {
    return axios
      .get(API_GET_LOGGED_USER_PROFILE, { headers })
      .then(response => {
        dispatch({
          type: GET_USER_INFO,
          payload: response
        });
        return response
      })
      .catch(err => {
        return err
      });
  };
};

export const logOutUser = () => {
  let headers = getHeadersForAPICall()
  return dispatch => {
    return axios
      .put(API_GET_LOGOUT_USER, null, { headers })
      .then(response => {
        return response
      })
      .catch(err => {
        return err
      });
  };
};

export const makeUserPrivateOrPublic = (makePrivate) => {
  let headers = getHeadersForAPICall()
  return dispatch => {
    return axios
      .put(API_PRIVATE_PUBLIC_USER + makePrivate, null, { headers })
      .then(response => {
        dispatch({
          type: UPDATE_USER_INFO,
          payload: response
        });
        return response
      })
      .catch(err => {
        return err
      });
  };
};
