import styled from 'styled-components'

export const MeditationContainer = styled.div`
	background: #f2f3f4;
	font-family: Cormorant;
	color: #404a59;
	padding: 4em 10.5% 2em;
  width: 100%;
  box-sizing: border-box;

  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
  }

  .title {
    flex-basis: 30%;
    font-size: 1.6em;
    font-weight: bold;
  }

  .btn_grp {
    flex-basis: 70%;
    text-align: right;
  }

  .media_container {
    margin: 2% 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #404a59;
    color: #f2f3f4;
    padding: 15px 30px;
  }

  .media_btn {
    border: 1px solid #af986f;
    color: #af986f;
    font-size: 0.9em;
    cursor: pointer;    
    max-width: 7em;
    text-align: center;
    background: #f2f3f4;
    padding: 0.3em;
    font-style: italic;
    width: 33%;
    outline: none;
  }

  .active_btn {
    background: #af986f;
    color: #f2f3f4;
  }

  .media_music_icon {
    width: 40px;
    height: 40px;
  }

  .media_detail {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .media_name {
    font-size: 1em;
    font-family: Muli;
    margin-right: 15px;
    flex: 1;
  }

  audio {
    flex: 2;
    outline: none;
  }

  .video_container {
    margin: 2% 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
  }

  .video_tile {
    cursor: pointer;
    margin: 2%;
    flex-basis: 20%;
  }

  .video_poster_container {
    position: relative;

    &>img {
      width: 100%;
      max-width: 30em;
    }
  }

  .video_poster_container:hover .overlay {
    opacity: 1;
  }

  .overlay {
    position: absolute; 
    bottom: 0; 
    background: #404a59b3;
    background: #404a59b3;
    color: #f1f1f1; 
    width: 100%;
    height: 100%;
    transition: .5s ease;
    opacity:0;
    color: white;
    font-size: 20px;
    padding: 20px;
    text-align: center;
    
    &>img {
      width: 100%;
      max-width: 25em;
    }
  }

  .video_info {
    font-size: 1.2em;
    padding-top: 10px;
  }

	@media screen and (max-width: 767px) {
		padding: 4%;

    .media_music_icon {
      width: 20px;
      height: 20px;
    }

    .media_container {
      flex-direction: column;
      padding: 4%;
    }

    .media_detail {
      padding: 0 0 2%;
    }

    audio {
      width: 100%;
    }

    .video_container {
      margin: 2% 0 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .video_tile {
      margin: 4% 0;
    }

    .video_info {
      font-size: 1em;
      text-align: center;
    }
	}
`