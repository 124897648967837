import styled from 'styled-components'

export const AboutEshaHeaderContainer = styled.div`
	padding: 0 15%;
	background: #f2f3f4;
	color: #404a59;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	font-family: Muli;
	font-size: 1em;

	.title {
    font-size: 2.2em;
    font-weight: bold;
		font-family: Cormorant;
  }

  .subtitle {
    color: #af986f;
    font-size: 1.4em;
    font-style: italic;
    padding-top: 0.8%;
		font-family: Cormorant;
		line-height: 33px;
		font-weight: bold;
  }

	.about_esha_description_container {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
		margin: 6% 0 0;
	}

	.esha_img_container {
		flex: 1;
		flex-basis: 55%;

		&>img {
			width: 95%;
		}
	}

	.esha_desc_container {
		flex: 1;
		flex-basis: 40%;
		padding-left: 10px;
	}

	.desc_container {
		width: 100%;
		margin: 40px 0;
	}

	.desc_title {
		font-size: 2.2em;
    font-weight: bold;
		text-align: center;
		font-family: Cormorant;
		margin-bottom: 10px;
	}

	.about_desc {
		font-size: 1em;
		text-align: left;
		padding: 15px;
	}

	@media screen and (min-width: 1500px) {
		.about_esha_description_container {
			margin: 4% 0 0;
		}

		.esha_img_container {
			flex: 1;
			flex-basis: 55%;
	
			&>img {
				width: 80%;
			}
		}
	
		.esha_desc_container {
			flex: 1;
			flex-basis: 45%;
			padding-left: unset;
		}
	}

	@media screen and (max-width: 767px) {
    padding: 0 4%;

    .title {
			font-size: 2.4em;
      margin-bottom: 0.5em;
			text-align: center;
    }

    .subtitle {
			font-size: 1.8em;
			line-height: 40px;
			text-align: center;
    }

		.about_esha_description_container {
			flex-direction: column;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: center;
			width: 100%;
			margin: 5% 0;
		}

		.esha_img_container, .esha_desc_container {
			flex: unset;
			flex-basis: unset;
		}

		.esha_desc_container {
			margin: 6% 0 3%;
		}

		.esha_img_container {
			&>img {
				width: 100%;
			}
		}

		.about_desc {
			text-align: center;
		}

		.desc_container {
			margin: 0 0 40px;
		}
  }

	@media screen and (max-width: 500px) {
		.title {
			font-size: 1.8em;
      margin-bottom: 0.5em;
			text-align: center;
    }

		.subtitle {
			font-size: 1.2em;
			line-height: 30px;
			text-align: center;
    }

		.desc_title {
			font-size: 1.8em;
		}
	}
`