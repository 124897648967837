import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { EditAccountSectionContainer } from './styles';
import { convertBase64, getCompletePathOfAssest, redirectWithOptionalMessage } from '../../../../utils/helper';
import { PROFILE_EDIT_IMG_ICON, PROFILE_DEFAULT_AVATAR, PROFILE_USERNAME_ICON, PROFILE_NAME_ICON, PROFILE_ADD_IMG_ICON } from '../../../../images';
import { UpdateUserInformation } from '../../../../redux/actions/user_actions';
import { successToast, errorToast } from '../../../../utils/toast';

function UserProfile(props) {
  const history = useHistory();
  const user = props.userInfo;
  const [userName, setUserName] = useState(user["userName"] ? user["userName"] : "");
  const [nickName, setNickName] = useState(user["basic"] ? user["basic"].nickName : "");
  const [profileMessage, setprofileMessage] = useState(user["profileMessage"] ? user["profileMessage"] : "");
  const [newProfileImageBase64, setNewProfileImageBase64] = useState("");
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const currentProfilePicPath = getCompletePathOfAssest(user["profileImageURL"]);
  
  const isValid = (event) => {
    event.preventDefault();
    let isValidInfo = true;

    if (!nickName.trim() || !profileMessage.trim()) {
      errorToast("Please fill in all details")
      isValidInfo = false;
    } else {
      if(userName && userName.trim().length < 2) {
        errorToast("Please enter minimum 2 characters for username");
        isValidInfo = false;
      }
      if(nickName && nickName.trim().length < 2) {
        errorToast("Please enter minimum 2 characters for name");
        isValidInfo = false;
      }
      if (profileMessage && profileMessage.trim().length < 5) {
        errorToast("Please enter minimum 5 characters for message");
        isValidInfo = false;
      }
    }

    if (isValidInfo) {
      const profileInfo = { nickName, userName, profileMessage, profileImage: newProfileImageBase64 }
      props.setLoading(true)
      props.UpdateUserInformation(profileInfo).then(resp => {
        props.setLoading(false)
        if (resp && resp.status === 200) {
          successToast("Profile updated succesfully", { toastId: "profile_updated_success" });
        } else {
          const msg = resp?.response?.data?.error?.message || `Error uploading profile image. Image size exceeds.`
          errorToast(msg);
        }
      })
    }
  }

  const handleImgUpload = async (event) => {
    const file = event.target.files[0]
    if (file) {
      let reader = new FileReader();
      let { current } = uploadedImage;
      current.file = file;
      reader.onload = e => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      const base64 = await convertBase64(file)
      setNewProfileImageBase64(base64);
    }
  }

  return (
    <EditAccountSectionContainer>
      <div className="profile_logo" onClick={() => imageUploader.current.click()}>
        <input type="file" multiple={false} accept="image/*" onChange={handleImgUpload} ref={imageUploader} style={{ display: "none" }} />
        <div className="profile_img">
          <img ref={uploadedImage} src={currentProfilePicPath ? currentProfilePicPath : PROFILE_DEFAULT_AVATAR} alt="Default Avatar" />
        </div>
        <div className="edit_icon">
          {!currentProfilePicPath ? <img src={PROFILE_ADD_IMG_ICON} alt="plus" /> : <img src={PROFILE_EDIT_IMG_ICON} alt="edit" />}
        </div>
      </div>
      <form>
        <div className="form_field">
          <label htmlFor="username">Username</label><br />
          <div className="input_field">
            <input type="text" value={userName} id="username" name="username" onChange={e => setUserName(e.target.value)} placeholder="Enter username" />
            <img src={PROFILE_USERNAME_ICON} alt="User Name" />
          </div>
        </div>
        <div className="form_field">
          <label htmlFor="name">Name</label><br />
          <div className="input_field">
            <input type="text" value={nickName} id="nickName" name="nickName" onChange={e => setNickName(e.target.value)} placeholder="Enter name" />
            <img src={PROFILE_NAME_ICON} alt="Name" />
          </div>
        </div>
        <div className="form_field">
          <label htmlFor="message">Profile message</label><br />
          <textarea id="msg" value={profileMessage} name="msg" onChange={e => setprofileMessage(e.target.value)} placeholder="Enter profile message" />
        </div>
        <div className="form_field_btn_grp">
          <input type="submit" className="cancel_btn" id="cancel" value="CANCEL" onClick={() => redirectWithOptionalMessage("/dashboard", history)} />
          <input type="submit" className="submit_btn" id="submit" value="SUBMIT" onClick={isValid} />
        </div>
      </form>
    </EditAccountSectionContainer>
  )
}

const mapDispatchToProps = {
  UpdateUserInformation
}

export default connect(null, mapDispatchToProps)(UserProfile)