import { GET_GUIDED_REFLECTION } from "../types";

let initialState = {
  guidedReflection: {},
};

export default function guided_reflection_reducer (state = initialState, action) {
  switch (action.type) {
    case GET_GUIDED_REFLECTION:
      return {
        ...state,
        guidedReflection: action.payload.data.data
      };
    default:
      return state;
  }
}