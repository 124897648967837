import React from 'react';
import { BlockedAccountContainer } from './styles';
import { PROFILE_DEFAULT_AVATAR } from '../../../../images';

function BlockedUserProfile() {
  const blockedUserList = ["ankit", "preet", "vishwesh"] 
  return (
    <BlockedAccountContainer>
      {
        blockedUserList && blockedUserList.length && blockedUserList.map((user, index) => {
          return (
            <>
            <div className="user_details_container" key={index}>
              <div className="user_profile">
                <img src={PROFILE_DEFAULT_AVATAR} alt="User Avatar"/>
              </div>
              <div className="user_name">{user}</div>
              <div className="unblock_button">
                <div className="button">UNBLOCK</div>
              </div>
            </div>
            </>
          )
        }) 
      }
    </BlockedAccountContainer>
  )
}

export default BlockedUserProfile;