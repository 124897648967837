import React, { useState } from 'react';
import { connect } from 'react-redux';
import { JournalContainer } from './styles';
import { PROFILE_DEFAULT_AVATAR } from '../../../../images';
import { getCompletePathOfAssest } from '../../../../utils/helper';
import { userProfileActions, getLoggedUserProfile } from '../../../../redux/actions/user_actions'
import Loader from '../../../common/loader'
import { errorToast, successToast } from '../../../../utils/toast'

function UserJournalProfile(props) {
  const [loading, setLoading] = useState(false)
  const specificUser = props.specificUserInfo.user;
  const currentLoggedUser = props.currentLoggedUser
  const profileAvatar = specificUser["profileImageURL"] ? getCompletePathOfAssest(specificUser["profileImageURL"]) : PROFILE_DEFAULT_AVATAR;
  let currentUserFollowSpecificUser = false
  let currentUserBlockSpecificUser = false

  if (currentLoggedUser["followings"] && currentLoggedUser["followings"].length) {
    let allowFollowingOfCurrentUser = []
    currentLoggedUser["followings"].forEach(userInfo => {
      allowFollowingOfCurrentUser.push(userInfo["userId"])
    })
    currentUserFollowSpecificUser = allowFollowingOfCurrentUser.includes(specificUser["id"])
  }

  if (currentLoggedUser["blockedUsers"] && currentLoggedUser["blockedUsers"].length) {
    let allowBlockedUserOfCurrentUser = []
    currentLoggedUser["blockedUsers"].forEach(userInfo => {
      allowBlockedUserOfCurrentUser.push(userInfo["userId"])
    })
    currentUserBlockSpecificUser = allowBlockedUserOfCurrentUser.includes(specificUser["id"])
  }

  const handleFollowUnfollow = () => {
    const data = { userId: specificUser["id"] }
    const action = currentUserFollowSpecificUser ? "unfollow" : "follow"
    setLoading(true)
    props.userProfileActions(data, action).then(resp => {
      if (resp && resp.status === 200) {
        successToast(action === "follow" ? "Successfully followed" : "Successfully unfollowed", { toastId: "follow_success_" + action });
        props.getLoggedUserProfile().then(userResp => {
          setLoading(false)
        })
      } else {
        setLoading(false)
        const msg = resp.response.data.error.message
        errorToast(msg, { toastId: "follow_user_error" });
      }
    })
  }

  const handleBlockUnblock = () => {
    const data = { userId: specificUser["id"] }
    const action = currentUserBlockSpecificUser ? "unblock" : "block"
    setLoading(true)
    props.userProfileActions(data, action).then(resp => {
      if (resp && resp.status === 200) {
        props.getLoggedUserProfile().then(userResp => {
          setLoading(false)
        })
      } else {
        setLoading(false)
      }
    })
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <JournalContainer>
        <div className="user_profile_container">
          <div className="user_profile">
            <img src={profileAvatar} alt="Avatar" />
          </div>
         <div className="user_info">
            <div className="title">User's Journey</div>
            <div className="username">{specificUser && specificUser.userName}</div>
            <div className="btn_grp">
              {!currentUserBlockSpecificUser ?
                <div className={!currentUserFollowSpecificUser ? "default_btn active_follow" : "default_btn"} onClick={handleFollowUnfollow}>{!currentUserFollowSpecificUser ? "FOLLOW" : "UNFOLLOW"}</div>
                : null
              }
              <div className={!currentUserBlockSpecificUser ? "default_btn" : "default_btn active_block"} onClick={handleBlockUnblock}>{!currentUserBlockSpecificUser ? "BLOCK" : "UNBLOCK"}</div>
            </div>
          </div>
          <div className="user_msg">{specificUser["profileMessage"] ? specificUser["profileMessage"] : ""}</div>
        </div>
      </JournalContainer>
    </>
  );
}

function mapStateToProps(state) {
  return {
    userInfo: state.user_content.userInfo,
  };
}

const mapDispatchToProps = {
  userProfileActions,
  getLoggedUserProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(UserJournalProfile);