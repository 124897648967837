import React, { Component, useState } from 'react';
import { connect } from 'react-redux'
import Header from '../../components/common/header'
import CommunitySharesHeader from '../../components/pages/community_shares/community_header'
import CommunityStory from '../../components/pages/community_shares/community_story'
import Footer from '../../components/common/footer'
import Loader from '../../components/common/loader'
import { getAllJournals, getFollowedPeopleJournals } from '../../redux/actions/journal_actions'
import { redirectWithOptionalMessage, isObjectEmpty } from '../../utils/helper'
import DefaultContentPage from '../../components/common/no_content_page';

function GetJournalView(props) {
  const [allJournalForDate, setAllJournalForDate] = useState("")

  if (props.journalData.length !== 0) {
    return (
      props.journalData.map((journalGrp, index) => {
        return <CommunityStory journalType={props.journalType} key={index+props.journalType} journalGrp={journalGrp} allJournalForDate={allJournalForDate} setAllJournalForDate={setAllJournalForDate} bgCol={index % 2 ? "#d7ddd8" : ""} />
      })
    )
  } else {
    return (
      <DefaultContentPage>No Journals are available in this Section.</DefaultContentPage>
    )
  }
}

class CommunityShares extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isAllJournalsAvailable: false,
      isFollowedPeopleJournalsAvailable: false,
      showJournalsFrom: "following"
    }
  }

  componentDidMount() {
    if (isObjectEmpty(this.props.userInfo)) {
      redirectWithOptionalMessage("/dashboard", this.props.history)
      return
    }

    if (window.location.search.includes("allShare")) {
      this.setState({ showJournalsFrom: "public" })
    }

    this.props.getAllJournals().then(resp => {
      if (resp && resp.status === 200) {
        this.setState({ isJournalsAvailable: true })
      } else {
        redirectWithOptionalMessage("/dashboard", this.props.history, resp.response.data.error.message)
      }
    })

    this.props.getFollowedPeopleJournals().then(resp => {
      if (resp && resp.status === 200) {
        this.setState({ isFollowedPeopleJournalsAvailable: true })
      } else {
        redirectWithOptionalMessage("/dashboard", this.props.history, resp.response.data.error.message)
      }
    })
  }

  render() {
    const { isJournalsAvailable, showJournalsFrom, isFollowedPeopleJournalsAvailable } = this.state
    return (
      <>
        {(!isJournalsAvailable || !isFollowedPeopleJournalsAvailable) ? <Loader /> :
          <>
            <Header />
            <CommunitySharesHeader showJournalsFrom={showJournalsFrom} updateJournalsFrom={(value) => this.setState({ showJournalsFrom: value })} />
            { showJournalsFrom === "public" ?
              <GetJournalView journalData={this.props.allJournals} journalType={"public"} /> :
              <GetJournalView journalData={this.props.followedPeopleJournals} journalType={"following"} />
            }
            <Footer />
          </>
        }
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    allJournals: state.journal_content.allJournals,
    followedPeopleJournals: state.journal_content.followedPeopleJournals,
    userInfo: state.user_content.userInfo,
  };
}

const mapDispatchToProps = {
  getAllJournals,
  getFollowedPeopleJournals
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunityShares)
