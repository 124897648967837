
const BASE_URL = process.env.PUBLIC_URL + "/assets/images/"
const BASE_URL_ICON = process.env.PUBLIC_URL + "/assets/icons/"

export const LOGO = BASE_URL + "logo/esha-estar-newlogo-with-tagline-full-color-rgb_esha-estar-logo-with-tagline-full-color-rgb.png"
export const LOGO_MARK = BASE_URL + "logo/esha-estar-newlogomark_esha-estar-logo-with-tagline-full-color-rgb.png"
// export const COMING_SOON_IMAGE = BASE_URL + "coming_soon/coming_soon_image.jpg"
export const COMING_SOON_IMAGE = BASE_URL + "coming_soon/coming_soon_image_new.jpg"
export const COMING_SOON_TITLE_1 = BASE_URL + "coming_soon/coming_soon_tile_1.jpg"
export const COMING_SOON_TITLE_2 = BASE_URL + "coming_soon/coming_soon_tile_2.jpg"
export const COMING_SOON_TITLE_3 = BASE_URL + "coming_soon/coming_soon_tile_3.jpg"
export const ABOUT_US_1 = BASE_URL + "coming_soon/coming_soon_tile_3.jpg"
export const CAROUSEL_1 = BASE_URL + "home/carousel_1.jpg"
export const CAROUSEL_2 = BASE_URL + "home/carousel_2.jpg"
export const CAROUSEL_3 = BASE_URL + "home/carousel_3.jpg"

// Home Images
export const HOME_CAROUSEL_1 = BASE_URL + "home/home_carousel_1.jpg"
export const HOME_CAROUSEL_2 = BASE_URL + "home/home_carousel_2.jpg"
export const HOME_CAROUSEL_3 = BASE_URL + "home/home_carousel_3.jpg"
export const HOME_CAROUSEL_4 = BASE_URL + "home/home_carousel_4.jpg"
export const HOME_ESHA_MSG_IMG = BASE_URL + "home/home_esha_msg_img.jpg"
export const HOME_PROGRAM_GENERAL_GRIEF = BASE_URL + "home/home_program_general_grief.jpg"
export const HOME_PROGRAM_I_AM_DYING = BASE_URL + "home/home_program_i_am_dying.jpg"
export const HOME_PROGRAM_LOST_LOVED_ONE = BASE_URL + "home/home_program_i_just_lost_a_loved_on.jpg "
export const HOME_PROGRAM_SELF_CARE = BASE_URL + "home/home_program_self_care.jpg"
export const HOME_PROGRAM_SPIRITUAL_GUIDANCE = BASE_URL + "home/home_program_spiritual_guidance.jpg"

export const HOME_ESHA = BASE_URL + "home/home_esha.jpg"
export const HOME_ESHA_NEW = BASE_URL + "home/home_esha_1.jpg"
export const WAY_TO_ENGAGE_1 = BASE_URL + "way_to_engage/WTE_1.jpg"
export const WAY_TO_ENGAGE_2 = BASE_URL + "way_to_engage/WTE_2.jpg"
export const WAY_TO_ENGAGE_3 = BASE_URL + "way_to_engage/WTE_3.jpg"
export const WAY_TO_ENGAGE_4 = BASE_URL + "way_to_engage/WTE_4.jpg"
export const WAY_TO_ENGAGE_5 = BASE_URL + "way_to_engage/WTE_5.jpg"
export const WAY_TO_ENGAGE_6 = BASE_URL + "way_to_engage/WTE_7.png"

// About Esha
export const ABOUT_ESHA = BASE_URL + "about_esha/about_esha_head.jpg"
export const ABOUT_ESHA_NEW = BASE_URL + "about_esha/Esha_about_us_new.jpg"

// Contact Page
export const CONTACT_MAP = BASE_URL + "contact/map.jpg"

// Download Page
export const DOWNLOAD_PROMO = BASE_URL + "download/two-ipads.jpg"

// Dashboard Page
export const ESHA_AVATAR = BASE_URL + "dashboard/dashboard_esha.jpg"

// Mariposa Sanctuary
export const MARIPOSA_SUMMARY_IMG = BASE_URL + "mariposa/mariposa_santuary_new.jpg";

//ICONS
export const PROFILE_USERNAME_ICON = BASE_URL_ICON + "username.svg";
export const PROFILE_NAME_ICON = BASE_URL_ICON + "profile_name.svg";
export const PROFILE_ADD_IMG_ICON = BASE_URL_ICON + "plus_with_background.svg";
export const PROFILE_EDIT_IMG_ICON = BASE_URL_ICON + "edit.svg";
export const PROFILE_DEFAULT_AVATAR = BASE_URL_ICON + "default_user.svg";
export const CLOSE_POPUP = BASE_URL_ICON + "close_popup.svg";
export const DATE_RANGE_ICON = BASE_URL_ICON + "date_range.svg";
export const SHARE_ICON = BASE_URL_ICON + "share.svg";
export const DOWNLOAD_ICON = BASE_URL_ICON + "download.svg";
export const MUSIC_WHITE_ICON = BASE_URL_ICON + "music_white.svg";
export const CROSS_WHITE_ICON = BASE_URL_ICON + "close_white.svg";
export const PLAY_VIDEO_ICON = BASE_URL_ICON + "play_circle_outline.svg";
export const PLAY_VIDEO_ICON_GOLDEN = BASE_URL_ICON + "play_icon_golden.svg";

// Programs Additional Resources
export const PROGRAM_IMG_1 = BASE_URL + "program_additional_resource/program_additional_resource_1.png";
export const PROGRAM_IMG_2 = BASE_URL + "program_additional_resource/program_additional_resource_2.png";
export const PROGRAM_IMG_3 = BASE_URL + "program_additional_resource/program_additional_resource_3.jpg";
