import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import static_content_reducer from "./reducers/static_content_reducer";
import user_reducer from "./reducers/user_reducers";
import meditation_reducer from './reducers/meditation_reducers'
import yoga_nidra_reducer from './reducers/yoga_nidra_reducers'
import yoga_vinyasa_reducer from './reducers/yoga_vinyasa_reducers'
import journal_reducer from './reducers/journal_reducers'
import guided_reflection_reducer from './reducers/guided_reflection_reducer'
import { composeWithDevTools } from 'redux-devtools-extension'

const initialState = {};

const middleware = [thunk];

const reducers = combineReducers({
  static_content: static_content_reducer,
  user_content: user_reducer,
  meditation_content: meditation_reducer,
  yoga_nidra_content: yoga_nidra_reducer,
  yoga_vinyasa_content: yoga_vinyasa_reducer,
  journal_content: journal_reducer,
  guided_reflection_content: guided_reflection_reducer
});

const store = createStore(reducers, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store;