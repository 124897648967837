import React, { useState } from 'react';
import { YogaVinyasaContainer } from './styles';
import VideoModal from '../../common/modal/yoga_vinyasa_video_modal';
import { COMING_SOON_TITLE_1, PLAY_VIDEO_ICON } from '../../../images';
import DefaultContentPage from '../../common/no_content_page';

function GetSingleVideoSection(props) {
  const [openVideoModal, setVideoModal] = useState(false);
  const videoInfo = props.content
  return (
    <>
      <div className="video_tile" onClick={() => setVideoModal(true)}>
        <div className="video_poster_container">
          <img src={COMING_SOON_TITLE_1} alt="poster 1" />
          <div className="overlay"><img src={PLAY_VIDEO_ICON} alt="Play Button" /></div>
        </div>
        <div className="video_info">{videoInfo.title}</div>
      </div>
      {
        openVideoModal ?
          <VideoModal
            closeModal={() => setVideoModal(false)}
            videoSrc={videoInfo.media}
            onEnd={(id) => props.updateYogaVinyasaCount(id)}
            id={videoInfo["_id"]}
          /> : null
      }
    </>
  )
}

function YogaVinyasaPage(props) {
  return (
    <>
      <YogaVinyasaContainer>
        <div className="title">Yoga Vinyasa</div>
        <div className="video_container">
          {
            (props.yoga_vinyasas.videoContent && props.yoga_vinyasas.videoContent.length) ?
              props.yoga_vinyasas.videoContent.map((videoInfo, index) => {
                return <GetSingleVideoSection content={videoInfo} key={index} updateYogaVinyasaCount={props.updateYogaVinyasaCount}/>
              })
            :
            <DefaultContentPage>No video(s) found</DefaultContentPage>
          }
        </div>
      </YogaVinyasaContainer>
    </>
  );
}

export default YogaVinyasaPage;
