import React from 'react';
import { useHistory } from "react-router-dom";
import { ProgramResourceContainer, ProgramFeedsContainer } from './styles';
import { PROGRAM_IMG_1, PROGRAM_IMG_2, PROGRAM_IMG_3 } from '../../../images';

function GetURLLink(props) {
  return (
    <div className="program_resource_link">
      <a style={{ color: "#404a59", textDecoration: "none" }} href={props.link} target="_blank" rel="noreferrer">{props.title}</a>
    </div>
  )
}

function ProgramResource() {
  const history = useHistory();

  return (
    <>
      <ProgramResourceContainer>
        <div className="program_heading">Additional Resources For Your Journey</div>
        <ProgramFeedsContainer>
          <img src={PROGRAM_IMG_1} alt="program_feed_1" />
          <div className="tile_heading">Online</div>
          <div className="tile_description">
            <div className="program_resource_link">Free Download</div>
            <GetURLLink link={"https://www.instagram.com/eshaestar"} title={"Instagram"} />
            <GetURLLink link={"https://www.facebook.com/eshaestar"} title={"Facebook"} />
            <div className="program_resource_link">Mobile App (coming soon)</div>
          </div>
        </ProgramFeedsContainer>
        <ProgramFeedsContainer>
          <img src={PROGRAM_IMG_2} alt="program_feed_2" />
          <div className="tile_heading">Books &amp; Workbooks</div>
          <div className="tile_description">
            <GetURLLink link={"https://shop.eshaestar.com/"} title={"Finding Stillness"} />
            <GetURLLink link={"https://shop.eshaestar.com/"} title={"Wisdom of Death"} />
            <GetURLLink link={"https://shop.eshaestar.com/"} title={"Understanding Death"} />
            <GetURLLink link={"https://shop.eshaestar.com/"} title={"A Grief Workbook"} />
            <GetURLLink link={"https://shop.eshaestar.com/"} title={"End of Life Planner"} />
            <GetURLLink link={"https://shop.eshaestar.com/"} title={"A History of Me"} />
          </div>
        </ProgramFeedsContainer>
        <ProgramFeedsContainer>
          <img src={PROGRAM_IMG_3} alt="program_feed_3" />
          <div className="tile_heading">One-on-one</div>
          <div className="tile_description">
            <div className="program_resource_link" onClick={() => history.push("/mariposa_sanctuary")}>Mariposa Santuary</div>
            <div className="program_resource_link">In Person</div>
            <div className="program_resource_link">Zoom Sessions</div>
            <div className="program_resource_link">Group Zoom Sessions</div>
          </div>
        </ProgramFeedsContainer>
      </ProgramResourceContainer>
    </>
  );
}

export default ProgramResource;
