import axios from "axios";
import { GET_ALL_JOURNALS, GET_FOLLOWED_PEOPLE_JOURNALS, GET_MY_JOUNRALS } from "../types";
import { API_GET_ALL_JOURNALS, API_GET_FOLLOWED_PEOPLE_JOURNALS, API_LIKE_JOURNAL, API_UNLIKE_JOURNAL, API_GET_MY_JOURNALS, API_SAVE_JOURNAL, API_GET_SPECIFIC_DATED_JOURNAL, API_SHARE_UNSHARE_JOURNAL } from '../../config/api/endpoint'
import { getHeadersForAPICall } from '../../utils/helper'

export const getAllJournals = () => {
  let headers = getHeadersForAPICall()
  return dispatch => {
    return axios
      .get(API_GET_ALL_JOURNALS, { headers })
      .then(response => {
        dispatch({
          type: GET_ALL_JOURNALS,
          payload: response
        });
        return response
      })
      .catch(err => {
        return err
      });
  };
};

export const getMyJournals = () => {
  let headers = getHeadersForAPICall()
  return dispatch => {
    return axios
      .get(API_GET_MY_JOURNALS, { headers })
      .then(response => {
        dispatch({
          type: GET_MY_JOUNRALS,
          payload: response
        });
        return response
      })
      .catch(err => {
        return err
      });
  };
};

export const getFollowedPeopleJournals = () => {
  let headers = getHeadersForAPICall()
  return dispatch => {
    return axios
      .get(API_GET_FOLLOWED_PEOPLE_JOURNALS, { headers })
      .then(response => {
        dispatch({
          type: GET_FOLLOWED_PEOPLE_JOURNALS,
          payload: response
        });
        return response
      })
      .catch(err => {
        return err
      });
  };
};

export const likeJournal = (journalInfo) => {
  let headers = getHeadersForAPICall()
  return dispatch => {
    return axios
      .put(API_LIKE_JOURNAL, journalInfo, { headers })
      .then(response => {
        return response
      })
      .catch(err => {
        return err
      });
  };
};


export const unLikeJournal = (journalInfo) => {
  let headers = getHeadersForAPICall()
  return dispatch => {
    return axios
      .put(API_UNLIKE_JOURNAL, journalInfo, { headers })
      .then(response => {
        return response
      })
      .catch(err => {
        return err
      });
  };
};

export const saveJournal = (journalInfo, date) => {
  let headers = getHeadersForAPICall()
  return dispatch => {
    return axios
      .post(API_SAVE_JOURNAL+date, journalInfo, { headers })
      .then(response => {
        return response
      })
      .catch(err => {
        return err
      });
  };
};

export const shareUnshareJournal = (journalInfo, action) => {
  let headers = getHeadersForAPICall()
  let params = action === "share" ? true : false
  return dispatch => {
    return axios
      .put(API_SHARE_UNSHARE_JOURNAL+params, journalInfo, { headers })
      .then(response => {
        return response
      })
      .catch(err => {
        return err
      });
  };
};

export const getSpecificDatedJournal = (date) => {
  let headers = getHeadersForAPICall()
  return dispatch => {
    return axios
      .get(API_GET_SPECIFIC_DATED_JOURNAL+date, { headers })
      .then(response => {
        return response
      })
      .catch(err => {
        return err
      });
  };
};

