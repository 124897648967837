import styled from 'styled-components'

export const CommunitySharesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #404a59;
  font-family: Cormorant;
  color: #e5e5e3;
  padding: 1em 10.5% 1em;
  text-align: center;

  &>img {
    width: 15%;
    border-radius: 50%;
    padding: 1em 0;
    max-width: 12em;
  }

  .header {
    font-size: 2em;
    font-weight: bold;
  }

  .sub_header {
    font-size: 1em;
    font-family: 'Muli';
  }

  .toggle_btn {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 1em 0 0;
    padding: 5px;
    background: #e5e5e3;
    width: 35%;
    max-width: 20em;
  }

  .btn {
    flex-basis: 50%;
    cursor: pointer;
    font-size: 0.8em;
    font-family: Muli;
    color: #404a59;
    padding: 10px;
    text-align: center;
    outline: none;
    border: none;
  }

  .active_btn {
    background: #af986f;
    flex-basis: 50%;
    cursor: pointer;
    font-size: 0.8em;
    font-family: Muli;
    color: #f2f3f4;
    padding: 10px;
    text-align: center;
    outline: none;
    border: none;
  }

  @media screen and (max-width: 767px) {
    padding: 4%;

    &>img {
      padding: 1em 0;
    }

    .header {
      font-size: 1.2em;
    }

    .sub_header {
      font-size: 0.8em;
    }

    .toggle_btn {
      width: 50%;
    }

    .active_btn, .btn{
      padding: 5px;
    }
  }

  @media screen and (max-width: 400px) {
    &>img {
      width: 20%;
    }

    .toggle_btn {
      width: 65%;
    }

    .active_btn, .btn{
      padding: 5px;
    }
  }
`