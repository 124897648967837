import styled from 'styled-components'

export const HomeEshaMessageContainer = styled.div`
	font-family: Cormorant;
	color: #404a59;
	padding: 1.5em 10.5% 2em;

	.esha_message_title {
		font-size: 1.9em;
		margin: 0 0 1em;
		font-weight: bold;
		text-align: center;
		font-style: italic;
	}

	.esha_message_subtitle {
		font-size: 1.9em;
		margin: 0 0 1em;
		font-weight: bold;
		text-align: center;
		font-style: italic;
	}

	.esha_message_overlay {
		width: 100%;
		margin: -10% auto 1em;
		font-size: 1.9em;
		font-weight: bold;
		text-align: center;
		color: white;
		font-style: italic;
		transition: .5s ease;
		transform: translate(0%, -50%);
	}

	.esha_message_img {
		width: 64%;
		margin: 0 auto;
	}

	.img_container {
    position: relative;
		width: 100%;
    cursor: pointer;

		&>img {
			width: 100%;
		}
	}

	.img_container:hover .overlay {
    opacity: 1;
  }

	.overlay {
    position: absolute; 
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
    width: 60%;
    transition: .5s ease;
    opacity:0;
    font-size: 20px;
    padding: 20px;
    
    &>img {
      width: 50%;
      max-width: 25em;
    }
  }

	.esha_message_body {
		font-size: 1.7em;
    margin: 1.5em auto 0.5em;
    font-style: italic;
    font-weight: bold;
    line-height: 1.2em;
    text-align: center;
	}

	@media screen and (min-width: 1440px) {
		.esha_message_title {
			font-size: 3em;
		}

		.esha_message_subtitle {
			font-size: 3em;
		}

		.esha_message_overlay {
			font-size: 3.0em;

		.esha_message_img {
			width: 65%;
		}

		.esha_message_body {
			font-size: 2.2em;
		}
	}

	@media screen and (max-width: 767px) {
		padding: 4%;

		.esha_message_title {
			font-size: 2.5em;
			margin: 0 0 1em;
		}

		.esha_message_subtitle {
			font-size: 2.5em;
			margin: 0 0 1em;
		}

		.esha_message_overlay {
			font-size: 2.5em;
			margin: 6% auto 1em;

		.esha_message_img {
			width: 100%;
		}

		.esha_message_body {
			font-size: 1.4em;
		}
	}
`