import React, { Component } from 'react';
import ComingSoon from '../../components/pages/coming_soon/coming_soon_head'
import MoreToCome from '../../components/pages/coming_soon/more_to_come'
import { connect } from 'react-redux'
class ComingSoonSplash extends Component {

  render() {
    return (
      <>
        <ComingSoon content={this.props.staticWebContent} />
        <MoreToCome content={this.props.staticWebContent} />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    staticWebContent: state.static_content.staticWebContent,
  }
}

export default connect(mapStateToProps)(ComingSoonSplash)