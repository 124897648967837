import React, { Component } from 'react';
import { connect } from 'react-redux'
import Header from '../../components/common/header'
import Footer from '../../components/common/footer'
import ContactForm from '../../components/pages/contact/contact_form'
import ContactEshaJourney from '../../components/pages/contact/contact_esha_journey'

class Contact extends Component {
  render() {
    return (
      <div>
        <Header />
        <ContactForm content={this.props.staticWebContent} />
        <ContactEshaJourney content={this.props.staticWebContent} />
        <Footer />
      </div>
    )
  }
}



const mapStateToProps = state => {
  return {
    staticWebContent: state.static_content.staticWebContent,
  }
}

export default connect(mapStateToProps)(Contact)