import styled from 'styled-components'

export const EditAccountSectionContainer = styled.div`
  background: #fff;
  padding: 3em;
  font-family: Muli;
  font-size: 0.7em;
  color: #404a59;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  box-sizing: border-box;
  margin: 10px auto;

  .profile_logo {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    max-width: 10em;

  }

  .profile_img {
    width: 10em;
    height: 10em;
    max-width: 13em;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;

    &>img {
      width: 100%;
    }
  }

  .edit_icon {
    &>img {
      position: absolute;
      width: 25px;
      bottom: 0;
      right: 0%;
    }
  }

  form {
    width: 80%;
  }

  .form_field {
    margin: 2% 0;
  }

  label {
   font-size: 1em;
  }

  input, textarea {
    border: 1px solid #e5e5e3;
    width: 100%;
    padding: 5px;
    outline: none;
  }

  textarea {
    min-height: 5em;
    max-height: 5em;
  }

  .input_field {
    position: relative;

    &>img {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 3.5%;
      max-width: 2em;
      min-width: 1.5em;
    }
  }

  .form_field_btn_grp {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .cancel_btn {
    color: #af986f;
    background: unset;
    border: 1px solid #af986f;
    font-size: 1.1em;
    padding: 8px;
    outline: none;
    cursor: pointer;
    flex-basis: 45%;
    text-align: center;
  }

  .submit_btn {
    background: #af986f;
    color: #f2f3f4;
    font-size: 1.1em;
    padding: 8px;
    outline: none;
    cursor: pointer;
    border: none;
    flex-basis: 45%;
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    padding: 4%;
    margin: 15px auto;

    .profile_img {
      width: 7em;
      height: 7em;
    }

    .edit_icon {
      &>img {
        width: 20px;
      }
    }

    form {
      margin-top: 10px;
    }
  }
`