import { infoToast, errorToast } from '../utils/toast'
import {getConfig} from '../config/environments/config'
import moment from 'moment';

export function setLocalStorageItem(itemKey, itemValue) {
  localStorage.setItem(itemKey, itemValue);
}

export function getLocalStorageItem(itemKey) {
  return localStorage.getItem(itemKey)
}

export function deleteLocalStorageItem(itemKey) {
  localStorage.removeItem(itemKey)
}

export function getAPIKey() {
  return getSpecificConfig("REACT_APP_API_KEY")
}

export function getSpecificConfig(configKey) {
  return getConfig()[configKey]
}

export function getAPIBaseURL() {
  return getSpecificConfig("REACT_APP_API_URL")
}

export function getCompletePathOfAssest(apiPath) {
  if (apiPath && apiPath.startsWith("/")) {
    return getAPIBaseURL() + apiPath
  }
  return apiPath
}

export function getGreatingOnTimeBasis(date = new Date()) {
  let hours = date.getHours()
  if (hours < 12) {
    return "GOOD MORNING"
  } else if (hours < 18) {
    return "GOOD AFTERNOON"
  } else {
    return "GOOD EVENING"
  }
}

export function getHeadersForAPICall(type="jwt_token") {
  let headers = {
    "apikey": getAPIKey(),
    "Content-Type": "application/json",
  }
  if(type === "jwt_token" && getLocalStorageItem("jwt_token")) {
    headers.Authorization = getLocalStorageItem("jwt_token")
  } else if(type === "auth0_access_token" && getLocalStorageItem("auth0_access_token")) {
    headers.Authorization = getLocalStorageItem("auth0_access_token")
  }
  return headers
}

export function isObjectEmpty(obj = {}) {
  if(obj) {
    return Object.keys(obj).length === 0
  }
  return true
}

export function convertBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result);
    }
    fileReader.onerror = (error) => {
      reject(error);
    }
  })
}

export function redirectWithOptionalMessage(redirectURL, history, message = "") {
  if(message) {
    infoToast(message, "generic_error")
  }
  history.push(redirectURL)
}

export function clearUserAndRedirectToHome(history) {
  errorToast("Something went wrong!", "logout_error")
  deleteLocalStorageItem("jwt_token")
  deleteLocalStorageItem("auth0_access_token")
  history.push("/home")
}

export function getNextDate(currentDate) {
  return moment(currentDate).add(1,'days').format('YYYY-MM-DD');
}

export function getPreviousDate(currentDate) {
  return moment(currentDate).subtract(1,'days').format('YYYY-MM-DD');
}

export function getTimeFromNowForJournal(JournalTime) {
  let currentTimestamp = new Date().getTime();
  let journalTimestamp = new Date(JournalTime).getTime();
  let timeDiff = (currentTimestamp - journalTimestamp)/1000;

  if(timeDiff < 60) {
    return "Atleast 1 minute ago"
  } else if(timeDiff >= 60 && timeDiff < 3600) {
    return "Few minutes ago"
  } else {
    return moment(JournalTime).fromNow();
  }
}
