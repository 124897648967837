import React from 'react';
import { HomeProgramContainer } from './styles';
import { HOME_PROGRAM_GENERAL_GRIEF, HOME_PROGRAM_I_AM_DYING, HOME_PROGRAM_LOST_LOVED_ONE,
  HOME_PROGRAM_SELF_CARE, HOME_PROGRAM_SPIRITUAL_GUIDANCE } from '../../../images';

const PROGRAMIMAGELIST = {
  "lost_a_loved_one" : {title: "Recent Loss", image: HOME_PROGRAM_LOST_LOVED_ONE, path: "https://shop.eshaestar.com/collections/recent-loss"},
  "i_am_dying" : {title: "Imminent Loss", image: HOME_PROGRAM_I_AM_DYING, path: "https://shop.eshaestar.com/collections/imminent-loss"},
  "general_grief" : {title: "General Grief", image: HOME_PROGRAM_GENERAL_GRIEF, path: "https://shop.eshaestar.com/collections/all/general-grief"},
  "spiritual" : {title: "Spiritual Guidance", image: HOME_PROGRAM_SPIRITUAL_GUIDANCE, path: "https://shop.eshaestar.com/collections/all/spiritual-guidance"},
  "self_care" : {title: "Self Care", image: HOME_PROGRAM_SELF_CARE, path: "https://shop.eshaestar.com/collections/all/self-care"}
}

function HomeProgramSection() {
  const programNameList = PROGRAMIMAGELIST ? Object.keys(PROGRAMIMAGELIST) : null;

  return (
    <>
      <HomeProgramContainer>
        <div className="title">How May I Serve You?</div>
        <div className="sub_title">5 Pathways to Freedom: Packages &amp; Services for Each</div>
        <div className="program_cards_container">
          {
            (programNameList && programNameList.length) ?
            programNameList.map((programName, index) => {
              let programInfo = PROGRAMIMAGELIST[programName];
              let name = programInfo.title;
              let imgSrc = programInfo.image;
              let url = programInfo.path;

              return (
                <div key={index} className="program_card" onClick={() => window.location.replace(url)}>
                  <img src={imgSrc} alt={name}/>
                  <div className="program_button">{name}</div>
                </div>
              )
            }) :
            null
          }
        </div>
      </HomeProgramContainer>
    </>
  );
}

export default HomeProgramSection;
