import React, { Component } from 'react';
import Header from '../../components/common/header'
import HomeCarouselScreen from '../../components/pages/home/coming_soon'
import Testimonals from '../../components/pages/home/testimonals'
import HomeEshaMessageScreen from '../../components/pages/home/about_esha'
import WaysToEngage from '../../components/pages/home/ways_to_engage'
import HomeProgramSection from '../../components/common/home_program_section';
import Footer from '../../components/common/footer'
import { connect } from 'react-redux'

class Home extends Component {
  render() {
    return (
      <>
        <Header />
        <HomeCarouselScreen content={this.props.staticWebContent} />
        <HomeEshaMessageScreen content={this.props.staticWebContent} />
        <HomeProgramSection />
        <WaysToEngage content={this.props.staticWebContent} />
        <Testimonals content={this.props.staticWebContent} />
        <Footer content={this.props.staticWebContent} />
      </>
    )
  }
}


const mapStateToProps = state => {
  return {
    staticWebContent: state.static_content.staticWebContent,
  }
}

export default connect(mapStateToProps)(Home)
