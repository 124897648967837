import React, { useState } from 'react';
import TodaysJournalModal from '../../../common/modal/dashboard_todays_jornal_modal'
import { DashboardHeaderContainer } from './styles'
import moment from 'moment';
import { getGreatingOnTimeBasis } from '../../../../utils/helper'

function DashboardHeader(props) {
  let date = moment().format('MMMM DD, YYYY')
  const user = props.userInfo
  const questionOfTheDay = props.questionOfTheDay
  const [openTodaysJournalModal, setTodaysJournalModal] = useState(false);

  const closeTodaysJournalModal = () => {
    setTodaysJournalModal(false);
  }

  return (
    <>
      {
        openTodaysJournalModal ?
          <TodaysJournalModal closeModal={closeTodaysJournalModal} user={user} questionOfTheDay={questionOfTheDay} origin={"journal"} selectedJournalDate={null}/> :
          null
      }
      <DashboardHeaderContainer>
        <div className="greeting">
          <div className="header">{date}</div>
          <div className="sub_header">{getGreatingOnTimeBasis() + ", " + user["basic"].name}</div>
        </div>
        <div className="qstn_of_the_day" onClick={() => setTodaysJournalModal(true)}>
          <div className="header">
            Question of the Day:
        </div>
          <div className="sub_header">{questionOfTheDay.question}</div>
        </div>
      </DashboardHeaderContainer>
    </>
  )
}

export default DashboardHeader;
