import styled from 'styled-components'

export const FooterContainer = styled.div`
	padding: 3em 10.5% 3.5em;
  font-family: Cormorant;
	color: #404a59;
	background: #f2f3f4;

	.footer-logo {
		width: 55%;
		display: inline-block;
		text-align: right;
		
		.footer-image-text{
			width: 26%;
			text-align: center;
			margin-left: auto;

			& > img {
				width: 100%;
			}

			.logo-copywrite-text{
				font-size: 1em;
				font-family: 'Muli';
				font-weight: unset;
			}
		}
	}

	@media screen and (max-width: 767px) {
		padding: 1em;
		font-family: Cormorant;
		color: #404a59;
	
		.footer-logo {
			width: 100%;
			margin 1em 0;
			text-align: center;
	
			.footer-image-text{
				width: 100%;
				text-align: center;
				margin: 0.7em 0;
	
				& > img {
					width: 35%;
				}
	
				.logo-copywrite-text{
					font-size: 0.7em;
					font-family: 'Muli';
					font-weight: unset;
				}
			}
		}
	}
`

export const FooterLinkContentSection = styled.div`
  display: inline-block;
  width: 15%;
	vertical-align: top;
	.heading {
		font-weight: bold;
    font-size: 1.3em;
    font-style: italic;
		margin-bottom: 0.8em;
	}

	.content {
		font-family: 'Muli';
    font-size: 0.9em;
		cursor: pointer;
	}


	@media screen and (max-width: 767px) {
		display: inline-block;
		width: 33.3%;
		text-align: center;
		vertical-align: top;
		.heading {
			font-size: 1.2em;
			margin-bottom: 0;
			height: 2em;
			margin-left: 0;
			text-align: ${props => props.align ? props.align : "center"};
		}
	
		.content {
			text-align: ${props => props.align ? props.align : "center"};
			font-family: 'Muli';
			font-size: 0.8em;
		}
	}
`