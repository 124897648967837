import styled from 'styled-components'

export const NavBarContainer = styled.div`
	text-align: center;
	background: #f2f3f4;
	font-family: Cormorant;
	color: #af986f;
	padding: 4em 3% 2em;

	.navbar_container {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	.navbar_section_btn {
		flex-basis: 35%;
	}

	.navbar_section_url {
		flex-basis: 35%;
	}

	.navbar_section_logo {
		flex-basis: 30%;

		&>img {
			width: 50%;
			cursor: pointer;
		}
	}

	.nav_items {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
	}

	.navbar_section_btn {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}

	.home_btn {
		font-size: 2em;
		cursor: pointer;
		margin-left: 1em;
	}

	.nav_items {
		font-size: 0.7em;
	}

	.find_peace_button {
		color: #f2f3f4;
		max-width: 12em;
		background: #af986f;
		text-align: center;
		padding: 0.3em;
    font-size: 1.1em;
		font-style: italic;
		width: 33%;
		cursor: pointer;
	}

	.login_logout_btn {
		border: none;
		color: #f2f3f4;
		background: #af986f;
		max-width: 12em;
		text-align: center;
		padding: 0.3em;
    font-size: 1.1em;
		font-style: italic;
		width: 33%;
		outline: none;
		cursor: pointer;
    margin-left: 2em;
	}

	@media screen and (min-width:1300px) {
		.nav_items {
			font-size: 0.9em;
		}
	}

	@media screen and (min-width:768px) and (max-width: 1100px) {
		.login_logout_btn, .find_peace_button {
			font-size: 0.7em;
		}

		.home_btn {
			font-size: 1.5em;
		}
	}

	@media screen and (max-width: 767px) {
		padding: 0 0 4%;
		
		.image_container {
			text-align: center;
			& > img {
				width: 50%;
				margin-top: 2em;
			}
		}

		/* Position and sizing of burger button */
		.bm-burger-button {
			position: absolute;
			width: 36px;
			height: 30px;
			left: 25px;
			top: 25px;
		}
		
		/* Color/shape of burger icon bars */
		.bm-burger-bars {
			background: #404a59;
		}
		
		/* Color/shape of burger icon bars on hover*/
		.bm-burger-bars-hover {
			background: ;
		}
		
		/* Position and sizing of clickable cross button */
		.bm-cross-button {
			height: 24px;
			width: 24px;
		}
		
		/* Color/shape of close button cross */
		.bm-cross {
			background: #af986f;
		}
		
		/*
		Sidebar wrapper styles
		Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
		*/
		.bm-menu-wrap {
			position: fixed;
			height: 100%;
		}
		
		/* General sidebar styles */
		.bm-menu {
			background: #404a59;
			padding: 1.5em 1.5em 0;
			font-size: 1.15em;
		}
		
		/* Morph shape necessary with bubble or elastic */
		.bm-morph-shape {
			fill: #404a59;
		}
		
		/* Wrapper for item list */
		.bm-item-list {
			color: #af986f;
		}
		
		/* Individual item */
		.bm-item {
			display: inline-block;
		}
		
		/* Styling of overlay */
		.bm-overlay {
			background: rgba(0, 0, 0, 0.3);
		}

		.logo_image {
			texe-align:center;
			& > img {
				width: 100%;
				vertical-align: middle;
				max-width: 10em;
			}
		}

		.login_logout_btn {
			width: 100%;
			max-width: 165px;
      font-size: 0.8em;
      margin: 2em 0 0 0;
		}
	}
`

export const NavBarSection = styled.div`
	width: ${props => props.width ? props.width : "38%"};
	display: inline-block;
	text-align: ${props => props.textAlign ? props.textAlign : "left"};
	text-align: center;
 
	& > img {
		width: 28%;
		vertical-align: middle;
		max-width: 250px;
	}

	.find_peace_button {
		color: white;
		max-width: 165px;
		background: #af986f;
		text-align: center;
		padding: 0.3em;
    font-size: 1.1em;
    font-family: 'Cormorant';
		font-style: italic;
		width: 75%;
		margin-left: auto;
    margin-right: 14%;
	}

	.nav-items {
		display: flex;
		justify-content: space-between;
	}

	@media screen and (max-width: 767px) {
		width: 100%;
		display: block;

		.find_peace_button {
			font-size: 0.8em;
			width: auto;
			margin: 1em auto;
		}	
	}
`

export const NavBarElement = styled.div`
	font-size: 1em;
	cursor: pointer;
	vertical-align: top;
	color: #af986f;
	font-family: Muli;
	color: #404a59;
	padding: 0 2%;
	outline: none;
	font-weight: ${props => props.weight ? props.weight : "unset"};
	@media screen and (min-width: 768px) and (max-width: 1100px){
		font-size: 0.8em;
	}

	@media screen and (max-width: 767px) {
		width: 100%;
		display: block;
		font-size: 0.8em;
		cursor: pointer;
		margin: 0.7em 0;
		margin-left: ${props => props.marginLeft ? props.marginLeft : "unset"};
		color: #af986f;
		text-align: center;
	}

`

export const PromoMessageBarContainer = styled.div`
	width: ${props => props.width ? props.width : "100%"};
	display: inline-block;
	text-align: ${props => props.textAlign ? props.textAlign : "left"};
	text-align: center;
	color: white;
	background: #af986f;
	text-align: center;
	padding: 0.6em;
    font-size: 1.1em;
    font-family: 'Cormorant';
	font-style: italic;

	div > a{
		color: white;
		text-decoration: underline;
	}

	div > sup{
		font-size: .5em;
	}
 
	.find_peace_button {
		color: white;
		max-width: 165px;
		background: #af986f;
		text-align: center;
		padding: 0.3em;
    font-size: 1.1em;
    font-family: 'Cormorant';
		font-style: italic;
		width: 75%;
		margin-left: auto;
    margin-right: 14%;
	}

	.nav-items {
		display: flex;
		justify-content: space-between;
	}

	@media screen and (max-width: 767px) {
		width: 100%;
		display: block;

		.find_peace_button {
			font-size: 0.8em;
			width: auto;
			margin: 1em auto;
		}	
	}
`
