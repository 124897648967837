import React, { useState } from 'react';
import { SharedJournalListContainer, SharedJournalContainer } from './styles'
import moment from 'moment';

function GetUserJournalSection(props) {
  const userJournalInfo = props.userJournalInfo
  if (userJournalInfo && userJournalInfo.length !== 0) {
    return (
      userJournalInfo.map((journal, index) => {
        const journalQuestion = (journal.questionId && journal.questionId.question) ? journal.questionId.question : "" 
        if (!props.showAllJournal && index >= 1) {
          return null
        }
        return (
          <SharedJournalListContainer key={index}>
            <div className="journal_date"> {moment(journal["date"]).format('MMMM DD, YYYY')} </div>
            <div className="journal_question"> {journalQuestion} </div>
            <div className="journal_title">{journal["title"]}</div>
            <div className="journal">{journal["journal"] ? journal["journal"] : ""}</div>
          </SharedJournalListContainer>
        )
      })
    )
  } else {
    return (
      <div> No Journals are available in this Section</div>
    )
  }
}

function UserJournalList(props) {
  const [showAllJournal, setShowAllJournal] = useState(false)
  const user = props.specificUserInfo.user;
  const userJournalInfo = props.specificUserInfo.journals;
  return (
    <>
      <SharedJournalContainer>
        <div className="journey_container">
          <div className="user_activity_container">
            <div className="activity" >
              <div className="activity_count">{user.entries_count || 0}</div>
              <div>SHARED ACTIVITY</div>
            </div>
            <div className="activity" >
              <div className="activity_count">{user.completed_meditation_count || 0}</div>
              <div>COMPLETED MEDITATION</div>
            </div>
          </div>
          <div className="heading">Public Journal Entries</div>
          {
            <GetUserJournalSection userJournalInfo={userJournalInfo} showAllJournal={showAllJournal} />
          }
          {userJournalInfo.length > 1 ?
            <div className="footer_show_more_less" onClick={() => setShowAllJournal(!showAllJournal)}>
              {showAllJournal ? "Show less" : "Show More"}
            </div> : null
          }
        </div>
      </SharedJournalContainer>
    </>
  );
}

export default UserJournalList;