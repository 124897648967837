import React, { Component } from 'react';
import Header from '../../components/common/header'
import DashboardHeader from '../../components/pages/dashboard/dashboard_header'
import DashboardCards from '../../components/pages/dashboard/dashboard_cards'
import DashboardTiles from '../../components/pages/dashboard/dashboard_tiles'
import DashboardNote from '../../components/pages/dashboard/dashboard_note'
import Footer from '../../components/common/footer'
import EditProfileModal from '../../components/common/modal/edit_profile_modal'
import QuestionsModal from '../../components/common/modal/profile_modal_question'
import { getUserInformation, getQuestionOfTheDay, getLoggedUserProfile } from '../../redux/actions/user_actions'
import { getGuidedReflection } from '../../redux/actions/guided_reflection_actions';
import { connect } from 'react-redux'
import Loader from '../../components/common/loader'
import { clearUserAndRedirectToHome, getLocalStorageItem, isObjectEmpty, redirectWithOptionalMessage } from '../../utils/helper'

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isUserInfoAvailable: false,
      showProfilePopup: false,
      showQuestionPopup: false,
      isQuestionOfTheDayAvailable: false,
      isGuidedReflectionAvailable: false
    }
  }

  componentDidMount() {
    if(!getLocalStorageItem("jwt_token")) {
      this.props.getUserInformation().then(resp => {
        this.checkResponse(resp)
      })
    } else {
      this.props.getLoggedUserProfile().then(resp => {
        this.checkResponse(resp)
      })
    }
  }
  
  checkResponse = (resp) => {
    if (resp && resp.status === 200) {
      const user = resp.data.data.user
      if (user && !user["userName"]) {
        this.setState({ showProfilePopup: true })
      }
      if (user && !user["whyAreYouHere"].length) {
        this.setState({ showQuestionPopup: true })
      }
      if(isObjectEmpty(this.props.questionOfTheDay)) {
        this.props.getQuestionOfTheDay().then(resp => {
          if (resp && resp.status === 200) {
            this.setState({ isQuestionOfTheDayAvailable: true })
          } else {
            redirectWithOptionalMessage("/dashboard", this.props.history, resp.response.data.error.message)
          }
        })
      } else {
        this.setState({ isQuestionOfTheDayAvailable: true })
      }
      if(isObjectEmpty(this.props.guidedReflection)) {
        this.props.getGuidedReflection().then(guidedReflectionResp => {
          if (guidedReflectionResp && guidedReflectionResp.status === 200) {
            this.setState({ isGuidedReflectionAvailable: true })
          } else {
            redirectWithOptionalMessage("/dashboard", this.props.history, guidedReflectionResp.response.data.error.message)
          }
        })
      } else {
        this.setState({ isGuidedReflectionAvailable: true })
      }
      this.setState({ isUserInfoAvailable: true })
    } else {
      clearUserAndRedirectToHome(this.props.history)
    }
  }

  render() {
    let { showProfilePopup, showQuestionPopup, isUserInfoAvailable, isQuestionOfTheDayAvailable, isGuidedReflectionAvailable } = this.state
    let { userInfo, questionOfTheDay, guidedReflection } = this.props
    return (
      <>
        {(!isUserInfoAvailable || !isQuestionOfTheDayAvailable || !isGuidedReflectionAvailable) ? <Loader /> :
          <>
            {showProfilePopup ? <EditProfileModal userInfo={userInfo} closePopup={() => this.setState({ showProfilePopup: false })} /> :
              showQuestionPopup ? <QuestionsModal userInfo={userInfo} closePopup={() => this.setState({ showQuestionPopup: false })} /> :
                <>
                  <Header />
                  <DashboardHeader userInfo={userInfo} questionOfTheDay={questionOfTheDay} />
                  <DashboardCards userInfo={userInfo} guidedReflection={guidedReflection} />
                  <DashboardTiles />
                  <DashboardNote />
                  <Footer />
                </>
            }
          </>
        }
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.user_content.userInfo,
    questionOfTheDay: state.user_content.questionOfTheDay,
    guidedReflection: state.guided_reflection_content.guidedReflection
  };
}

const mapDispatchToProps = {
  getUserInformation,
  getQuestionOfTheDay,
  getLoggedUserProfile,
  getGuidedReflection
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
