import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useHistory } from "react-router-dom";
import { MariposaOfferingsContainer, OfferingList } from './styles';

function MariposaOfferings(props) {
  const history = useHistory();
  const content = props.content
  return (
    <>
      <MariposaOfferingsContainer>
        <div className="dynamic_content title">{ReactHtmlParser(content["mariposa_offerings_heading"])}</div>
        <OfferingList>
          <div className="offer_name">
            <div className="dynamic_content offer_title">{ReactHtmlParser(content["mariposa_offerings_offer_1_title"])}</div>
            <div className="dynamic_content offer_sub_title">{ReactHtmlParser(content["mariposa_offerings_offer_1_sub_title"])}</div>
          </div>
          <div className="dynamic_content offer_description">{ReactHtmlParser(content["mariposa_offerings_offer_1_description"])}</div>
        </OfferingList>
        <OfferingList>
          <div className="offer_name">
            <div className="dynamic_content offer_title">{ReactHtmlParser(content["mariposa_offerings_offer_2_title"])}</div>
            <div className="dynamic_content offer_sub_title">{ReactHtmlParser(content["mariposa_offerings_offer_2_sub_title"])}</div>
          </div>
          <div className="dynamic_content offer_description">{ReactHtmlParser(content["mariposa_offerings_offer_2_description"])}</div>
        </OfferingList>
        <OfferingList>
          <div className="offer_name">
            <div className="dynamic_content offer_title">{ReactHtmlParser(content["mariposa_offerings_offer_3_title"])}</div>
            <div className="dynamic_content offer_sub_title">{ReactHtmlParser(content["mariposa_offerings_offer_3_sub_title"])}</div>
          </div>
          <div className="dynamic_content offer_description">{ReactHtmlParser(content["mariposa_offerings_offer_3_description"])}</div>
        </OfferingList>
        <div className="find_peace_button" onClick={() => history.push("/contact")}>Connect Here</div>
      </MariposaOfferingsContainer>
    </>
  )
}

export default MariposaOfferings;