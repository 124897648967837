import styled from 'styled-components'

export const MainContainer = styled.div`
  padding: 0.5em 0 0;
  background: #404a59;

  @media screen and (max-width: 767px) {
    padding: 0.5em 0 0;
  }
`

export const CommunityStoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: ${props => props.bgCol ? props.bgCol : "#e5ebf2"};
  font-family: Cormorant;
  color: #404a59;
  padding: 1em 10.5% 1em;

  .header {
    font-size: 1.5em;
    font-weight: bold;
    margin: 1em 0 0;
  }

  .question {
    font-size: 1em;
    font-weight: bold;
    margin: 1em 0;
    width: 65%;
    text-align: center;
  }

  .footer {
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    margin: 1em 0;
  }

  .like_count {
    font-size: 1.3em;
    display: inline;
    margin-left: 0.7em;
  }

  .likes_container {
    margin: 0.5em 0 2em;
    cursor: pointer;
  }

  .fa-heart {
    color: white;
  }

  .sub_header {
    font-size: 1em;
    font-family: 'Muli';
    margin: 1em 0 3em;
    width: 70%;
    text-align: center;
  }

  .read_more {
    font-size: 1em;
    font-family: 'Muli';
    margin: 1em 0;
    width: 70%;
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    padding: 4%;

    .header {
      font-size: 1.2em;
    }

    .footer {
      font-size: 1em;
    }

    .question {
      font-size: 1em;
      width: 100%;
    }

    .likes_container {
      margin: 0.3em 0 1.5em;
      cursor: pointer;
    }

    .sub_header, .read_more {
      font-size: 0.8em;
      width: 100%;
    }
  }
`
export const Story = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background: #af986f;
  font-family: Cormorant;
  color: #404a59;
  padding: 1em 2em;
  width: 65%;
  max-width: 55em;
  
  .user_comment_card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }

  .user_img {
    flex: 0.5;

    &>div{
      width: 5em;
      height: 5em;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      background: black;

      &>img {
        width: 100%;
      }
    }
  }

  .user_name {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 3;
    align-items: center;
    margin-left: 1em;
  }

  .name_text {
    font-size: 1.2em;
    font-weight: bold;
  }

  .sub_text {
    font-size: 0.6em;
    font-family: Muli;
    margin-left: 10px;
  }

  .journal_title {
    font-family: Muli;
    font-size: 1.1em;
    font-weight: bold;
    text-transform: capitalize;
  }

  .story_title {
    font-size: 1em;
    font-family: Muli;
    margin: 0.5em 0 0.5em;
  }

  .story_content {
    font-size: 0.9em;
    font-family: Muli;
    margin: 1em 0;
    word-break: break-all;
  }

  .likes {
    cursor: pointer;
  }

  .user_follow_btn {
    flex: 1;
  }

  .follow_btn {
    width: 70%;
    cursor: pointer;
    background: #f2f3f4;
    margin: 0 auto;
    padding: 5px;
    text-align: center;
  }

  @media screen and (min-width: 767px) and (max-width: 900px) {
    .name_text {
      font-size: 1em;
    }

    .sub_text {
      font-size: 0.5em;
    }

    .story_title {
      font-size: 0.9em;
    }

    .follow_btn {
      font-size: 0.5em;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 4%;
    width: 100%;

    .story_title {
      font-size: 0.8em;
    }

    .name_text {
      font-size: 0.8em;
    }

    .sub_text {
      font-size: 0.5em;
    }
  }

  @media screen and (max-width: 550px) {
    padding: 4%;

    .user_name {
      flex: 2;
    }

    .name_text {
      font-size: 0.9em;
    }

    .sub_text {
      font-size: 0.5em;
    }

    .story_title {
      font-size: 1em;
    }

    .user_follow_btn {
      flex: 1;
    }
    
    .story_content {
      font-size: 0.8em;
    }

    .follow_btn {
      font-size: 0.5em;
    }
  }
`