import styled from 'styled-components'

export const DashboardHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #f2f3f4;
  font-family: Cormorant;
  color: #404a59;
  padding: 4em 10.5% 0;
  text-align: center;

  .header {
    font-size: 2em;
    font-weight: bold;
  }

  .sub_header {
    font-size: 1.2em;
    font-family: 'Muli';
    margin-top: 1em;
  }

  .qstn_of_the_day {
    background: #d7ddd8;
    margin: 2%;
    padding: 2em;
    width: 82%;
    box-sizing: border-box;
    cursor: pointer;
  }

  @media screen and (max-width: 767px) {
    padding: 4%;

    .header {
      font-size: 1.2em;
    }

    .sub_header {
      font-size: 0.8em;
    }

    .qstn_of_the_day {
      width: 98%;
      margin: 4% 0 0;
    }
  }
`