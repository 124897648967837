import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { YogaNidraContainer } from './styles';
import { MUSIC_WHITE_ICON } from '../../../images';
import DefaultContentPage from '../../common/no_content_page';

function stopAnyOtherCurrentlyPlayingAudio(event) {
  for (const audio of document.querySelectorAll('audio')) {
    if (audio !== event.currentTarget) {
      audio.pause();
      audio.currentTime = 0
    }
  }
}

function GetSingleAudioSection(props) {
  const audioInfo = props.content
  return (
    <>
      <div className="media_container">
        <div className="media_detail">
          <img src={MUSIC_WHITE_ICON} className="media_music_icon" alt="Music" />
          <div className="media_name">{audioInfo.title}</div>
        </div>
        <ReactAudioPlayer
          src={audioInfo.media}
          className="audio_player"
          autoPlay={false}
          controls
          controlsList="nofullscreen nodownload noremoteplayback"
          preload="auto"
          onPlay={event => stopAnyOtherCurrentlyPlayingAudio(event)}
          onEnded={()=>props.updateYogaNidraCount({id: audioInfo["_id"]})}
        />
      </div>
    </>
  )
}

function YogaNidraPage(props) {
  return (
    <>
      <YogaNidraContainer>
        <div className="title">Yoga Nidra</div>
        {
          (props.yoga_nidras && props.yoga_nidras.length) ?
            props.yoga_nidras.map((audioInfo, index) => {
              return <GetSingleAudioSection content = {audioInfo} key = {index} updateYogaNidraCount = {props.updateYogaNidraCount} />
            })
          :
          <DefaultContentPage>No audio(s) found</DefaultContentPage>
        }
      </YogaNidraContainer>
    </>
  );
}

export default YogaNidraPage;
