import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { DashboardContainer, CardView } from './styles';
import { ESHA_AVATAR, PROFILE_DEFAULT_AVATAR } from '../../../../images';
import GuidedReflection from '../../../common/modal/dashboard_guided_reflection_modal';
import { getCompletePathOfAssest } from '../../../../utils/helper';

function DashboardCards(props) {
  const [openGuidedReflectionModal, setGuidedReflectionModal] = useState(false);
  const history = useHistory();
  const user = props.userInfo;
  let userDetails = user["basic"];
  let name = userDetails["name"];
  let profileAvatar =  user["profileImageURL"] ? getCompletePathOfAssest(user["profileImageURL"]) : PROFILE_DEFAULT_AVATAR;
  
  const closeGuidedReflectionModal = () => {
    setGuidedReflectionModal(false);
  }

  return (
    <>
      {
        openGuidedReflectionModal ? 
          <GuidedReflection closeModal={closeGuidedReflectionModal} guidedReflection={props.guidedReflection}/> : null
      }
      <DashboardContainer>
        <CardView bgCol={"#af986f"} onClick={() => history.push('/journal')}>
          <div className="card_container">
            <div className="card_img">
              <div>
                <img src={profileAvatar} alt="User Avatar" />
              </div>
            </div>
            <div className="card_info">
              <div className="card_header">My Practice</div>
              <div className="card_body">{name}'s <br/> JOURNEY</div>
            </div>
          </div>
        </CardView>
        <CardView onClick={() => setGuidedReflectionModal(true)}>
          <div className="card_container">
            <div className="esha_img">
              <img src={ESHA_AVATAR} alt="Esha Avatar" />
            </div>
            <div className="card_info">
              <div className="card_header">
                Guided Reflections <br />
                <span className="card_sub_header">WITH ESHA</span>
              </div>
              <div className="card_body">HOW ARE YOU FEELING TODAY?</div>
            </div>
          </div>
        </CardView>
      </DashboardContainer>
    </>
  )
}

export default DashboardCards;
