import React, { useState } from 'react';
import { QuestionModalContainer, ModalContainer } from './styles';
import Checkbox from '../../checkbox'
import { UpdateUserInformation } from '../../../../redux/actions/user_actions'
import { connect } from 'react-redux'
import { successToast, errorToast } from '../../../../utils/toast'
import Loader from '../../loader'

function QuestionsModal(props) {
  const [whyHere, updateWhyHereList] = useState([]);
  const [loading, setLoading] = useState(false)

  const handleChange = (event) => {
    if (event && event.target) {
      let elementName = event.target.name;
      if (event.target.checked && !whyHere.includes(elementName)) {
        updateWhyHereList([
          ...whyHere,
          elementName
        ])
      } else if (whyHere.includes(elementName)) {
        let tmp = [...whyHere];
        tmp.splice(whyHere.indexOf(elementName), 1);
        updateWhyHereList(tmp);
      }
    }
  }

  const isValid = () => {
    let isValidInfo = true;
    if (whyHere && whyHere.length === 0) {
      errorToast("Please select atleast one reason", { toastId: "whyHere" });
      isValidInfo = false;
    }

    if (isValidInfo) {
      setLoading(true)
      props.UpdateUserInformation({ whyAreYouHere: [...whyHere] }).then(resp => {
        setLoading(false)
        if (resp && resp.status === 200) {
          successToast("Information updated succesfully", { toastId: "profile_updated_success" });
          props.closePopup();
        } else {
          const msg = resp.response.data.error.message
          errorToast(msg, { toastId: "profile_updated_error" });
        }
      })
    }
  }

  const whyYouHereOptions = [
    "I’m dying and worrying about death.",
    "A person close to me is dying and I’m scared for them and what will come next.",
    "I lost a family member or friend.",
    "I’m dealing with a separation or breakup.",
    "I have lost financial stability am scared for the future.",
    "I’d rather not say at this time.",
    "I’m veteran suffering from PTSD."
  ]

  return (
    <>
      {loading ? <Loader /> : null}
      <QuestionModalContainer>
        <ModalContainer>
          <div className="title">
            WHY ARE YOU HERE?
        </div>
          {whyYouHereOptions.map((option, index) => {
            return (
              <label key={index}>
                <Checkbox checked={whyHere.includes(option)} id={"checkbox_" + index} onChange={handleChange} name={option} />
                <span className={"checkbox_label"}>{option}</span>
              </label>
            )
          })}
          <div className="submit_container">
            <input type="submit" className="submit_btn" id="submit" value="SUBMIT" onClick={isValid} />
          </div>
        </ModalContainer>
      </QuestionModalContainer>
    </>
  )
}

const mapDispatchToProps = {
  UpdateUserInformation
}

export default connect(null, mapDispatchToProps)(QuestionsModal)