import { GET_YOGA_NIDRAS } from "../types";

let initialState = {
  yoga_nidras: {},
};

export default function yoga_nidra_reducer (state = initialState, action) {
  switch (action.type) {
    case GET_YOGA_NIDRAS:
      return {
        ...state,
        yoga_nidras: action.payload.data.data
      };
    default:
      return state;
  }
}