import styled from 'styled-components'

export const TestimonalsContainer = styled.div`
	background: #404a59;
	font-family: Cormorant;
	color: #f2f3f4;
	padding: 3em 5em 5em;
	text-align: center;

	.testimonals_heading {
		text-align: center;
		font-size: 2em;
		margin-bottom: 2em;
    font-weight: bold;
	}

	@media screen and (max-width: 767px) {
		background: #404a59;
		font-family: Cormorant;
		color: #f2f3f4;
		padding: 2.5em 4%;
		text-align: center;
	
		.testimonals_heading {
			text-align: center;
			font-size: 2.6em;
			margin-bottom: 0.5em;
			font-weight: bold;
		}
	}

`

export const TestimonalsContent = styled.div`
	display: inline-block;
	width: 33.3%;	
	vertical-align: top;
	.content {
		width: 70%;
		font-size: 0.9em;
		line-height: 19px;
		margin: 0 auto;
		font-family: Muli;
	}

	.author {
		margin-top: 2.5em;
		font-style: italic;
		font-size: 1.5em;
    // font-weight: bold;
	}

	@media screen and (max-width: 767px) {
		padding: 1.5em 0 1em 0;
		display: inline-block;
		width: 100%;	
		vertical-align: top;
		.content {
			width: 100%;
			line-height: 19px;
			margin: 0 auto;
			font-family: Muli;
		}
	
		.author {
			margin-top: 0.5em;
		}
	}

`