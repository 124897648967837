import React, { Component } from 'react';
import { connect } from 'react-redux';

import Header from '../../components/common/header';
import EditProfileHome from '../../components/pages/edit_profile/edit_profile_home_container'
import Footer from '../../components/common/footer';
import Loader from '../../components/common/loader'
import { isObjectEmpty, redirectWithOptionalMessage } from '../../utils/helper'

class EditProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isUserInfoAvailable: false,
    }
  }

  componentDidMount() {
    if (isObjectEmpty(this.props.userInfo)) {
      redirectWithOptionalMessage("/dashboard", this.props.history)
      return
    } else {
      this.setState({ isUserInfoAvailable: true })
    }
  }

  render() {
    const { userInfo } = this.props
    const { isUserInfoAvailable } = this.state
    return (
      <>
        {!isUserInfoAvailable ? <Loader /> :
          <>
            <Header />
            <EditProfileHome userInfo={userInfo} />
            <Footer />
          </>
        }
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.user_content.userInfo,
  }
}

export default connect(mapStateToProps, null)(EditProfile)