import styled from 'styled-components'

export const MariposaOfferingsContainer = styled.div`
  background: #d7ddd8;
  font-family: Cormorant;
  color: #404a59;
  padding: 3em 15%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  .title {
    font-size: 1.5em;
    font-weight: bold;
  }

  .find_peace_button {
		color: #f2f3f4;
		max-width: 8em;
		min-width: 8em;
		background: #af986f;
		text-align: center;
		padding: 0.3em;
    font-size: 1.2em;
		font-style: italic;
		width: 45%;
		cursor: pointer;
    margin-top: 1em;
	}

	@media screen and (max-width: 767px) {
    padding: 4%;

    .title {
      margin-top: 0.5em;
      font-size: 1.7em;
    }

    .find_peace_button {
      font-size: 1em;
      margin-bottom: 2em;
    }
  }
`

export const OfferingList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 3em 0;
  width: 100%;

  .offer_name, .offer_description {
    flex: 1;
  }

  .offer_title {
    font-size: 1.5em;
    font-weight: bold;
  }

  .offer_sub_title {
    color: #af986f;
    font-style: italic;
    font-size: 1.2em;
  }

  .offer_description {
    font-size: 0.8em;
    font-family: Muli;
    margin-left: 8em;
  }

	@media screen and (max-width: 767px) {
    flex-direction: column;
    margin: 1em 0;
    justify-content: center;
    align-items: center;

    .offer_name, .offer_description {
      flex: unset;
      text-align: center;
    }

    .offer_title {
      font-size: 1.3em;
    }
  
    .offer_sub_title {
      font-size: 1.1em;
    }
  
    .offer_description {
      font-size: 0.8em;
      margin-left: unset;
    }
  }
`