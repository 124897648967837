import styled from 'styled-components'

export const DashboardNoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #d7ddd8;
  font-family: Cormorant;
  color: #404a59;
  padding: 4em 10.5% 5%;
  text-align: center;

  .footer_header {
    font-size: 2em;
    font-weight: bold;
  }

  .footer_sub_text {
    font-family: Muli;
    font-size: 1.2em;
    margin: 2em 0;
    width: 70%;
  }

  .footer-logo {
    &>img {
      width: 20%;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 4%;

    .footer_header {
      font-size: 1.2em;
    }

    .footer_sub_text {
      font-size: 0.8em;
      width: 90%;
    }
  }
`