import axios from "axios";
import { GET_YOGA_NIDRAS } from "../types";
import { API_GET_YOGA_NIDRAS, API_UPDATE_YOGA_NIDRA_COUNT } from '../../config/api/endpoint'
import { getHeadersForAPICall } from '../../utils/helper'

export const getYogaNidras = () => {
  let headers = getHeadersForAPICall()
  return dispatch => {
    return axios
      .get(API_GET_YOGA_NIDRAS, { headers })
      .then(response => {
        dispatch({
          type: GET_YOGA_NIDRAS,
          payload: response
        });
        return response
      })
      .catch(err => {
        return err
      });
  };
};


export const updateYogaNidraCount = (data) => {
  let headers = getHeadersForAPICall()
  return dispatch => {
    return axios
      .put(API_UPDATE_YOGA_NIDRA_COUNT, data, { headers })
      .then(response => {
        return response
      })
      .catch(err => {
        return err
      });
  };
};
