import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px 15% 5%;
  background: #f2f3f4;
  color: #e5e5e3;

  .card_container {
    background: #404a59;
    padding: 3em 3em 1em;
    border-radius: 2em;
  }

  .card_body {
    font-size: 1em;
    font-family: 'Muli';
  }

  .card_sign {
    font-family: La-Luxes;
    text-align: right;
    font-size: 3em;
  }

  .read_more {
    text-align: center;
    background: #af986f;
    color: white;
    padding: 10px;
    width: 20%;
    cursor: pointer;
    margin: 20px auto;
    font-family: Cormorant;
    font-size: 1.1em;
    font-style: italic;
  }

  @media screen and (min-width: 1200px) {
    .read_more {
      width: 15%;
    }
  }

  @media screen and (max-width: 768px) {
    padding: unset;

    .card_container {
      border-radius: unset;
      padding: 10% 4% 3%;
    }

    .card_sign {
      text-align: center;
      font-size: 2em;
    }

    .card_body {
      font-size: 1em;
      text-align: center;
    }

    .read_more {
      width: 25%;
    }
  }

  @media screen and (max-width: 500px) {
    .read_more {
      width: 35%;
    }
  }
`