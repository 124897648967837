import styled from 'styled-components'

export const MainContainer = styled.div`
  padding: 4% 15% 5%;
  background: #f2f3f4;
  color: #404a59;
  text-align: center;
  width: 100%;
  font-family: Muli;

  .card_container {
    font-size: 1.5em;
  }

  @media screen and (max-width: 768px) {
    padding: 10% 4%;

    .card_container {
      font-size: 1em;
    }
  }
`