import React from 'react';
import VideoPlayer from 'react-video-js-player';
import { YogaVinyasaModalContainer, ModalContainer } from './styles';
import { CROSS_WHITE_ICON } from '../../../../images'

function VideoModal(props) {
  return (
    <YogaVinyasaModalContainer>
      <ModalContainer>
        <div className="cross_video_player" onClick={props.closeModal}>
          <img src={CROSS_WHITE_ICON} alt="Close Modal"/>
        </div>
        <VideoPlayer
          controls={true}
          className="video_player"
          src={props.videoSrc}
          width="100%"
          onEnd={()=>props.onEnd({id:props.id})}/>
      </ModalContainer>
    </YogaVinyasaModalContainer>
  )
}

export default VideoModal;