import React from 'react';
import { JournalContainer } from './styles';
import { PROFILE_DEFAULT_AVATAR } from '../../../../images';
import { getCompletePathOfAssest } from '../../../../utils/helper';

function MyJournalProfile(props) {
  const user = props.userInfo;
  let userBasicDetails = user["basic"];
  let profileAvatar = user["profileImageURL"] ? getCompletePathOfAssest(user["profileImageURL"]) : PROFILE_DEFAULT_AVATAR;

  return (
    <>
      <JournalContainer>
        <div className="user_profile_container">
          <div className="user_profile">
            <img src={profileAvatar} alt="Avatar" />
          </div>
          <div className="user_info">
            <div className="title">My Journey</div>
            <div className="username">{userBasicDetails.name}</div>
          </div>
          <div className="user_msg">{user["profileMessage"] ? user["profileMessage"] : ""}</div>
        </div>
      </JournalContainer>
    </>
  );
}

export default MyJournalProfile;