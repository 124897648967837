import styled from 'styled-components'

export const ProgramResourceContainer = styled.div`
	text-align: center;
	background: #af986f;
  padding: 2em 13%;

	.program_heading{
		font-size: 2em;
		font-weight: bold;
		font-family: Cormorant;
		color: #404a59;
	}

	@media screen and (max-width: 767px) {
		padding: 10% 4%;
	
		.program_heading{
			font-size: 1.8em;
		}
	}
`
export const ProgramFeedsContainer = styled.div`
	display: inline-block;
	width: 25.3%;
	margin: 3em 4% 1em;
	text-align: center;
	vertical-align: top;
  color: #404a59;

	& > img {
		width: 85%;
	}

	.tile_heading{
		font-size: 1.5em;
		font-weight: bold;
		margin-top: 6%;
		font-family: Cormorant;
	}

	.tile_description{
		font-size: 1.3em;
		font-style: italic;
		font-family: Cormorant;
		line-height: 1.3em;
	}

	.program_resource_link {
		cursor: pointer;
	}

	@media screen and (max-width: 767px) {
		width: auto;
		vertical-align: top;
		margin-top: 3em;

		& > img {
			width: 100%;
		}

		.tile_heading{
			font-size: 1.9em;
			margin-top: 8%;
			margin-top: 0.5em;
		}

		.tile_description{
			font-size: 1.2em;
			font-family: 'Muli';
			line-height: 1.5em;
		}
	}
`