import styled from 'styled-components'

export const EditProfileHomeContainer = styled.div`
	background: #f2f3f4;
	font-family: Cormorant;
	font-size: 1em;
	color: #404a59;
	padding: 2em 15%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
	justify-content: center;
  width: 100%;
  box-sizing: border-box;

	.header {
		border-bottom: 1px solid #404a59;
		padding: 10px 0;
	}

	.title {
		font-size: 1.5em;
	}

	.sub_title {
		font-size: 0.8em;
		letter-spacing: 0.5px;
		font-family: Muli;
	}

  .edit_container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  .settings_options {
    width: 20%;
  }

  .option {
    font-size: 0.9em;
    text-align: left;
    padding: 1em;
    cursor: pointer;
    border-bottom: 1px solid #404a59;
  }

  .active {
    color: #af986f;
    font-weight: bold;
  }

  .selected_setting_container {
    width: 75%;
    min-height: 30em;
  }

	@media screen and (max-width: 767px) {
		padding: 4%;

		.header {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: center;
			width: 100%;
		}

		.title {
			flex-basis: 20%;
		}

		.sub_title {
			flex-basis: 80%;
			text-align: right;
		}

    .edit_container {
      flex-direction: column;
    }

    .settings_options {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
    }

    .option {
      border: none;
      flex: 1;
      font-size: 0.8em;
      text-align: center;
    }

    .active {
      text-decoration: underline;
      text-underline-position: under;
    }

    .selected_setting_container {
      width: 100%;
      min-height: 28em;
    }
	}
`