import styled from 'styled-components'

export const QuestionModalContainer = styled.div`
  background: #00000096;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`

export const ModalContainer = styled.div`
  background: #fff;
  font-family: Muli;
  color: #404a59;
  padding: 2% 4%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  width: 50%;

  .title {
    font-size: 1.2em;
    margin: 2% 0;
  }

  label {
    margin: 1% 0 0;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .checkbox_label {
    margin-left: 10px;
    font-size: 0.8em;
  }

  .submit_container {
    text-align: center;
    width: 100%;
  }

  .submit_btn {
    background: #af986f;
    color: #f2f3f4;
    width: 35%;
    outline: none;
    cursor: pointer;
    border: none;
    margin: 4% 0 2%;
    font-size: 0.8em;
    padding: 5px 0;
    min-width: 10em;
    max-width: 12em;
  }

  @media screen and (max-width: 767px) {
    padding: 4%;
    width: 90%;
  }

  @media screen and (max-width: 510px) {
    .title, .submit_btn {
      font-size: 0.8em;
    }

    .checkbox_label {
      font-size: 0.7em;
    }
  }
`