import React, { Component } from 'react';

import Header from '../../components/common/header'
import MariposaSummary from '../../components/pages/mariposa_sanctuary/mariposa_sanctuary_summary'
import MariposaOfferings from '../../components/pages/mariposa_sanctuary/mariposa_sanctuary_offerings'
import Footer from '../../components/common/footer'
import { connect } from 'react-redux'

class MariposaSanctuary extends Component {
  render() {
    return (
      <>
        <Header />
        <MariposaSummary content={this.props.staticWebContent}/>
        <MariposaOfferings content={this.props.staticWebContent}/>
        <Footer />
      </>
    )
  }
}


const mapStateToProps = state => {
  return {
    staticWebContent: state.static_content.staticWebContent,
  }
}

export default connect(mapStateToProps)(MariposaSanctuary)