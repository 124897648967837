import React, { Component } from 'react';
import { connect } from 'react-redux'
import Header from '../../components/common/header'
import Footer from '../../components/common/footer'
import DownloadForm from '../../components/pages/download/download_form'
//import ContactEshaJourney from '../../components/pages/contact/contact_esha_journey'

class Download extends Component {
  render() {
    return (
      <div>
        <Header />
        <DownloadForm content={this.props.staticWebContent} />
        {/* <ContactEshaJourney content={this.props.staticWebContent} /> */}
        <Footer />
      </div>
    )
  }
}



const mapStateToProps = state => {
  return {
    staticWebContent: state.static_content.staticWebContent,
  }
}

export default connect(mapStateToProps)(Download)