import styled from 'styled-components'

export const MariposaSanctuaryContainer = styled.div`
  padding: 3em 0 0;
  background: #f2f3f4;

  &>img {
    width: 100%;
  }
`

export const MariposaSummaryContainer = styled.div`
  background: #f2f3f4;
  font-family: Cormorant;
  color: #404a59;
  padding: 3em 15%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;

  .title {
    font-size: 1.5em;
    font-weight: bold;
  }

  .sub_title {
    color: #af986f;
    font-style: italic;
    font-size: 1.2em;
  }

  .summary {
    font-family: Muli;
    font-size: 0.8em;
    margin: 2em 0;
  }

	@media screen and (max-width: 767px) {
    padding: 4%;

    .title {
      margin-top: 5px;
      font-size: 1.6em;
      text-align: center;
    }
  
    .sub_title {
      font-size: 1.1em;
      text-align: center;
    }
  
    .summary {
      font-size: 0.8em;
      text-align: center;
      margin: 1em 0;
    }
  }
`