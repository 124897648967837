import styled, {keyframes} from 'styled-components'

export const TodaysJournalModalContainer = styled.div`
  background: #00000096;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`

const fadeIn = keyframes`
  0%
  {
    opacity: 0;
  }
  50%
  {
    opacity: 1;
  }
  100%
  {
    opacity: 0;
  }
`;

export const ModalContainer = styled.div`
  background: #fff;
  font-family: Muli;
  color: #404a59;
  padding: 2%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 55%;
  position: relative;
  max-width: 60em;

  .title {
    font-size: 28px;
    font-family: Cormorant;
    text-align: center;
  }

  .close_popup {
    position: absolute;
    top: 3%;
    right: 3%;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .no_journal_body{
    font-size: 1em;
    text-align:center;
    font-weight: bold;
    padding: 6.35em 0;
    border: 1px dashed black;
    width: 90%;
    margin-top: 1em;
  }

  .date_container {
    width: 90%;
  }

  .left_arrow {
    font-size: 1.5em;
    text-align: left;
    display: inline-block;
    width: 5%;
    cursor: pointer;
  }

  .date {
    font-size: 18px;
    font-weight: bold;
    margin: 2% 0 1%;
    display: inline-block;
    width: 90%;
    text-align: center;
    display: inline-block;
  }

  .right_arrow {
    font-size: 1.5em;
    text-align: right;
    display: inline-block;
    width: 5%;
    cursor: pointer;
  }

  .modal_body {
    font-size: 15px;
    margin: 1% 0;
    width: 80%;
    text-align: center;
  }

  textarea {
    padding: 0.5em;
    margin: 2% 0;
    min-height: 10em;
    max-height: 10em;
    font-size: 0.8em;
    width: 90%;
    background: #f2f3f4;
    border: 1px solid #e5e5e3;
    border-radius: 5px;
    outline: none;
  }

  input {
    padding: 0.5em;
    margin: 2% 0;
    font-size: 0.8em;
    width: 90%;
    background: #f2f3f4;
    border: 1px solid #e5e5e3;
    border-radius: 5px;
    outline: none;
  }

  .saved_notification_txt {
    visibility: hidden;
    font-family: 'Muli';
    font-size: 13px;
    color: #066706;
    font-weight: bold;
  }

  .show_saved_notification {
    animation: ${fadeIn} 3s linear forwards;
    visibility: unset !important;
  }

  .hide_saved_notification {
    visibility: hidden !important;
  }

  .modal_footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 2% 0;
  }

  .footer_date_selector {
    flex-basis: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: baseline;
    justify-content: flex-start;
    text-transform: uppercase;

    &>label>img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      cursor: pointer;
    }
  }

  .footer_share_selector {
    flex-basis: 30%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    text-transform: uppercase;

    &>img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }

    &>div {
      cursor: pointer;
    }
  }

  .footer_date_input {
    outline: none;
    border: none;
    background: none;
    max-width: 9em;
  }

  .footer_all_journal {
    cursor: pointer;
  }

  .footer_share_selector, .footer_all_journal {
    font-size: 0.8em;
  }

  @media screen and (min-width: 768px) and (max-width: 980px) {
    .footer_date_input, .footer_share_selector, .footer_all_journal {
      font-size: 0.6em;
    }

    .footer_date_selector, .footer_share_selector {
      &>label>img {
        margin-right: 5px;
      }
    }

  }

  @media screen and (max-width: 767px) {
    padding: 4%;
    width: 90%;

    .title {
      font-size: 20px;
    }

    .date {
      font-size: 14px;
    }

    .close_popup {
      width: 20px;
      height: 20px;
    }

    .modal_body {
      font-size: 14px;
      margin: 2% 0 4%;
      text-align: center;
    }

    .modal_footer {
      flex-direction: column;
      width: 100%;
    }

    .footer_share_selector {
      margin: 2% 0 0;
      width: 65%;
      padding: 10px;
      justify-content: center;
      background: #e5e5e3;
      color: #404a59;

      &>div {
        color: #404a59;
        font-size: 0.9em;
      }
    }

    .footer_date_selector {
      background: #f2f3f4;
      margin: 2% 0 0;
      width: 65%;
      padding: 0 10px;
      justify-content: center;
    }
  }

  @media screen and (max-width: 519px) {
    .footer_share_selector, .footer_date_selector {
      width: 90%;
    }

    .footer_date_selector {
      padding: 10px;
    }

    .footer_all_journal {
      font-size: 0.7em;
    }
  }
`