import axios from "axios";
import { GET_STATIC_WEB_CONTENT } from "../types";
import { API_GET_STATIC_CONTENT } from '../../config/api/endpoint'
import { getHeadersForAPICall } from '../../utils/helper'

export const getStaticWebContent = () => {
  let headers = getHeadersForAPICall()
  return dispatch => {
    return axios
      .get(API_GET_STATIC_CONTENT, { headers })
      .then(response => {
        dispatch({
          type: GET_STATIC_WEB_CONTENT,
          payload: response
        });
        return response
      })
      .catch(err => {
        return err
      });
  };
};

