import React from 'react';
import { TilesViewContainer } from './styles'

function TileView(props) {
  return (
    <>
      <TilesViewContainer>
        <img src={props.image} alt={props.heading + " image"} />
        <div className="dynamic_content tile_heading" style={props.style_tile_heading ? props.style_tile_heading : {}}>{props.heading}</div>
        <div className="dynamic_content tile_description" style={props.style_tile_description ? props.style_tile_description : {}}>{props.description}</div>
        <div className="dynamic_content imp_note" style={props.style_imp_note ? props.style_imp_note : {}}>{props.impNote ? props.impNote : ""}</div>
      </TilesViewContainer>
    </>
  );
}

export default TileView;
