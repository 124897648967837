import React, { Component } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import { NavBarContainer, NavBarSection, NavBarElement, PromoMessageBarContainer } from './styles';
import { LOGO } from '../../../images';
import { withRouter } from 'react-router';
import { slide as Menu } from 'react-burger-menu';
import { getLocalStorageItem, deleteLocalStorageItem } from '../../../utils/helper';
import {logOutUser} from '../../../redux/actions/user_actions'

function LoginLogoutBtn(props) {
  const { loginWithRedirect, logout } = useAuth0();
  if (getLocalStorageItem("jwt_token")) {
    return (
      <>
        <button className="login_logout_btn" onClick={() => {
          props.logOutUser().then(resp=>{
            logout({
              returnTo: window.location.origin + "/home"
            })
            deleteLocalStorageItem("jwt_token");
            deleteLocalStorageItem("auth0_access_token");
            toast.success("You are successfully logged out", { toastId: "login_out" })
          })
        }}> Logout</button>
      </>
    )
  } else {
    return (
      <>
        <button className="login_logout_btn" onClick={() => loginWithRedirect()}>Login</button>
      </>
    )
  }
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { screenWidth: window.innerWidth };
  }

  updateWindowDimensions = () => {
    this.setState({ screenWidth: window.innerWidth });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  getActiveFontWeight = (path) => {
    const currentPath = window.location.pathname
    if(path === currentPath) {
      return "bold"
    }
  }

  render() {
    if (this.state.screenWidth < 768) {
      return (
        <>
          <NavBarContainer>
            <div id={"burger-menu"}>
              <Menu width={'60%'}>
                <div></div>
                <div className="logo_image">
                  <img src={LOGO} alt="Esha Estar logo" />
                </div>
                <NavBarSection width="100%">
                  <LoginLogoutBtn logOutUser={this.props.logOutUser} history={this.props.history} />
                </NavBarSection>
                <NavBarElement weight={this.getActiveFontWeight("/home")} onClick={() => this.props.history.push("/home")}>Home</NavBarElement>
                <NavBarElement weight={this.getActiveFontWeight("/mariposa_sanctuary")} onClick={() => this.props.history.push("/mariposa_sanctuary")}>Mariposa Sanctuary</NavBarElement>
                {getLocalStorageItem("jwt_token") ? <NavBarElement weight={this.getActiveFontWeight("/dashboard")} onClick={() => this.props.history.push("/dashboard")}>Dashboard</NavBarElement> : null}
                <NavBarElement onClick={() => window.location.replace("https://shop.eshaestar.com")}>Shop</NavBarElement>
                <NavBarElement weight={this.getActiveFontWeight("/contact")} onClick={() => this.props.history.push("/contact")}>Contact</NavBarElement>
                <NavBarElement weight={this.getActiveFontWeight("/about")} onClick={() => this.props.history.push("/about")}>About Esha</NavBarElement>
                <NavBarSection width="100%">
                  {
                    getLocalStorageItem("jwt_token") ?
                      <div className="find_peace_button" onClick={() => this.props.history.push("/setting")}>Setting</div> :
                      <div className="find_peace_button" onClick={() => this.props.history.push("/contact")}>Connect Here</div>
                  }
                </NavBarSection>
              </Menu>
            </div>
            <div className="image_container" onClick={() => this.props.history.push("/home")}>
              <img src={LOGO} alt="Esha Estar logo" />
            </div>
          </NavBarContainer>
        </>
      );
    } else {
      return (
        <>
          <PromoMessageBarContainer>
            <div>Send the Gift of Healing<sup>TM</sup> - Grief Packages for You or a Loved One. <a href="https://shop.eshaestar.com" onClick={() => window.location.replace("https://shop.eshaestar.com")}>Click Here</a></div>
          </PromoMessageBarContainer>
          <NavBarContainer>
            <div className="navbar_container">
              <div className="navbar_section_url">
                <div className="nav_items">
                  {getLocalStorageItem("jwt_token") ? <NavBarElement onClick={() => this.props.history.push("/dashboard")}>Dashboard</NavBarElement> : null}
                  <NavBarElement onClick={() => this.props.history.push("/mariposa_sanctuary")}>Mariposa Sanctuary</NavBarElement>
                  <NavBarElement onClick={() => window.location.replace("https://shop.eshaestar.com")}>Shop</NavBarElement>
                  <NavBarElement onClick={() => this.props.history.push("/contact")}>Contact</NavBarElement>
                  <NavBarElement onClick={() => this.props.history.push("/about")}>About</NavBarElement>
                </div>
              </div>
              <div className="navbar_section_logo" onClick={() => this.props.history.push("/home")}>
                <img src={LOGO} alt="Esha Estar logo" />
              </div >
              <div className="navbar_section_btn">
                {
                  getLocalStorageItem("jwt_token") ?
                    <div className="find_peace_button" onClick={() => this.props.history.push("/setting")}>Settings</div> :
                    <div className="find_peace_button" onClick={() => this.props.history.push("/contact")}>Connect Here</div>
                }
                <LoginLogoutBtn logOutUser= {this.props.logOutUser} history={this.props.history} />
                <div className="home_btn">
                  <i className="fas fa-home" onClick={() => this.props.history.push("/home")} />
                </div>
              </div>
            </div>
          </NavBarContainer>
        </>
      );
    }
  }
}

const mapDispatchToProps = {
  logOutUser,
}

export default withRouter(connect(null, mapDispatchToProps)(Header))

