import styled from 'styled-components'

export const MoreToComeContainer = styled.div`
	text-align: center;
	background: #d7ddd8;
	font-family: Cormorant;

	.more_to_come_heading{
		font-size: 3.1em;
		padding-top: 1em;
		color: #404a59;
	}

	.engage_heading{
		color: #af986f;
		font-size: 2em;
		font-style: italic;
		margin-top: 0.3em;
		padding: 0 4%;
	}

	.more_to_come_tile_section{
		padding: 2em 10.5%;
	}

	.more_to_come_bottom_text{
		color: #af986f;
		font-size: 1.2em;
		width: 68%;
		margin: 0 auto;
		font-family: Muli;
		margin-top: 1.5em;
	}

	@media screen and (max-width: 767px) {
		text-align: center;
		background: #d7ddd8;
		font-family: Cormorant;
		padding: 4%;
	
		.more_to_come_heading{
			font-size: 3.1em;
			padding-top: 0.3em;
			color: #404a59;
		}
	
		.engage_heading{
			color: #af986f;
			font-size: 2em;
			font-style: italic;
			margin-top: 0.3em;
		}
	
		.more_to_come_tile_section{
			margin-top: 0;
			padding: 0;
		}
	
		.more_to_come_bottom_text{
			color: #af986f;
			font-size: 1.2em;
			width: 100%;
			margin: 0 auto;
			font-family: Muli;
			margin-top: 1.5em;
		}
	}
`

export const FooterLogo = styled.div`
	text-align: center;
	padding: 3em;
	
	& > img {
		width: 14%;
	}

	@media screen and (max-width: 767px) {
		padding: 0;
		margin: 2.5em 0;
		& > img {
			width: 45%;
		}
	}
`
