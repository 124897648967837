import React from 'react';
import { DashboardNoteContainer } from './styles'

function DashboardNote() {
  return (
    <>
      <DashboardNoteContainer>
        <div className="footer_header">A Note from Esha</div>
        <div className="footer_sub_text">
          We are being called to state of greater awareness, mindfulness, and increased consciousness in how we tend to the journey
          of our heart and soul. Our heart-based transformational programs are aimed at supporting you through life’s journey in 
          such a mindful and compassionate way with presence
        </div>
      </DashboardNoteContainer>
    </>
  );
}

export default DashboardNote;
