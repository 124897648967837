import React from 'react';
import { HOME_CAROUSEL_1, HOME_CAROUSEL_2, HOME_CAROUSEL_3, HOME_CAROUSEL_4 } from '../../../../images'
import { HomeCarouselContainer, HomeCarouselImgContainer } from './styles'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

function HomeCarouselScreen() {
  return (
    <>
      <HomeCarouselContainer>
        <Carousel autoPlay={true} transitionTime={1500} interval={6000} infiniteLoop={true} showArrows={false} showThumbs={false} showStatus={false}>
        <HomeCarouselImgContainer right>
            <img src={HOME_CAROUSEL_1} alt="Carousel 1" />
            <div>
              <b>Welcome</b>
              <div className="sub_text">
                I welcome your story,<br/>
                I welcome your healing,<br/>
                I welcome your presence.
              </div>
            </div>
          </HomeCarouselImgContainer>
          <HomeCarouselImgContainer left>
            <img src={HOME_CAROUSEL_2} alt="Carousel 2" />
            <div>
              <b>5 Pathways to Freedom</b>
              <div className="sub_text">
                Packages &amp; Services for You or a Loved One
              </div>
            </div>
          </HomeCarouselImgContainer>                    
          <HomeCarouselImgContainer bottom>
            <img src={HOME_CAROUSEL_3} alt="Carousel 3" />
            <div>
              <b>You don’t have to walk alone</b><br/>
              <div className="sub_text">Your Invitation to <br/>Healing Starts Here</div>
            </div>
          </HomeCarouselImgContainer>
          <HomeCarouselImgContainer fullLeft>
            <img src={HOME_CAROUSEL_4} alt="Carousel 4" />
            <div>
              <b>Grief Mandala</b><sup>TM</sup>
              <div className="sub_text">
                A Grief Self Assessment Tool
              </div>
              <div className="quote_text">
                Go to any of the 5 Pathways packages in the Shop<br/>
                to get your free Grief Self Assessment Tool. 
              </div>
            </div>
          </HomeCarouselImgContainer>
        </Carousel>
      </HomeCarouselContainer>
    </>
  );
}

export default HomeCarouselScreen;
