import styled from 'styled-components'

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #f2f3f4;
  font-family: Cormorant;
  color: #404a59;
  padding: 0 10.5% 0;
  text-align: center;

  @media screen and (max-width: 767px) {
    padding: 0 4% 0;
    justify-content: space-between;
  }
`

export const CardView = styled.div`
  flex-basis: 40%;
  padding: 1em;
  background: ${props => props.bgCol ? props.bgCol : "#d7ddd8"};
  font-size: 1.2em;
  margin: 0 1%;
  flex-direction: row;
  box-sizing: border-box;
  min-height: 9em;
  cursor: pointer;

  .card_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .card_img {
    flex-basis: 40%;

    &>div{
      width: 7em;
      height: 7em;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      background: black;

      &>img {
        width: 100%;
      }
    }
  }

  .esha_img {
    flex-basis: 40%;

    &>img {
      width: 7em;
      height: 7em;
      border-radius: 50%;
    }
  }

  .card_info {
    flex-basis: 60%;
  }

  .card_header {
    font-size: 1.5em;
    font-weight: bold;
    padding-bottom: 1em;
    line-height: 0.7em;
  }

  .card_sub_header {
    font-family: Muli;
    font-size: 0.6em;
    font-weight: unset;
    letter-spacing: 1px;
  }

  .card_body {
    text-transform: uppercase;
    font-size: 0.9em;
  }

  @media screen and (min-width: 986px) and (max-width: 1330px){
    min-height: 11em;
    font-size: 1em;
    
    .card_header {
      font-size: 1.2em;
      padding: 1em 0;
      line-height: 1em;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 985px){
    min-height: 19.5em;

    .card_header {
      font-size: 1.2em;
      padding: 1em 0;
      line-height: 1em;
      min-height: 4em;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 4%;
    margin: 0 1%;
    min-height: 18em;
    flex-basis: 47%;
    font-size: 0.8em;

    .card_container {
      flex-direction: column;
      justify-content: space-between;
    }


    .card_header {
      font-size: 1.2em;
      padding: 1em 0;
      line-height: 1em;
      min-height: 4em;
    }
  }

`