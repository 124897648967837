import { GET_USER_INFO, GET_QUESTION_OF_THE_DAY, UPDATE_USER_INFO } from "../types";

let initialState = {
  userInfo: {},
  questionOfTheDay: null
};

export default function user_reducer (state = initialState, action) {
  switch (action.type) {   
    case GET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload.data.data.user
      };
    
      case UPDATE_USER_INFO:
        return {
          ...state,
          userInfo: action.payload.data.data.user
        };  
    
    case GET_QUESTION_OF_THE_DAY:
      return {
        ...state,
        questionOfTheDay: action.payload.data.data
      };

    default:
      return state;
  }
}