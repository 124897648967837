import React from 'react';
import { MoreToComeContainer, FooterLogo } from './styles'
import { WAY_TO_ENGAGE_1, WAY_TO_ENGAGE_2, WAY_TO_ENGAGE_3, WAY_TO_ENGAGE_4, WAY_TO_ENGAGE_5, WAY_TO_ENGAGE_6, LOGO_MARK } from '../../../../images'
import TileView from '../../../common/tile_view'
import ReactHtmlParser from 'react-html-parser';

function MoreToCome(props) {
	const content = props.content
	const WaysToEngageTilesList = [
		{ image: WAY_TO_ENGAGE_1, heading: ReactHtmlParser(content["more_to_come_tile_1_head"]), description: ReactHtmlParser(content["more_to_come_tile_1_desc"]), impNote: ReactHtmlParser(content["more_to_come_tile_1_note"])},
		{ image: WAY_TO_ENGAGE_2, heading: ReactHtmlParser(content["more_to_come_tile_2_head"]), description: ReactHtmlParser(content["more_to_come_tile_2_desc"]), impNote: ReactHtmlParser(content["more_to_come_tile_2_note"])},
		{ image: WAY_TO_ENGAGE_3, heading: ReactHtmlParser(content["more_to_come_tile_3_head"]), description: ReactHtmlParser(content["more_to_come_tile_3_desc"]), impNote: ReactHtmlParser(content["more_to_come_tile_3_note"])},
		{ image: WAY_TO_ENGAGE_4, heading: ReactHtmlParser(content["more_to_come_tile_4_head"]), description: ReactHtmlParser(content["more_to_come_tile_4_desc"]), impNote: ReactHtmlParser(content["more_to_come_tile_4_note"])},
		{ image: WAY_TO_ENGAGE_5, heading: ReactHtmlParser(content["more_to_come_tile_5_head"]), description: ReactHtmlParser(content["more_to_come_tile_5_desc"]), impNote: ReactHtmlParser(content["more_to_come_tile_5_note"])},
		{ image: WAY_TO_ENGAGE_6, heading: ReactHtmlParser(content["more_to_come_tile_6_head"]), description: ReactHtmlParser(content["more_to_come_tile_6_desc"]), impNote: ReactHtmlParser(content["more_to_come_tile_6_note"])},
	]

	return (
		<>
			<MoreToComeContainer>
				<div className="dynamic_content more_to_come_heading">{ReactHtmlParser(content["more_to_come_head"])}</div>
				<div className="dynamic_content engage_heading">{ReactHtmlParser(content["more_to_come_desc"])}</div>
				<div className="dynamic_content more_to_come_tile_section">
					{WaysToEngageTilesList.map((tile, index) =>
						<TileView key={index} image={tile.image} heading={tile.heading} description={tile.description} impNote={tile.impNote} />
					)}
				</div>
				<div className="dynamic_content more_to_come_bottom_text">{ReactHtmlParser(content["more_to_come_bottom_text"])}</div>
				<FooterLogo>
					<img src={LOGO_MARK} alt="Esha Estar logo" />
				</FooterLogo>
			</MoreToComeContainer>
		</>
	);
}

export default MoreToCome;
