import { GET_ALL_JOURNALS, GET_FOLLOWED_PEOPLE_JOURNALS, GET_MY_JOUNRALS } from "../types";

let initialState = {
  allJournals: {},
  followedPeopleJournals: {},
  myJournals: {}
};

export default function journal_reducer (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_JOURNALS:
      return {
        ...state,
        allJournals: action.payload.data.data
      };

    case GET_FOLLOWED_PEOPLE_JOURNALS:
      return {
        ...state,
        followedPeopleJournals: action.payload.data.data
      };

    case GET_MY_JOUNRALS:
      return {
        ...state,
        myJournals: action.payload.data.data.journals
      };
      
    default:
      return state;
  }
}