import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../components/common/header';
import Footer from '../../components/common/footer';
import MyJournalProfile from '../../components/pages/my_journal/my_journal_profile';
import MyJournalList from '../../components/pages/my_journal/my_journal_list';
import { getMyJournals, saveJournal, getSpecificDatedJournal } from '../../redux/actions/journal_actions'
import Loader from '../../components/common/loader';
import { isObjectEmpty, redirectWithOptionalMessage } from '../../utils/helper';

class MyJournal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMyJournalInfoAvailable: false
    }
  }

  componentDidMount() {
    if (isObjectEmpty(this.props.userInfo)) {
      redirectWithOptionalMessage("/dashboard", this.props.history)
      return
    }
    this.props.getMyJournals().then(journalResp => {
      if (journalResp && journalResp.status === 200) {
        this.setState({ isMyJournalInfoAvailable: true })
      } else {
        redirectWithOptionalMessage("/dashboard", this.props.history, journalResp.response.data.error.message)
      }
    })
  }

  render() {
    const { isMyJournalInfoAvailable } = this.state;
    const { userInfo, myJournals } = this.props
    return (
      <>
        {
          !isMyJournalInfoAvailable ? <Loader /> :
            <>
              <Header />
              <MyJournalProfile userInfo={userInfo} />
              <MyJournalList userInfo={userInfo} myJournals={myJournals} getSpecificDateJournal={this.props.getSpecificDatedJournal} saveEditedJournal={this.props.saveJournal} />
              <Footer />
            </>
        }
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.user_content.userInfo,
    myJournals: state.journal_content.myJournals
  };
}

const mapDispatchToProps = {
  getMyJournals,
  saveJournal,
  getSpecificDatedJournal,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyJournal);
