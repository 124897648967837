import styled from 'styled-components'

export const GuidedReflectionModalContainer = styled.div`
  background: #00000096;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`

export const ModalContainer = styled.div`
  background: #fff;
  font-family: Muli;
  color: #404a59;
  padding: 2%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: end;
  max-width: 75em;
  align-items: center;
  box-sizing: border-box;
  width: 55%;
  position: relative;
  min-height: 25em;

  .title {
    font-size: 28px;
    font-family: Cormorant;
    font-weight: bold;
    text-align: center;
  }

  .close_popup {
    position: absolute;
    top: 3%;
    right: 3%;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .sub_title {
    font-size: 0.9em;
    margin: 2% 0 1%;
    text-align: center;
  }

  .modal_body {
    font-size: 1.2em;
    text-align: center;
    font-family: Cormorant;
    margin: 0.7em 0 1.2em;
  }

  .reflection_text {
    margin: 0em 2em 2em;
    border: 2px dotted black;
    padding: 1em;
    text-align: center;
    width: 100%;
  }

  .btn_grp {
    width: 100%;
    text-align: center;
  }

  .arrows {
    width: 30px;
    height: 30px;
    padding: 8px 10px;
    border-radius: 50%;
    background: #af986f;
    color: #f2f3f4;
    margin: 0 5px;
    cursor: pointer;
    outline: none;
  }

  .audio_player {
    width: 100%;
    margin: 2em 0 1em;
    outline: none;
  }

  .video_tile {
    width: 50%;
    margin: 2em auto 1em;
  }

  .video_poster_container {
    position: relative;
    text-align: center;

    &>img {
      width: 52%;
      max-width: 12em;
    }
  }

  .video_poster_container:hover .overlay {
    opacity: 1;
    cursor: pointer;
  }

  .overlay {
    position: absolute; 
    bottom: 0; 
    background: #404a59b3;
    background: #404a59b3;
    color: #f1f1f1; 
    width: 52%;
    height: 100%;
    transition: .5s ease;
    opacity:0;
    color: white;
    font-size: 20px;
    padding: 20px;
    left: 24%;
    
    &>img {
      width: 100%;
      max-width: 20em;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 4%;
    width: 90%;
    min-height: 18em;
    .title {
      font-size: 20px;
    }

    .sub_title {
      font-size: 0.7em;
    }

    .date {
      font-size: 14px;
    }

    .modal_body {
      font-size: 1em;
    }

    .close_popup {
      width: 20px;
      height: 20px;
    }

    .options {
      flex-basis: 30%;
    }

    .reflection_text {
      margin: 0em 0em 2em;
      font-size: 0.8em;
    }

  }
`

export const ButtonOptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1em;

  .options {
    cursor: pointer;
    background: #af986f;
    font-size: 0.85em;
    margin: 0 1% 2%;
    text-align: center;
    padding: 10px;
    flex-basis: 31.3%;
  }

  @media screen and (max-width: 767px) {
    .options {
      font-size: 0.7em;
      margin: 0 1% 2%;
      text-align: center;
      padding: 10px 5px;
      flex-basis: 31.3%;
    }
  }
`