import styled from 'styled-components'

export const ErrorPageContainer = styled.div`
  background: #f2f3f4;
  font-family: Muli;
  color: #af986f;
  padding: 0 10.5% 2em;
  width: 100%;
  text-align: center;
  box-sizing: border-box;

  .title {
    font-size: 20em;
    line-height: 1em;
    margin-bottom: 40px;
    font-weight: bold;
  }

  .sub_text {
    font-size: 1em;
  }

  .redirect_text_container {
    font-size: 1em;
    margin-top: 2%;
    word-break: break-all;
  }

  .redirect_text {
    cursor: pointer;
    text-decoration: underline;
    text-underline-position: under;
    word-break: break-all;
  }

	@media screen and (min-width: 1400px) {
    .title {
      font-size: 25em;
    }
  }

	@media screen and (max-width: 767px) {
    padding: 0 4% 5%;

    .title {
      font-size: 10em;
    }
  }
`