import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { CLOSE_POPUP, DATE_RANGE_ICON, SHARE_ICON } from '../../../../images';
import { TodaysJournalModalContainer, ModalContainer } from './styles';
import { saveJournal, getSpecificDatedJournal, shareUnshareJournal } from '../../../../redux/actions/journal_actions'
import { getLoggedUserProfile } from '../../../../redux/actions/user_actions'
import Loader from '../../../common/loader'
import { getNextDate, getPreviousDate, isObjectEmpty, redirectWithOptionalMessage } from '../../../../utils/helper'
import { errorToast, infoToast, successToast } from '../../../../utils/toast'

class TodaysJournalModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      journalContent: {},
      isJournalInfoAvailable: false,
      currentJournalDate: moment().format('YYYY-MM-DD'),
      loading: false
    }
    this.timeout = 0;
  }


  callFetchSpecificDatedJournalAPI = (date) => {
    this.props.getSpecificDatedJournal(date).then(resp => {
      if (resp && resp.status === 200) {
        let journal = (resp.data.data && resp.data.data[0]) ? resp.data.data[0] : {}
        this.setState({ isJournalInfoAvailable: true, currentJournalDate: date, journalContent: journal })
      } else {
        redirectWithOptionalMessage("/dashboard", this.props.history, resp.response.data.error.message)
        this.props.closeModal()
      }
    })
  }

  showSavedNotification = () => {
    const saveElement = document.getElementById("saved_notification_text")
    if(saveElement) {
      saveElement.classList.remove("hide_saved_notification")
      saveElement.classList.add("show_saved_notification")
      setTimeout(()=>{
        saveElement.classList.remove("show_saved_notification")
        saveElement.classList.add("hide_saved_notification")
      },3000)
    }
  }

  callSaveJournalAPI() {
    const { currentJournalDate, journalContent } = this.state
    const journalTitle = journalContent.title?.trim() || ""
    const journalMessage = journalContent.journal?.trim()
    const questionId = (moment(journalContent.date).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) ? journalContent.questionId : this.props.questionOfTheDay["questionId"]
    if (journalMessage && journalMessage.trim()) {
      this.props.saveJournal({ title: journalTitle, journal: journalMessage, questionId }, currentJournalDate).then(resp => {
        if (resp && resp.status === 200) {
          this.showSavedNotification()
          if (!journalContent["_id"]) {
            this.callFetchSpecificDatedJournalAPI(currentJournalDate)
          }
        } else {
          errorToast(resp.response.data.error.message, "save_journal_api_error")
        }
      })
    }
  }

  componentDidMount() {
    let date = this.state.currentJournalDate;
    if(this.props.origin === "edit_journal") {
      date = moment(this.props.selectedJournalDate).format('YYYY-MM-DD');
    }
    this.callFetchSpecificDatedJournalAPI(date)
    let burgerElement = document.getElementById("burger-menu")
    document.body.style.overflow = 'hidden';
    if (burgerElement) {
      burgerElement.style.display = "none"
    }
  }

  componentWillUnmount() {
    let burgerElement = document.getElementById("burger-menu")
    document.body.style.overflow = 'unset';
    if (burgerElement) {
      burgerElement.style.display = "block"
    }
    this.callSaveJournalAPI()
  }

  render() {
    const { currentJournalDate, isJournalInfoAvailable, journalContent } = this.state
    const isSelectedDateIsToday = moment().isSame(currentJournalDate, 'day')
    const journalTitle = journalContent.title || ""
    const journalMessage = journalContent.journal || ""
    const isUserPrivate = this.props.user["private"]
    const nextDateActionHandler = (currentDate) => {
      const nextDate = getNextDate(currentDate)
      this.callFetchSpecificDatedJournalAPI(nextDate)
    }

    const previousDateActionHandler = (currentDate) => {
      const previousDate = getPreviousDate(currentDate)
      this.callFetchSpecificDatedJournalAPI(previousDate)
    }

    const updateJournal = (event, contentType) => {
      if (event && event.target) {
        let copyJournalContent = { ...journalContent }
        if (contentType === "title") {
          copyJournalContent.title = event.target.value
        } else if (contentType === "journal") {
          copyJournalContent.journal = event.target.value
        }
        this.setState({ journalContent: copyJournalContent }, () => {
          if (this.timeout) {
            clearTimeout(this.timeout);
          }
          this.timeout = setTimeout(() => {
            this.callSaveJournalAPI()
          }, 1500);
        })
      }
    }

    const onChangeDatePicker = (date) => {
      let newSelectedDate = moment(date).format('YYYY-MM-DD')
      this.setState({ currentJournalDate: newSelectedDate })
      this.callFetchSpecificDatedJournalAPI(newSelectedDate)
    }

    const shareUnshareJournal = (isUserPrivate, action) => {
      this.setState({loading: true})
      if (isUserPrivate) {
        infoToast("Your account is private. Please change it to public.", { toastId: "private_account_info" });
        this.setState({loading: false})
        return
      }
      const journalInfo = { journalId: journalContent["_id"] }
      this.props.shareUnshareJournal(journalInfo, action).then(resp => {
        if (resp && resp.status === 200) {
          successToast(resp.data.data.message)
          if(action === "share") {
            this.setState({loading: false})
            redirectWithOptionalMessage("/community?allShare", this.props.history)
          } else if(action === "unshare") {
            this.callFetchSpecificDatedJournalAPI(this.state.currentJournalDate)
            this.props.getLoggedUserProfile().then(userResp=>{
              this.setState({loading: false})
            })
          }
        } else {
          this.setState({loading: false})
          errorToast(resp.response.data.error.message)
        }
      })
    }

    return (
      <>
        {!isJournalInfoAvailable || this.state.loading ? <Loader /> : null}
        <>
          <TodaysJournalModalContainer>
            <ModalContainer>
              {
                this.props.origin === "edit_journal" ? <div className="title">Edit Journal</div> : <div className="title">Todays Journal</div>
              }
              <img src={CLOSE_POPUP} className="close_popup" onClick={this.props.closeModal} alt="Close" />
              {
                this.props.origin !== "edit_journal" ? 
                <div className="date_container">
                  <i className="fas fa-angle-left left_arrow" onClick={() => previousDateActionHandler(currentJournalDate)}></i>
                  <div className="date">{currentJournalDate}</div>
                  {!isSelectedDateIsToday ?
                    <i className="fas fa-angle-right right_arrow" onClick={() => nextDateActionHandler(currentJournalDate)}></i>
                    : null
                  }
                </div> : null
              }
              {journalContent?.journal || isSelectedDateIsToday ?
                <>
                  <div className="modal_body">
                    {isSelectedDateIsToday ? this.props.questionOfTheDay["question"] : ((journalContent.questionId && journalContent.questionId.question) ? journalContent.questionId.question : null)}
                  </div>
                  <input id="journalTitle" value={journalTitle} name="journalTitle" onChange={e => updateJournal(e, "title")} placeholder="Enter title (optional)" />
                  <textarea id="journalMessage" value={journalMessage} name="journalMessage" onChange={e => updateJournal(e, "journal")} placeholder="Enter your message" />
                </> : 
                <>
                  <div className="modal_body">
                    {(journalContent.questionId && journalContent.questionId.question) ? journalContent.questionId.question : null}
                  </div>
                  <div className="no_journal_body">No journal is available for selected date</div>
                </>
              }
              <div id="saved_notification_text" className="saved_notification_txt">Saved successfully</div>
              {
                this.props.origin !== "edit_journal" ?
                <div className="modal_footer">
                  <div className="footer_date_selector">
                    <label>
                      <img src={DATE_RANGE_ICON} alt="Calender" />
                      <DatePicker className="footer_date_input" selected={moment(currentJournalDate).toDate()} onChange={date => onChangeDatePicker(date)} maxDate={new Date()} />
                    </label>
                    <div className="footer_all_journal" onClick={() => redirectWithOptionalMessage("/journal", this.props.history)} >
                      View All entries
                    </div>
                  </div>
                  {
                    !isObjectEmpty(journalContent) && journalContent["_id"] ?
                      <>
                        <div className="footer_share_selector">
                          <img src={SHARE_ICON} alt="Share" />
                          {!journalContent["isPublic"] ?
                            <div onClick={() => shareUnshareJournal(isUserPrivate, "share")}>Share Journal</div> :
                            <div onClick={() => shareUnshareJournal(isUserPrivate, "unshare")}>Unshare Journal</div>}
                        </div>
                      </>
                      : null
                  }
                </div> : null
              }
            </ModalContainer>
          </TodaysJournalModalContainer>
        </>
      </>
    )
  }
}

const mapDispatchToProps = {
  saveJournal,
  getSpecificDatedJournal,
  shareUnshareJournal,
  getLoggedUserProfile
}

export default withRouter(connect(null, mapDispatchToProps)(TodaysJournalModal))
