import styled from 'styled-components'

export const EditProfileModalContainer = styled.div`
  background: #00000096;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`

export const ModalContainer = styled.div`
  background: #fff;
  font-family: Muli;
  color: #404a59;
  padding: 1% 8% 2%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 50%;

  .title {
    font-size: 1em;
    margin-bottom: 8%;
  }

  .profile_logo {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .user_img {
    width: 7em;
    height: 7em;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;

    &>img {
      width: 100%;
    }
  }

  .add_edit_icon {
    &>img {
      position: absolute;
      width: 25px;
      bottom: 0;
      right: 0%;
    }
  }

  .form {
    margin: 2% 0;
    width: 100%;
    text-align: left;
  }

  @media screen and (max-width: 767px) {
    padding: 4%;
    width: 90%;
  }
`
export const FormField = styled.div`
  margin: 1% 0;

  label {
    font-size: 0.8em;
    text-align: left;
    margin: unset;
  }

  input, textarea {
    border: 1px solid #e5e5e3;
    width: 100%;
    padding: 5px;
    font-size: 0.7em;
    background: #f2f3f4;
    outline: none;
  }

  textarea {
    min-height: 8em;
    max-height: 8em;
  }

  .input_field {
    position: relative;

    &>img {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 4%;
      max-width: 1.2em;
      min-width:1.2em;
    }
  }

  .submit_btn {
    background: #af986f;
    color: #f2f3f4;
    font-size: 0.8em;
    margin-top: 2%;
    width: 100%;
    outline: none;
    cursor: pointer;
  }

  @media screen and (min-width: 768px) {
    .input_field {
      &>img {
        width: 7%;
      }
    }

    .submit_btn {
      margin-top: 1%;
    }
  }

  @media screen and (max-width: 767px) {
    .input_field {
      &>img {
        width: 4%;
      }
    }

    .submit_btn {
      font-size: 0.7em;
    }
  }

  @media screen and (min-width: 460px) and (max-width: 600px) {
    .input_field {
      &>img {
        width: 5%;
      }
    }
  }

  @media screen and (min-width: 300px) and (max-width: 459px) {
    .input_field {
      &>img {
        width: 6%;
      }
    }
  }
`