import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import VideoModal from '../../../common/modal/yoga_vinyasa_video_modal';
import { HomeEshaMessageContainer } from './styles';
import { HOME_ESHA_MSG_IMG, PLAY_VIDEO_ICON_GOLDEN } from '../../../../images'

function HomeEshaMessageScreen(props) {
  const content = props.content;
  const [openVideoModal, setVideoModal] = useState(false);

  return (
    <>
      <HomeEshaMessageContainer>
        <div className="esha_message_title">{ReactHtmlParser(content["home_esha_video_header"])}</div>
        <div className="esha_message_subtitle">{ReactHtmlParser(content["home_esha_video_subheader"])}</div>
        <div className="esha_message_img">
          <div className="img_container" onClick={() => setVideoModal(true)}>
            <img src={HOME_ESHA_MSG_IMG} alt="Esha message" />
            <div className="overlay"><img src={PLAY_VIDEO_ICON_GOLDEN} alt="Play Button" /></div>
            <div className="esha_message_overlay">{ReactHtmlParser(content["home_esha_message_title"])}</div>
          </div>
        </div>
        {
          (openVideoModal) ?
          <VideoModal
            closeModal={() => setVideoModal(false)}
            videoSrc={"https://griefand.s3.us-east-2.amazonaws.com/esta-estar-master-2048x1108-h-264-042321-109159-PREVIEW.mp4"}
            onEnd={(id) => props.updateYogaVinyasaCount(id)}
          /> :
          null
        }
        <div className="esha_message_body">{ReactHtmlParser(content["home_esha_message_body"])}</div>
      </HomeEshaMessageContainer>
    </>
  );
}

export default HomeEshaMessageScreen;
