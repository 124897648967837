import React from 'react';
import { FooterContainer, FooterLinkContentSection } from './styles'
import { LOGO_MARK } from '../../../images'
import { useHistory } from 'react-router-dom';

function GetFooterContent(props) {
  return (
    <div className="content"><a style={{color: "#404a59", textDecoration: "none"}} href={props.link} target="_blank" rel="noreferrer">{props.title}</a></div>
  )
}

function WaysToEngage() {
  const history = useHistory();
  return (
    <>
      <FooterContainer>
        <FooterLinkContentSection align="left" >
          <div className="heading">Engage</div>
          <div className="content" onClick={() => history.push("/contact")}>Workshops</div>
          <div className="content" onClick={() => history.push("/contact")}>Speaking Events</div>
          <GetFooterContent link={"https://shop.eshaestar.com"} title={"Books"}/>
        </FooterLinkContentSection>

        <FooterLinkContentSection align="center">
          <div className="heading">Social Media</div>
          <GetFooterContent link={"https://youtube.com/channel/UC-5fpekT5MlXPOSIVZBIHTQ"} title={"Youtube"}/>
          <GetFooterContent link={"https://www.instagram.com/eshaestar"} title={"Instagram"}/>
          <GetFooterContent link={"https://www.facebook.com/eshaestar"} title={"Facebook"}/>
          <GetFooterContent link={"https://www.joinclubhouse.com/@eshaestar"} title={"Clubhouse"}/> 
        </FooterLinkContentSection>

        <FooterLinkContentSection align="right">
          <div className="heading">Esha</div>
          <div className="content" onClick={() => history.push("/about")}>About Esha</div>
          <div className="content" onClick={() => history.push("/contact")}>Contact Esha</div>
        </FooterLinkContentSection>

        <div className="footer-logo">
          <div className="footer-image-text">
            <img src={LOGO_MARK} alt="Esha Estar logo" />
            <div className="logo-copywrite-text"><b>&#169; Holy Badass Living, LLC 2021</b></div>
          </div>
        </div>

      </FooterContainer>
    </>
  );
}

export default WaysToEngage;
