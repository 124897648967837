export const programInfo = {
  "general_grief": {
    cards: [
      { titleId: "program_general_grief_title_1", descriptionId: "program_general_grief_desc_1" },
      { titleId: "program_general_grief_title_2", descriptionId: "program_general_grief_desc_2" },
      { titleId: "program_general_grief_title_3", descriptionId: "program_general_grief_desc_3" },
      { titleId: "program_general_grief_title_4", descriptionId: "program_general_grief_desc_4" },
      { titleId: "program_general_grief_title_5", descriptionId: "program_general_grief_desc_5" },
      { titleId: "program_general_grief_title_6", descriptionId: "program_general_grief_desc_6" },
    ],
    workBooks: [
      { bookTitle: " The Grief workbook", link: "https://shop.eshaestar.com/" },
    ]
  },

  "i_am_dying": {
    cards: [
      { titleId: "program_i_m_dying_title_1", descriptionId: "program_i_m_dying_desc_1" },
      { titleId: "program_i_m_dying_title_2", descriptionId: "program_i_m_dying_desc_2" },
      { titleId: "program_i_m_dying_title_3", descriptionId: "program_i_m_dying_desc_3" },
      { titleId: "program_i_m_dying_title_4", descriptionId: "program_i_m_dying_desc_4" }
    ],
    workBooks: [
      { bookTitle: " End of life Planner and Journal,", link: "https://shop.eshaestar.com/" },
      { bookTitle: " A History of Me: A Companion Journal to leave for loved ones,", link: "https://shop.eshaestar.com/" },
      { bookTitle: " The Wisdom of Death", link: "https://shop.eshaestar.com/" },
    ]
  },

  "lost_a_loved_one": {
    cards: [
      { titleId: "program_lost_a_loved_one_title_1", descriptionId: "program_lost_a_loved_one_desc_1" },
      { titleId: "program_lost_a_loved_one_title_2", descriptionId: "program_lost_a_loved_one_desc_2" },
      { titleId: "program_lost_a_loved_one_title_3", descriptionId: "program_lost_a_loved_one_desc_3" },
      { titleId: "program_lost_a_loved_one_title_4", descriptionId: "program_lost_a_loved_one_desc_4" }
    ],
    workBooks: [
      { bookTitle: " The Grief Workbook,", link: "https://shop.eshaestar.com/" },
      { bookTitle: " Compassionate Grieving Guide", link: "https://shop.eshaestar.com/" },
    ]
  },

  "self_care": {
    cards: [
      { titleId: "program_self_care_title_1", descriptionId: "program_self_care_desc_1" },
      { titleId: "program_self_care_title_2", descriptionId: "program_self_care_desc_2" },
      { titleId: "program_self_care_title_3", descriptionId: "program_self_care_desc_3" },
      { titleId: "program_self_care_title_4", descriptionId: "program_self_care_desc_4" }
    ]
  },

  "spiritual": {
    cards: [
      { titleId: "program_spiritual_title_1", descriptionId: "program_spiritual_desc_1" },
      { titleId: "program_spiritual_title_2", descriptionId: "program_spiritual_desc_2" }
    ],
    workBooks: [
      { bookTitle: "Awakening the Spirit Workbook", link: "https://shop.eshaestar.com/" },
    ]
  }
}
