import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';
import LoginCallBack from './auth/login_callback';
import ProtectedRoute from './protected_routes';
import ScrollToTop from './utils/scroll_to_top';
import ComingSoonSplash from './containers/coming_soon_container';
import Home from './containers/home_container';
import AboutEsha from './containers/about_esha_container';
import Contact from './containers/contact_container';
import Download from './containers/download_form_container';
import Dashboard from './containers/dashboard_container';
import Meditation from './containers/meditation_container';
import YogaNidra from './containers/yoga_nidra_container';
import YogaVinyasa from './containers/yoga_vinyasa_container';
import MariposaSanctuary from './containers/mariposa_container';
import MyJournal from './containers/my_journal_container';
import EditProfile from './containers/edit_profile_container';
import CommunityShares from './containers/community_shares_container';
import UserJournal from './containers/user_journal_container';
import ErrorPage from './components/common/error_screen';
import ProgramScreen from './containers/program_screen';

export const Routes = () => {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Auth0ProviderWithHistory>
          <Switch>
            <Route exact path="/" component={Home} />
            {/*  <Redirect to="/coming_soon" />
            </Route> */}
            <Route exact path="/coming_soon" component={ComingSoonSplash} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/login_callback" component={LoginCallBack} />
            <Route exact path="/about" component={AboutEsha} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/download" component={Download} />
            <Route exact path="/mariposa_sanctuary" component={MariposaSanctuary} />
            <Route exact path="/programs/general_grief" component={() => <ProgramScreen programType={"general_grief"} />} />
            <Route exact path="/programs/i_am_dying" component={() => <ProgramScreen programType={"i_am_dying"} />} />
            <Route exact path="/programs/lost_a_loved_one" component={() => <ProgramScreen programType={"lost_a_loved_one"} />} />
            <Route exact path="/programs/self_care" component={() => <ProgramScreen programType={"self_care"} />} />
            <Route exact path="/programs/spiritual" component={() => <ProgramScreen programType={"spiritual"} />} />

            <ProtectedRoute path="/dashboard" component={Dashboard} />
            <ProtectedRoute path="/meditation" component={Meditation} />
            <ProtectedRoute path="/yoga_nidra" component={YogaNidra} />
            <ProtectedRoute path="/yoga_vinyasa" component={YogaVinyasa} />
            <ProtectedRoute path="/journal" component={MyJournal} />
            <ProtectedRoute path="/user/:id" component={UserJournal} />
            <ProtectedRoute path="/setting" component={EditProfile} />
            <ProtectedRoute path="/community" component={CommunityShares} />

            <Route component={ErrorPage}/>
          </Switch>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </>
  );
};

export default Routes