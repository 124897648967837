import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Container } from './style'

function AboutEshaCard(props) {
  const content = props.content;
  let splitCardInfo = (content["about_esha_full_card"]).split("<readmore>");
  let [isCardDetailsExpanded, setCardDetailExpanded] = useState(false);
  let [cardDetails, updateCardDetails] = useState(ReactHtmlParser(splitCardInfo[0]));

  let ReadMoreHandel = () => {
    if(!isCardDetailsExpanded) {
      setCardDetailExpanded(true);
      updateCardDetails(ReactHtmlParser(splitCardInfo[0] + splitCardInfo[1]))
    } else {
      setCardDetailExpanded(false);
      updateCardDetails(ReactHtmlParser(splitCardInfo[0]))
    }
  }

  return (
    <Container>
      <div className="card_container">
        <div className="dynamic_content card_body">{cardDetails}</div>
        { isCardDetailsExpanded ? <div className="card_sign">Esha Estar</div> : undefined }
        { !isCardDetailsExpanded ? <div className="read_more" onClick={e => ReadMoreHandel()}>Read More</div> : 
          <div className="read_more" onClick={e => ReadMoreHandel()}>Read Less</div>
        }
      </div>
    </Container>
  );
}

export default AboutEshaCard;