import React from 'react';

import { CommunitySharesContainer } from './styles';
import { ESHA_AVATAR } from '../../../../images';

function CommunitySharesHeader(props) {
  const {updateJournalsFrom, showJournalsFrom} = props

  return (
    <CommunitySharesContainer>
      <img src={ESHA_AVATAR} alt="Esha Avatar" />
      <div className="header">Community Shares</div>
      <div className="sub_header">SHARED STORIES FROM OUR GRIEF WARRIORS</div>
      <div className="toggle_btn">
        <div className={showJournalsFrom === "following" ? "active_btn" : "btn"} onClick={() => updateJournalsFrom("following")}>FOLLOWING</div>
        <div className={showJournalsFrom === "public" ? "active_btn" : "btn"} onClick={() => updateJournalsFrom("public")}>ALL SHARES</div>
      </div>
    </CommunitySharesContainer>
  )
}

export default CommunitySharesHeader;