import React, { Component } from 'react'
import { connect } from 'react-redux'
import Routes from './routes'
import { getStaticWebContent } from './redux/actions/static_content_action'
import Loader from './components/common/loader'
import NoConnectionPage from './components/common/no_connection_page'
import { ToastContainer } from 'react-toastify';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      staticDataReceived: false,
      errorMessage: undefined
    }
  }

  componentDidMount() {
    this.props.getStaticWebContent().then(resp => {
      if (resp && resp.status === 200) {
        this.setState({ staticDataReceived: true })
      } else {
        this.setState({ errorMessage: "Unable to connect, Please try again later." })
      }
    })
  }

  render() {
    let { staticDataReceived, errorMessage } = this.state
    return (
      <>
      <ToastContainer autoClose={2500} />
        {errorMessage ? <NoConnectionPage message={errorMessage} /> :
          !staticDataReceived ? <Loader /> : <Routes />
        }
      </>
    )
  }
}

const mapDispatchToProps = {
  getStaticWebContent
}

const mapStateToProps = state => {
  return {
    staticWebContent: state.static_content.staticWebContent,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
